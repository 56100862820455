
import { Divider } from '@mui/material';
import { APColumn, APDialogCard, APFormSubmit, APSizedBox, APText, Deferred } from 'ap-components'

export default function LinkPanDialog({ deferred }: { deferred: Deferred<void> }) {
    return (
        <APDialogCard title='Link PAN with Aadhaar' style={{ minWidth: "240px", maxWidth: "478px" }}>
            <APColumn crossAxisAlignment='stretch'>
                <APSizedBox height='8px' />
                <Divider />
                <APSizedBox height='16px' />
                <APText variant='paragraph-small'>As per the SEBI guidelines, PAN and Aadhaar must be linked by 31st March*The non-compliance with this would be considered non-KYC compliant and there could be restrictions on securities and other transactions until the PAN and Aadhaar are linked.</APText>
                <APSizedBox height='16px' />
                <APText variant='paragraph-xsmall'>*Ignore if already linked</APText>
                <APSizedBox height='16px' />
                <Divider />
                <APFormSubmit
                    onClick={async () => {
                        // TODO: redirection link for linking PAN
                        deferred.resolve();
                    }}
                >
                    Link
                </APFormSubmit>
            </APColumn>
        </APDialogCard>
    )
}
