import { useLocation } from "@gatsbyjs/reach-router";
import { APTypography } from "ap-components/src/utils/APTypography";
import { useEffect, useState } from "react";
import Activation2Model from "../../Models/Activation2Model";
import { addParamsToCurrentUrl, launchUrl } from "../DigilockerKYC/common";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneyStepApp, JourneySectionApp, getIt, APColumn, APSizedBox, APImage, APTag, APText, APCenter } from "ap-components";
import { CircularProgress } from "@mui/material";

export default function EsignStepper({ activationData, updateActivationData }: { activationData: Activation2Model, updateActivationData: (activationData: Activation2Model) => void }): JourneyStepApp | JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [isFetchingUpdate, setisFetchingUpdate] = useState<boolean>(false);

    if (!activationData.activationWorkflowProgress?.includes('DigiLocker')) {
        let url = new URL(window.location.href);
        let isRedirected = searchParams.get("kyc_digilocker_stage") == 'redirected';
        let isDigilockerComeback = searchParams.get("kyc_step") == 'Digilocker';
        if (isRedirected || isDigilockerComeback) {
            ["kyc_digilocker_stage", "kyc_step"].forEach(param => url.searchParams.delete(param));
            launchUrl(url.href);
        }
    }

    async function refetch(holder: 'primary' | 'secondary' | 'guardian') {
        await getIt(MutualFundApiServices).refetchKycDetails({ token: token!, holder });
    }

    async function executeEsignProcess(onSubmit?: boolean) {
        if (!activationData.esignStatus || ["INITIATED", "PENDING"].includes(activationData.esignStatus ?? "")) {
            try {
                await getIt(MutualFundApiServices).executeEsign2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian' });
                await refetch(activationData.taxStatus != '02' ? 'primary' : 'guardian');
                updateActivationData(await getIt(MutualFundApiServices).submitApplication2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian' }));
                setisFetchingUpdate(false);
            } catch (error) {
                if (onSubmit) {
                    let { url } = await getIt(MutualFundApiServices).generateEsignUrl2({ token: token!, redirectUrl: addParamsToCurrentUrl({ kyc_esign_stage: "redirected", kycStep: "E-Sign", }), holder: activationData.taxStatus != '02' ? 'primary' : 'guardian' });
                    launchUrl(url);
                    setisFetchingUpdate(false);
                    throw new Error("Redirecting to Esign");
                }
                setisFetchingUpdate(false);
                throw new Error("Refetch failed")
            }
        }
        setisFetchingUpdate(false);
    }

    useEffect(() => {
        setisFetchingUpdate(true);
        executeEsignProcess();
    }, [])

    if (activationData.holdingType == 'SI') {
        return ({
            title: "e-Sign",
            description: "",
            code: "ES",

            form: (isFetchingUpdate ? <APCenter>< CircularProgress /></APCenter> : (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='32px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        {(activationData.esignStatus ?? '') === "COMPLETE" && (<APTag variant="positive">e-Sign completed successfully. Click on Next to continue</APTag>)}
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>E-sign KYC ensures your identity is securely verified, complies with legal requirements, and makes the process fast and convenient.</APText>
                        </APColumn>
                    </APColumn>
                </APColumn>
            )
            ),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                await executeEsignProcess(true);
            }
        });
    }

    const steps: JourneyStepApp[] = [];
    if (!activationData.firstHolderHasCkyc) {
        steps.push({
            title: "e-Sign",
            description: "",
            code: "FH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='8px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        <APSizedBox height="32px" />
                        <APImage width={114.738} height={28.069} src='img/Growth.svg' />
                        <APSizedBox height="32px" />
                        {(activationData.esignStatus ?? '') === "COMPLETE" && (<APTag variant="positive">e-Sign completed successfully. Click on Next to continue</APTag>)}
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>E-sign KYC ensures your identity is securely verified, complies with legal requirements, and makes the process fast and convenient.</APText>
                        </APColumn>
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: async () => {
                if (!activationData.esignStatus) {
                    let { url } = await getIt(MutualFundApiServices).generateEsignUrl2({ token: token!, redirectUrl: addParamsToCurrentUrl({ kyc_esign_stage: "redirected", kycStep: "E-Sign", }), holder: 'primary' });
                    launchUrl(url);
                    throw new Error("Redirecting to Esign");
                };

                if ((["INITIATED"].includes(activationData.esignStatus ?? ''))) {
                    await getIt(MutualFundApiServices).executeEsign2({ token: token!, holder: 'primary' });
                    await refetch(activationData.taxStatus != '02' ? 'primary' : 'guardian');
                    updateActivationData(await getIt(MutualFundApiServices).submitApplication2({ token: token!, holder: "primary" }));
                };
            }
        });
    };
    if (!activationData.secondHolderHasCkyc) {
        steps.push({
            title: "e-Sign",
            description: "",
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='8px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        <APSizedBox height="32px" />
                        <APImage width={114.738} height={28.069} src='img/Growth.svg' />
                        <APSizedBox height="32px" />
                        {(activationData.secondHolderEsignStatus ?? '') === "COMPLETE" && (<APTag variant="positive">e-Sign completed successfully. Click on Next to continue</APTag>)}
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>E-sign KYC ensures your identity is securely verified, complies with legal requirements, and makes the process fast and convenient.</APText>
                        </APColumn>
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: async () => {
                if (!activationData.esignStatus) {
                    let { url } = await getIt(MutualFundApiServices).generateEsignUrl2({ token: token!, redirectUrl: addParamsToCurrentUrl({ kyc_esign_stage: "redirected", kycStep: "E-Sign", }), holder: 'secondary' });
                    launchUrl(url);
                    throw new Error("Redirecting to Esign");
                };

                if ((["INITIATED"].includes(activationData.esignStatus ?? ''))) {
                    await getIt(MutualFundApiServices).executeEsign2({ token: token!, holder: 'secondary' });
                    await refetch("secondary");
                    updateActivationData(await getIt(MutualFundApiServices).submitApplication2({ token: token!, holder: "secondary" }));
                };
            }
        });
    };

    return {
        code: "DL",
        title: "DigiLocker",
        description: activationData.name,
        steps
    };
};


export class PhotoRequestForHolderClass {
    token!: string;
    photoBase64!: string;
    holder!: 'primary' | 'secondary' | 'guardian'
}