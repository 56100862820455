import { APRow, APExpanded, APSizedBox } from "../layout";
import { APPalette, NArray } from "../utils";

export function APJourneyTile({ currentStep, totalSteps, thickness = 1, variant = 'primary' }: { currentStep: number, totalSteps: number, thickness?: number, variant?: 'primary' | 'secondary' }) {

    var active = APPalette["brand-300"]
    var inactive = APPalette["grey-300"]
    if (variant === "secondary") {
        active = APPalette.white;
        inactive = APPalette["grey-500"]
    }

    return <APRow
        mainAxisAlignment="spaceEvenly"
        crossAxisAlignment="center"
        mainAxisSize="max"
        gap="8px"
    >
        {
            NArray(totalSteps).map(index =>
                <APExpanded key={`${index}_key`}>
                    <APSizedBox
                        style={{
                            transition: "all .2s ease-out",
                            border: `${thickness}px solid ${(index < currentStep) ? APPalette["positive-400"] : index === currentStep ? active : inactive}`,
                            borderRadius: "8px"
                        }}
                    />
                </APExpanded>
            )}
    </APRow>
}