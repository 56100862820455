export function AppDownloadLinkWithUserToken(
  advisor: string,
  token: string
): string {
  const _encode = (v: string) => {
    if (!v) {
      return "";
    }
    return encodeURIComponent(v);
  };

  const queryString = (params: { [x: string]: any }) =>
    Object.keys(params)
      .map((key) => _encode(key) + "=" + _encode(params[key]))
      .join("&");

  const firstQuery = {
    link: `https://www.assetplus.in/?${queryString({
      advisor,
      login: true,
      token,
    })}`,
    apn: "io.assetplus",
    isi: "1200170877",
    ibi: "io.assetplus",
    ius: "io.assetplus",
  };

  return `https://aj2pf.app.goo.gl/?${queryString(firstQuery)}`;
}

export const testAppDownloadLinkWithUserToken = AppDownloadLinkWithUserToken(
  "5badcc07feea3061cd4463cb",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MjI4YjAzOWU3M2VhNTAwMDhkZDNjN2UiLCJpYXQiOjE2NDY4MzM3MjJ9.UCkgK0L6cOGMK0NBvt6kCkJyHE5E7uBI2BmvgEJPuxk"
);
