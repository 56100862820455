import { APExpanded } from 'ap-components'
import React from 'react'
import FailureResponse from './FailureResponse'
import SuccessResponse from './SuccessResponse'
import WaitingResponse from './WaitingResponse'
import PolicyDetails from './PolicyDetails'
import { RouteComponentProps, Router } from '@gatsbyjs/reach-router'
import KYCSuccess from './KYCSuccess'
import KYCFailed from './KYCFailed'

export default function HealthInsurance(props: RouteComponentProps) {
  return (
    <APExpanded>
      <Router style={{ height: "100%", display: "flex" }}>
        {/* <DefaultPage path="/" default /> */}
        <PolicyDetails path="/policy"></PolicyDetails>
        <SuccessResponse path='/success'></SuccessResponse>
        <FailureResponse path="/failed"></FailureResponse>
        <WaitingResponse path="/waiting"></WaitingResponse>
        <KYCSuccess path='/kyc-success'></KYCSuccess>
        <KYCFailed path='/kyc-failed'></KYCFailed>
      </Router>
    </APExpanded>
  )
}
