// import the core library.
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import charts, all with Chart suffix
import {
    BarChart, LineChart,
    PieChart
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    DataZoomComponent,
    GridComponent,
    LegendComponent, TitleComponent,
    TooltipComponent
} from 'echarts/components';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';

// Register the required components
echarts.use(
    [
        TitleComponent, TooltipComponent, DataZoomComponent, GridComponent, LegendComponent,
        PieChart, LineChart, BarChart,
        CanvasRenderer
    ]
);
// The usage of ReactEChartsCore are same with above.


export function APCharts(props: any) {

    return <ReactEChartsCore
        echarts={echarts}
        theme={"theme_name"}
        {...props}
    />
}


//3.1 MB withouth echarts minification
// import EChartsReact from 'echarts-for-react';

// export function APCharts(props: any) {
//     return <EChartsReact
//         theme={"theme_name"}
//         {...props}
//     />
// }