import { APButton, APDialogCard, APImage, APText } from '../elements';
import { APRow } from '../layout';
import { Deferred } from '../utils/Deferred';
import ReactHtmlParser from 'html-react-parser';

export function ErrorDialog(props: { deferred: Deferred<void>; title?: string; message: string, yesAction?: string }) {
    return (
        <APDialogCard
            style={{ minWidth: "300px", maxWidth: "500px" }}
            title={props.title ?? "Error"}
            leading={<APImage src='/icons/icon-error-snackbar.svg' />}
            actions={
                <APRow mainAxisAlignment='end' gap='8px'>
                    <APButton size='small' onClick={() => {
                        console.log("This has been clicked")
                        props.deferred.resolve()
                    }}>{props.yesAction ?? "Okay"} </APButton>
                </APRow>
            }
        >
            <APText variant='paragraph-small'>{ReactHtmlParser(props.message)} </APText>
        </APDialogCard>
    )
}
