import { useLocation } from "@gatsbyjs/reach-router";
import { useEffect, useState } from "react";
import { APTypography } from "ap-components/src/utils/APTypography";
import { Drawer, CircularProgress } from "@mui/material";
import { JourneyStepApp, useAPForm, getIt, APColumn, APPalette, APExpanded, APFormFieldText, APFormFieldDropdown, APFormFieldImage, APPadding, APRow, APText, APInkWell, CloseIcon, APCenter, APImage, APSizedBox, APButton, APTag } from "ap-components";
import Activation2Model, { AccountType } from "../../Models/Activation2Model";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { launchUrl } from "../DigilockerKYC/common";
import { Constants } from "./ClientActivation";

export default function BankVerificationStepperFunction({ activationData, updateActivationData }: { activationData: Activation2Model, updateActivationData: (activationData: Activation2Model) => void }): JourneyStepApp {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = searchParams.get("token");
    const baseDetailsPageControl = useAPForm();
    const [updatedActivationData2, setUpdatedActivationData] = useState<Activation2Model>();
    const [form, setForm] = useState<VerifyBankRequestClass>({
        token: token!,
        accountNumber: activationData.bank0?.accountNumber ?? '',
        ifsc: activationData.bank0?.IFSC ?? '',
        accountType: activationData?.bank0?.accountType ?? '',
        bankDetailsImage: activationData.bankDetailsImage ?? ''
    });
    const [error, setError] = useState<{ errorTitle: string, errorDiscription: string }>();
    async function verifyBankAccount({ accountDetails }: { accountDetails: VerifyBankRequestClass }) {
        setDrawerOpen(true);
        setIsLoading(true);
        try {
            setError(undefined);
            const updatedActivationData = await getIt(MutualFundApiServices).verifyBank(accountDetails);
            setUpdatedActivationData(updatedActivationData);
            updateActivationData(updatedActivationData);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            const { metaData } = error;
            console.log({ metaData })
            if (!!metaData) {
                setError(metaData);
            } else {
                setError({ errorTitle: error.message, errorDiscription: error.message })
            }
            throw new Error();
        }
    }

    return (
        {
            title: "Bank Account",
            code: "BA",
            description: "",
            form: (
                <APColumn style={{ color: APPalette['grey-500'] }} crossAxisAlignment='stretch' gap='8px'>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.bank0?.accountNumber}
                            label='Account Number'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    accountNumber: v
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return "Account Number required"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.bank0?.accountNumber}
                            label='Re-enter Account Number'
                            keyboardType="text"
                            onChanged={(v) => { }}
                            validator={(v) => {
                                if (!v) {
                                    return "Re enter Account Number"
                                };
                                if (v != form.accountNumber) {
                                    return "Account number did not match";
                                }

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.bank0?.IFSC}
                            label='IFSC'
                            keyboardType="text"
                            textAppearance="capitalize"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    ifsc: v
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return "IFSC required"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            control={baseDetailsPageControl}
                            initialValue={form.accountType}
                            items={Constants.accountType}
                            key={activationData.bank0?.accountType ?? 'activationData.bank0.accountType'}
                            label='Account Type'
                            disabled={form.accountNumber == '02'}
                            onChanged={(v) => {
                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        accountType: v as AccountType,
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return 'Account Type Required';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    {
                        ['02', '21', '24'].includes(activationData.taxStatus ?? '') && <>
                            <APFormFieldImage
                                label="Bank Proof Image"
                                src={activationData?.bankDetailsImage}
                                onChange={(src) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        bankDetailsImage: src,
                                    }));
                                }}
                                validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                            />
                        </>
                    }
                    <Drawer
                        anchor="bottom"
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                    >
                        <APPadding padding="16px" noClone>
                            <>
                                <APColumn crossAxisAlignment="stretch" gap="16px">
                                    <APRow mainAxisAlignment="spaceBetween">
                                        <APText style={APTypography.h3}>Bank Verification</APText>
                                        <APInkWell onClick={() => setDrawerOpen(false)}>
                                            <CloseIcon />
                                        </APInkWell>
                                    </APRow>
                                    <APCenter>
                                        <APImage width={97} height={101} src='img/BankVerification.svg' />
                                    </APCenter>

                                </APColumn>
                                <APSizedBox height="48px" />
                                {isLoading && (
                                    <APColumn crossAxisAlignment="center">
                                        <APText style={APTypography["paragraph-medium"]}>Please wait while we verify your bank details by depositing ₹1 in your bank account</APText>
                                        <APCenter>
                                            <APSizedBox height="32px" />
                                            <APSizedBox height="56px" width="56px" noClone>
                                                <CircularProgress />
                                            </APSizedBox>
                                        </APCenter>
                                        <APSizedBox height="16px" />
                                        <APRow crossAxisAlignment="stretch">
                                            <APExpanded>
                                                <APButton>Continue</APButton>
                                            </APExpanded>
                                        </APRow>
                                    </APColumn>
                                )}
                                {error && (
                                    <APExpanded noClone>
                                        <APColumn crossAxisAlignment="stretch" gap="16px">
                                            <APTag variant="negative">{error.errorTitle}</APTag>
                                            <APText style={APTypography["paragraph-small"]}>{error.errorDiscription}</APText>
                                            <APSizedBox height="16px" />
                                            <APButton onClick={() => {
                                                setDrawerOpen(false);
                                                setError(undefined);
                                            }}>
                                                Retry
                                            </APButton>
                                        </APColumn>
                                    </APExpanded>
                                )}
                                {(!!updatedActivationData2 && updatedActivationData2.activationWorkflowProgress === 'Complete') && (
                                    <APColumn crossAxisAlignment="stretch" gap="16px">
                                        <APTag variant="positive"> ₹1 deposited in your bank account.
                                            Your bank has been successfully verified.</APTag>
                                        <APButton onClick={() => { launchUrl("https://www.assetplus.in/#!/") }}>OK</APButton>
                                    </APColumn>
                                )}
                            </>
                        </APPadding>
                    </Drawer>
                </APColumn >
            ),
            onSubmit: async () => {
                const dataToSubmit: VerifyBankRequestClass = {
                    token: token!,
                    accountNumber: form.accountNumber,
                    ifsc: form.ifsc,
                    accountType: form.accountType,
                    bankDetailsImage: form.bankDetailsImage
                };

                const bankVerificationResponse = await verifyBankAccount({ accountDetails: dataToSubmit });
            }
        }
    )
};

export class VerifyBankRequestClass {
    token!: string;
    accountNumber!: string;
    ifsc!: string;
    accountType?: string;
    bankDetailsImage?: string
}