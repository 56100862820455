import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, APSwitcher, useDeviceType } from 'ap-components'
import saveAs from 'file-saver'
import html2canvas from 'html2canvas'
import { useRef, useState } from 'react'
import { APCharts } from '../../../Common/Charting'
import CalculatorFormulas from '../CalculatorFormulas'
import CalculatorInput from '../CalculatorInput'
import { ChartValue, ScreenShot } from './Emi'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display'

export default function CompoundInterest() {

    const targetElementRef = useRef<HTMLDivElement>(null);

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);
    let deviceType = useDeviceType();

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Compound Interest Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };


    const [initialValue, setInitialValue] = useState({
        interval: "Yearly",
        principalAmount: 200000,
        investmentPeriod: 5,
        interestRate: 12
    })

    var out = CalculatorFormulas.compoundCalculation(initialValue);
    var investedAmount = out.principalAmount;
    var maturityAmount = out.maturityAmount;
    var gains = maturityAmount - investedAmount
    var option = {
        legend: {
            orient: 'horizontal',
            position: "center",
            data: ['Gains', 'Amount Invested']
        },
        tooltip: {
            trigger: 'item',
        },
        title: {
            text: `${maturityAmount.currency(2, true)}`,
            left: 'center',
            top: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '60%'],
                avoidLabelOverlap: false,
                startAngle: 180,
                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency();
                    }
                },
                label: {
                    show: true,
                    formatter(param: any) {
                        return (param.value.currency(2, true))
                    }
                },

                data: [
                    {
                        value: investedAmount,
                        name: 'Amount Invested',
                        itemStyle: {
                            color: '#268EFF'
                        }
                    },
                    {
                        value: gains,
                        name: 'Gains',
                        itemStyle: {
                            color: '#5DD27A'
                        }
                    },
                ]
            }
        ]
    };

    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween" >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>Compound Interest Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >

                        <APImage src="/icons/icon-camera.svg" />

                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />
            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}  >

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>

                        <APRow mainAxisAlignment="spaceBetween"  >
                            <APRow gap="3px" mainAxisAlignment='start' crossAxisAlignment="center" mainAxisSize="min" >
                                <APText variant="h5" style={{ color: APPalette.black }}>COMPOUND INTERVAL</APText>
                            </APRow>
                            <APSwitcher
                                options={["Quarterly", "Monthly", "Half-Yearly", "Yearly"]}
                                onSelect={(tenure) => {
                                    setInitialValue(prev => ({ ...prev, interval: tenure }))
                                }}
                                initial={initialValue.interval}
                                variant="primary" />

                        </APRow >
                        <APSizedBox height='24px' />
                        <CalculatorInput
                            label="Principle Amount"
                            dataType="amount"
                            value={initialValue.principalAmount}
                            sliderMax={1000000}
                            sliderMin={10000}
                            sliderStep={10000}
                            inputMin={100}
                            inputMax={1000000000}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, principalAmount: value })
                            }}
                        />

                        <APSizedBox height='24px' />

                        <CalculatorInput
                            label="Duration"
                            dataType="age"
                            value={initialValue.investmentPeriod}
                            sliderMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            inputMin={1}
                            inputMax={50}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, investmentPeriod: value })
                            }}
                        />

                        <APSizedBox height='24px' />

                        <CalculatorInput
                            label="Interest Rate"
                            dataType="percentage"
                            value={initialValue.interestRate}
                            sliderMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            inputMin={1}
                            inputMax={30}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, interestRate: value })
                            }}
                        />
                    </div>
                </APExpanded>

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column", }} ref={in2Ref}>
                        <ScreenShot dataType="amount" label="Compound Interval" value={initialValue.interval} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="amount" label="Principle Amount" value={initialValue.principalAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Duration" value={initialValue.investmentPeriod} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="percentage" label="Interest rate" value={initialValue.interestRate} />
                    </div>
                </APExpanded>
                <APSizedBox width="32px" />

                <APExpanded flex={3}>
                    <APColumn crossAxisAlignment="stretch" style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }}>
                        <APCharts height="320px" option={option} />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "8px", flexWrap: "wrap" }}  >
                            < ChartValue style={{ color: "#268EFF" }} label={"Amount Invested"} value={investedAmount} />

                            < ChartValue label={"Maturity Amount"} value={maturityAmount} />

                            < ChartValue style={{ color: "#5DD27A" }} label={"Gains"} value={gains} />
                        </div>
                    </APColumn>
                </APExpanded>

            </div>

        </div>
    )
}
