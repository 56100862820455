import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { APRow } from '../layout';
import { APPalette } from '../utils';
import { APFormContext } from "./APForm";
import { APFormControl, APFormFieldItem } from './Common';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export function APFormFieldDropdown<T>(
    {
        style, label, items, onChanged, initialValue, helperText, validator, control, disabled, size, autoFocus, required, readOnly = false, autoValidate, hideCloseButton = false, variant = 'outlined', disableUnderline = false
    }: {
        style?: React.CSSProperties;
        label: string;
        items: Array<{ value: T; label: React.ReactElement | string; }>;
        helperText?: string;
        onChanged?: (value: T | null, isRemoved?: boolean) => void | Promise<void>;
        initialValue?: T;
        validator?: (v: T | null) => string | null | Promise<string | null>;
        control?: APFormControl;
        size?: 'small';
        autoFocus?: boolean;
        disabled?: boolean;
        required?: boolean;
        readOnly?: boolean;
        autoValidate?: boolean;
        hideCloseButton?: boolean;
        variant?: 'outlined' | 'standard' | 'filled'
        disableUnderline?: boolean;
    }) {

    let inputRef = useRef<any>(null);


    var control2: APFormControl | undefined = useContext(APFormContext);

    if (control !== undefined) {
        control2 = control;
    }

    const [value, setValue] = useState<T | null>(initialValue ?? null);

    const [error, setError] = useState<string | null>(null);

    async function checkValues(): Promise<boolean> {
        var errorMessage = null;
        if (validator) {
            errorMessage = await validator(value);
        }
        setError(errorMessage);
        if (errorMessage) {
            inputRef.current?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
        return !errorMessage;
    }

    var fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setValue(initialValue ?? null);
        }
    };

    useEffect(() => {
        if (control2 !== undefined)
            control2.fields.add(fieldItem);
        return () => {
            if (control2 !== undefined)
                control2.fields.delete(fieldItem);
        };
    });


    useEffect(() => {
        (async () => {
            if (validator && (autoValidate || error !== null)) {
                var errorMessage = null;
                errorMessage = await validator(value);
                setError(errorMessage);
            }

            if (onChanged) {
                onChanged(value);
            }
        })();
    }, [value, ...items.map(item => item.value).join(":")]);

    let foundIndex: number | "" = items.findIndex((item) => item.value === value);
    if (foundIndex == -1) {
        foundIndex = "";
    }

    return (
        <FormControl
            margin='normal'
            variant={variant}
            size={size}
            error={!!error}
            style={style}
            ref={inputRef}
            disabled={disabled}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                readOnly={readOnly}
                label={
                    <APRow>
                        <span style={{ color: APPalette['grey-500'] }}>{label}</span>
                        {
                            required &&
                            <span style={{ color: !!error ? APPalette['negative-400'] : APPalette['grey-500'] }}>&nbsp;*</span>
                        }
                    </APRow>
                }
                IconComponent={
                    (value !== null && !readOnly && !disabled && !hideCloseButton) ?
                        () => (
                            <IconButton onClick={() => setValue(null)}>
                                <Close fontSize='small' />
                            </IconButton>
                        ) : undefined
                }
                value={foundIndex}
                disableUnderline={disableUnderline}
                autoFocus={autoFocus}
                onChange={(v) => {
                    if (typeof v.target.value == 'number') {
                        setValue(items[v.target.value].value);
                    }
                }}
                style={{
                    backgroundColor: readOnly ? APPalette['grey-100'] : APPalette.white,
                }}
            >
                {
                    items.map((item, index) => (
                        <MenuItem value={index} key={index}>
                            {item.label}
                        </MenuItem>
                    ))
                }
            </Select>
            <FormHelperText>{error || helperText}</FormHelperText>
        </FormControl>
    )

}
