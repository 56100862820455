import Dialog from '@mui/material/Dialog';
import { default as React, useEffect, useState } from 'react';
import { Deferred } from '../utils/Deferred';
import { DialogHolder } from './DialogHolder';

import { container, InjectionToken } from "tsyringe";
import { APErrorWrap } from '../utils';

export function getIt<T>(token: InjectionToken<T>) {
    return container.resolve(token);
}

export const DialogContext = React.createContext<{ deferred?: Deferred<any> | undefined, disableClose?: boolean }>({});

export function DialogWrapper(props: { children: any, deferred: Deferred<any>, disableClose?: boolean, fullScreen?: boolean }) {

    const [open, setOpen] = useState(true);
    props.deferred.promise.then(() => setOpen(false)).catch(() => setOpen(false));

    return (
        <Dialog
            fullScreen={props.fullScreen}
            open={open}
            onClose={(event, reason) => {
                if (props.disableClose) {
                    return;
                }
                props.deferred.reject(new Error("USER_CANCELLED"))
            }}
            scroll={"body"}
        >
            <DialogContext.Provider value={{ deferred: props.deferred, disableClose: props.disableClose }}>
                <APErrorWrap>
                    {props.children}
                </APErrorWrap>
            </DialogContext.Provider>
        </Dialog>
    );
}

//  {React.cloneElement(props.children, { deferred: props.deferred })} 
export interface IDialogLaunchOptions {
    disableClose?: boolean
    fullScreen?: boolean
}

export function launchDialog<T>(render: (deferred: Deferred<T>) => JSX.Element, options?: IDialogLaunchOptions): Promise<T> {
    var deferred = new Deferred<T>();

    showDialog(<DialogWrapper fullScreen={options?.fullScreen} deferred={deferred} disableClose={options?.disableClose ?? false}>{render(deferred)}</DialogWrapper>);

    return deferred.promise
}

export function showDialog(cmp: JSX.Element) {
    getIt(DialogHolder).push(cmp);
    return () => getIt(DialogHolder).pop(cmp);
}

export function closeDialog(cmp: JSX.Element) {
    getIt(DialogHolder).pop(cmp);
}

export function DialogHelper() {

    const [state, setState] = useState({ cmps: new Array<{ id: string, element: JSX.Element }>() });

    function onUpdate(cmps: Array<{ id: string, element: JSX.Element }>) {
        setState({ cmps });
    }

    useEffect(() => {
        getIt(DialogHolder).setListener(onUpdate);
        return () => {
            getIt(DialogHolder).removeListener();
        }
    }, []);

    return (
        <div style={{ minWidth: "280px" }}>
            {state.cmps && state.cmps.map((cmp, index) => <div key={cmp.id}>{cmp.element}</div>)}
        </div>
    )
}
