import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider, CssBaseline } from '@mui/material';
import { DialogHelper } from 'ap-components';
import App from './App';
import theme from './theme';
import { createRoot } from 'react-dom/client';
import "./index.css"

createRoot(document.querySelector('#root')!).render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <App />
      <DialogHelper key={'apDialogHelper'} />
    </ThemeProvider>
  </StyledEngineProvider>
);