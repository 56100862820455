import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, useDeviceType } from "ap-components";
import { useRef, useState } from "react";
import CalculatorInput from "../CalculatorInput";
import CalculatorFormulas from "../CalculatorFormulas";
import { ChartValue, ScreenShot } from "./Emi";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import { APCharts } from "../../../Common/Charting";


export default function Lumpsum() {

    const targetElementRef = useRef<HTMLDivElement>(null);
    const deviceType = useDeviceType()

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Lumpsum Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };


    const [initialValue, setInitialValue] = useState({
        investedAmount: 500000,
        investmentPeriod: 5,
        inflationRate: 12
    });

    var out = CalculatorFormulas.lumpsumCalculation(initialValue);
    var totalInvested = out[0]?.amount;
    var gains = out[out.length - 1]?.gain;
    var maturityValue = out[out.length - 1]?.maturity;

    var option = {
        legend: {
            data: ['Gains', 'Total Investment'],
            position: "center",

        },
        grid: {
            left: '14%',
            bottom: "15%",
            right: "1%"
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none',
            },
        },
        xAxis: [
            {
                type: 'category',
                data: out?.map(year => year?.time),
                axisPointer: {
                    type: 'shadow',
                    label: {
                        formatter: (v: any) => {
                            return "Year " + v.value;
                        }
                    }
                },
                axisLabel: {
                    formatter: 'Year {value}'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                min: 0,
                axisLabel: {
                    width: "10px",
                    position: "right",
                    formatter: (v: number) => {
                        return v.currency(1, true);
                    }
                }
            },
        ],
        position: "left",
        series: [
            {
                name: 'Total Investment',
                type: 'bar',
                stack: 'maturity',
                itemStyle: {
                    color: '#268EFF'
                },
                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency(2, true);
                    }
                },
                data: out?.map(sum => sum?.amount),
            },
            {
                name: 'Gains',
                type: 'bar',
                stack: 'maturity',
                itemStyle: {
                    color: '#5DD27A'
                },
                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency(2, true);
                    }
                },
                data: out?.map(gain => gain?.gain),
            },
        ]
    }

    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween" >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>Lumpsum Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >
                        <APImage src="/icons/icon-camera.svg" />
                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />
            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}  >

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>
                        <CalculatorInput
                            label="Investment Amount"
                            dataType="amount"
                            value={initialValue.investedAmount}
                            sliderMax={2000000}
                            inputMax={1000000000}
                            inputMin={100}
                            sliderMin={10000}
                            sliderStep={10000}
                            onChange={(value: any) => {
                                setInitialValue({ ...initialValue, investedAmount: value });
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Duration"
                            dataType="age"
                            value={initialValue.investmentPeriod}
                            sliderMax={30}
                            inputMin={1}
                            inputMax={50}
                            sliderMin={1}
                            sliderStep={1}
                            onChange={(value: any) => {
                                setInitialValue({ ...initialValue, investmentPeriod: value });
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Expected Returns"
                            dataType="percentage"
                            value={initialValue.inflationRate}
                            sliderMax={30}
                            inputMin={1}
                            inputMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            onChange={(value: any) => {
                                setInitialValue({ ...initialValue, inflationRate: value });
                            }}
                        />
                    </div>
                </APExpanded>

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot label="Investment Amount" value={initialValue.investedAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Duration" value={initialValue.investmentPeriod} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="percentage" label="Expected Returns" value={initialValue.inflationRate} />
                    </div>
                </APExpanded>


                <APSizedBox width="32px" />

                <APExpanded flex={3}>
                    <APColumn crossAxisAlignment="stretch" style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }} >

                        <APCharts height="320px" option={option} />

                        <div style={{
                            flexDirection: "row", display: "flex", flexWrap: "wrap", gap: "8px", justifyContent: "space-between"
                        }}>
                            < ChartValue label={"Total Invested"} value={totalInvested} style={{ color: "#268EFF" }} />

                            < ChartValue label={"Maturity Value"} value={maturityValue} style={{ color: APPalette["grey-700"] }} />

                            < ChartValue label={"Gains"} value={gains} style={{ color: "#5DD27A" }} />
                        </div>

                    </APColumn>
                </APExpanded>

            </div>
        </div>
    )
}