import { APCard, APCenter, APColumn, APExpanded, APInkWell, APPalette, APRow, APScrollView, APSizedBox, APText, getIt, handleErrorMessage, launchDialog } from 'ap-components'
import SelfDeclarationModel, { NomineeValidationModel, SelfAPIReq, SelfValidationModel } from './Models.tsx/SelfDeclarationModel'
import { ChevronRightRounded } from '@mui/icons-material';
import APAlertBar from 'ap-components/src/elements/APAlertBar';
import SelfValidationDialog from './SelfValidationDialog';
import LinkPanDialog from './LinkPanDialog';
import NomineeDialog from './NomineeDialog';
import { useEffect, useState } from 'react';
import VerificationDialog from './VerificationDialog';
import ClientApiServices from '../../Services/ClientApiServices';
import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router';
import { launchPage } from '../../Common/Utils';
import { CircularProgress } from '@mui/material';



export default function RegulatoryValidation(props: RouteComponentProps) {
    var location = useLocation();
    const token = new URLSearchParams(location.search).get("tkn");
    const [data, setData] = useState<SelfDeclarationModel>();
    const [req, setReq] = useState<SelfAPIReq>();

    useEffect(() => {
        getDetails()

    }, [token])

    if (!token) {
        window.location.href = "/";
    }

    async function getDetails() {
        if (!token) { return }

        try {
            var res = await getIt(ClientApiServices).getDetails(token);
            setData(res)
            setReq({
                nominee: res.nominee.name,
                nomineeRelation: res.nominee.relationship,
                email: res.self.email,
                emailOwner: res.self.emailOwner,
                mobile: res.self.mobile,
                mobileOwner: res.self.mobileOwner,
            })
        } catch (error) {
            handleErrorMessage(error);
        }
    }

    async function updateSelf() {
        if (req && token && req.emailOwner && req.mobileOwner && req.nominee && req.nomineeRelation) {
            try {
                await getIt(ClientApiServices).updateSelfValidation(req, token);
                window.location.href = "/regulatory_validation/successPage";
            } catch (error) {
                handleErrorMessage(error)
            }
        }
    }

    useEffect(() => {
        updateSelf()
    }, [req])


    if (!data || !req) {
        return (
            <APCenter>
                <CircularProgress />
            </APCenter>
        )
    }

    if (!token) {
        launchPage('/home')
        return <></>
    }

    return (
        <APColumn mainAxisSize='max' style={{ padding: "16px", width: "100vw" }} >
            <APExpanded>
                <APScrollView>
                    <APColumn>
                        <APColumn crossAxisAlignment='stretch' style={{ maxWidth: "min(500px, 100%)" }} gap="16px">
                            <APColumn crossAxisAlignment='stretch'>
                                <APText variant='h3'>Dear {data.name}</APText>
                                <APSizedBox height='8px' />
                                <APAlertBar
                                    style={{ alignItems: "start" }}
                                    padding="12px"
                                    severity="warning"
                                    content={
                                        <APText variant='paragraph-small'>Due to regulatory changes by SEBI, we need the following details from your end to ensure smooth transactions and withdrawals for your investments</APText>
                                    }
                                />

                            </APColumn>

                            <DeclarationCard
                                content='Self Declaration'
                                onClick={
                                    (req.emailOwner && req.mobileOwner) ?
                                        undefined
                                        :
                                        async () => {

                                            try {
                                                if (!token) {
                                                    throw new Error("Unauthorized Access")
                                                }

                                                var res = await launchDialog<SelfValidationModel>(deferred => <SelfValidationDialog deferred={deferred} details={data.self} name={data.name} />);
                                                await launchDialog<void>(deferred => <VerificationDialog deferred={deferred} phone={res.mobile} email={res.email} token={token!} />)
                                                setReq(prev => ({ ...prev, ...res }))
                                            } catch (error) {
                                                handleErrorMessage(error)
                                            }
                                        }
                                }
                            />

                            <DeclarationCard
                                content='Nominee Details'
                                onClick={
                                    (req.nominee && req.nomineeRelation) ?
                                        undefined
                                        :
                                        async () => {
                                            try {
                                                if (!token) {
                                                    throw new Error("Unauthorized Access")
                                                }

                                                var res = await launchDialog<NomineeValidationModel>(deferred => <NomineeDialog deferred={deferred} details={data.nominee} />)
                                                setReq(prev => ({ ...prev, nominee: res.name, nomineeRelation: res.relationship }))
                                            } catch (error) {
                                                handleErrorMessage(error)
                                            }
                                        }
                                }
                            />

                            {/* {
                                data.members &&
                                <>
                                    <APText variant='h3'>Family Account Declaration</APText>
                                    {
                                        data.members?.map(member => (
                                            <DeclarationCard
                                                key={member.userId}
                                                content={member.name}
                                                onClick={
                                                    member.status == "COMPLETED" ?
                                                        undefined
                                                        :
                                                        async () => {
                                                            try {
                                                                if (!token) {
                                                                    throw new Error("Unauthorized Access")
                                                                }

                                                                if (member.self.status != "COMPLETED") {
                                                                    let res = await launchDialog<SelfValidationModel>(deferred => <SelfValidationDialog deferred={deferred} details={member.self} />)
                                                                    await launchDialog<void>(deferred => <VerificationDialog deferred={deferred} phone={res.mobile} email={res.email} token={token!} />)
                                                                    await getIt(ClientApiServices).updateMemberSelfValidation(res, token);
                                                                }

                                                                if (member.nominee.status != "COMPLETED") {
                                                                    let res = await launchDialog<NomineeValidationModel>(deferred => <NomineeDialog deferred={deferred} details={member.nominee} />)
                                                                    await getIt(ClientApiServices).updateMemberNomineeValidation(res, token);
                                                                }

                                                                if (!member.relationship) {
                                                                    let res = await launchDialog<string>(deferred => <RelationshipDialog deferred={deferred} />)
                                                                    await getIt(ClientApiServices).updateMemberRelationshipValidation(res, token);
                                                                }

                                                                await getDetails()

                                                            } catch (error) {
                                                                handleErrorMessage(error)
                                                            }
                                                        }
                                                }
                                            />
                                        ))
                                    }
                                </>
                            } */}

                            <APAlertBar
                                padding='12px'
                                style={{ alignItems: "start" }}
                                content={
                                    <APColumn crossAxisAlignment='stretch'>
                                        <APText color={APPalette['info-400']} variant='paragraph-small'>As per the SEBI guidelines, it is mandatory to link Aadhaar with PAN by 31st March</APText>
                                        <APSizedBox height='8px' />
                                        <APText
                                            variant='link-small'
                                            onClick={async () => {
                                                try {
                                                    await launchDialog<void>(deferred => <LinkPanDialog deferred={deferred} />)
                                                } catch (error) {
                                                    handleErrorMessage(error)
                                                }
                                            }}
                                        >
                                            know more
                                        </APText>
                                    </APColumn>
                                }
                            />
                        </APColumn>
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    )
}

function DeclarationCard({ onClick, content }: { content: string, onClick?: () => any }) {
    return (
        <APInkWell
            disabled={!onClick}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        >
            <APCard style={{ borderRadius: "8px", padding: "8px" }}>
                <APRow>
                    <APExpanded>
                        <APText>{content}</APText>
                    </APExpanded>
                    {
                        onClick ?
                            <APRow mainAxisSize='min'>
                                <APText variant='paragraph-small' colored={-1}>Add Details</APText>
                                <APSizedBox width='2px' />
                                <ChevronRightRounded />
                            </APRow>
                            :
                            <APText colored={1}>Completed</APText>
                    }
                </APRow>
            </APCard>
        </APInkWell>
    )
}