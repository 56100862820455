import { InternalSVGImage } from "../dialogs";
import { APExpanded, APRow } from "../layout";
import { APPalette } from "../utils";

export type Severity = 'positive' | 'negative' | 'info' | 'warning';

export interface AlertBarProps {
    content: React.ReactElement,
    style?: React.CSSProperties,
    /**
     * @default Severity.info
     */
    severity?: Severity,
    /**
     * @default true
     */
    includeIcon?: boolean,
    /**
     * @default 24
     */
    iconSize?: number,
    /**
     * @default 8px 16px
     */
    padding?: string,
    /**
     * Gap between leadingIcon and Content
     * @default 8
     */
    gap?: number
}

export default function APAlertBar({ severity = "info", includeIcon = true, content, padding = "8px 16px", gap = 8, style, iconSize = 24 }: AlertBarProps) {

    let _style: React.CSSProperties = {
        backgroundColor: APPalette["info-100"],
        color: APPalette["info-400"],
        borderRadius: "8px"
    }
    let iconName = 'info-snackbar';

    switch (severity) {
        case "positive":
            _style.backgroundColor = APPalette["positive-100"];
            _style.color = APPalette["positive-400"];
            iconName = 'check-snackbar';
            break;
        case "negative":
            _style.backgroundColor = APPalette["negative-100"];
            _style.color = APPalette["negative-400"];
            iconName = 'error-snackbar';
            break;
        case "warning":
            _style.backgroundColor = APPalette["warning-100"];
            _style.color = APPalette["warning-400"];
            iconName = 'warning-snackbar';
            break;
    }

    return (
        <APRow gap={`${gap}px`} style={{ padding, ...style, ..._style }}>
            {
                includeIcon && <InternalSVGImage size={iconSize} name={iconName} />
            }
            <APExpanded>
                {content}
            </APExpanded>
        </APRow>
    )
}