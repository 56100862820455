import { APColumn, APText, APSizedBox, APTag } from "ap-components";
import { KYCDigilockerSchema } from "../../Services/MutualFundServices";

export function DigilockerKYCIntro({ kycForm }: { kycForm: KYCDigilockerSchema }) {
    return <APColumn crossAxisAlignment='start' gap="12px">

        <APText variant='h3'>Hello {kycForm.name},</APText>
        <APText variant="subtext">
            Please verify the contact details before proceeding:
            <br />Email: {kycForm.email}
            <br />Mobile: {kycForm.mobile}
            <br />Tax Status: <strong>Resident Individual</strong>
            <br />(If the details are incorrect, please contact our support team)
        </APText>

        <APColumn crossAxisAlignment='start'>
            <APText>Documents to be kept ready for KYC</APText>
            <APText>1. Image of your signature</APText>
            <APText>2. Photograph of the applicant</APText>
            <APText>3. Phone number linked to Aadhaar</APText>
            <APText>4. Proof of Identity: PAN Image</APText>
        </APColumn>

        {
            kycForm.digilockerStatus == "APPROVED"
                ? <APColumn crossAxisAlignment='start' gap="12px" style={{ marginTop: "12px" }}>
                    <APText variant="h3">Digilocker Approved</APText>
                    <APText>Click on <strong>Next</strong> to proceed</APText>
                </APColumn>
                : <APColumn crossAxisAlignment='start' style={{ paddingTop: "12px" }}>
                    <APText>Click on <strong>Next</strong> to get redirected to Digilocker</APText>
                </APColumn>
        }

    </APColumn>
}


export function addParamsToCurrentUrl(newParams: { [key: string]: string }) {

    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    Object.entries(newParams).forEach(([key, value]) => {
        searchParams.set(key, value);
    });

    currentUrl.search = searchParams.toString();

    return currentUrl.toString();
};


export function launchUrl(url: string) {
    window.location.href = url;
}



export let OccupationTable = [
    { value: "01", label: "Private Sector Service" },
    { value: "02", label: "Public Sector" },
    { value: "03", label: "Business" },
    { value: "04", label: "Professional" },
    { value: "05", label: "Agriculturist" },
    { value: "06", label: "Retired" },
    { value: "07", label: "Housewife" },
    { value: "08", label: "Student" },
    { value: "09", label: "Forex Dealer" },
    { value: "10", label: "Government Service" },
    // { value: "11", label: "PUBLIC SECTOR / GOVERNMENT SERVICE Only allowed for Download. Will be present only in MF Records" },
    // { value: "99", label: "Others" },
]