import { RotateLeft, RotateRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Deferred, APSizedBox, handleErrorMessage, showErrorDialog, APAsyncButton, APColumn, APDialogCard, APRow } from "ap-components";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";
import React, { useRef, useState } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

export default function ImageCircleCropDialog({ base64, deferred }: { base64: string, deferred: Deferred<string> }) {

    // Getting the width of the device - mobile/desktop
    const { width: windowWidth } = useWindowDimensions();

    const [crop, setCrop] = useState<Crop>({
        unit: "px", width: (windowWidth && windowWidth <= 600) ? 200 : 460, height: (windowWidth && windowWidth <= 600) ? 200 : 460, x: 30, y: 30
    });
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const imgRef = useRef<HTMLImageElement>(null)
    const [rotate, setRotate] = useState(0);

    function rotateRight() {
        if (rotate === 180)
            setRotate(-90)
        else
            setRotate(val => val + 90);
    }

    function rotateLeft() {
        if (rotate === -180)
            setRotate(90)
        else
            setRotate(val => val - 90);
    }

    // Setting the width of the dialog as per the device. 
    // This will be used in mWeb(Flow) project as the same is being used there also.
    let dialogWidth: React.CSSProperties | undefined;
    if (windowWidth && windowWidth <= 730) {
        dialogWidth = { maxWidth: `${windowWidth - 74}px`, minWidth: `${windowWidth - 74}px` };
    }
    else {
        dialogWidth = {
            maxWidth: "600px"
        }
    }

    function getScale() {
        if (!imgRef.current) {
            return 1;
        }

        if ([90, -90].includes(rotate)) {
            var scale = imgRef.current.height / imgRef.current.width;
            if (scale > 1) {
                return 1 / scale;
            }
            return scale
        }

        return 1
    }

    return (
        <APDialogCard title="Crop Image" style={dialogWidth} trailing={
            (windowWidth && windowWidth <= 730) ?
                <></>
                :
                <APRow gap="12px" mainAxisAlignment="center">
                    <IconButton onClick={rotateLeft}>
                        <RotateLeft />
                    </IconButton>
                    Rotate
                    <IconButton onClick={rotateRight}>
                        <RotateRight />
                    </IconButton>
                </APRow>
        }>
            <APColumn>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    ruleOfThirds
                    circularCrop={true}
                    aspect={1}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={base64}
                        style={{ transform: `scale(${getScale()}) rotate(${rotate}deg)` }}
                    />
                </ReactCrop>
                <APSizedBox height="24px" />
                <APRow mainAxisAlignment="center">
                    <APAsyncButton
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            if (crop && imgRef.current && completedCrop) {
                                try {
                                    var croppedBase64 = await imgPreview(
                                        imgRef.current,
                                        completedCrop,
                                        getScale(),
                                        rotate
                                    );
                                    deferred.resolve(croppedBase64);
                                } catch (error) {
                                    handleErrorMessage(error);
                                }
                            }
                            else {
                                showErrorDialog("Please crop the image");
                            }
                        }}
                    >
                        Submit
                    </APAsyncButton>
                </APRow>
            </APColumn>
        </APDialogCard >
    )
}

function canvasToBase64(canvas: HTMLCanvasElement): string {
    return canvas.toDataURL('image/jpeg', 0.5);
}

async function imgPreview(
    image: HTMLImageElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0,
) {
    const canvas = document.createElement('canvas')
    canvasPreview(image, canvas, crop, scale, rotate)

    return canvasToBase64(canvas);
}


async function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0,
) {
    const TO_RADIANS = Math.PI / 180
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    // const pixelRatio = window.devicePixelRatio
    const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}