import { useMemo } from 'react';
import { APFormControl } from './Common';


export function useAPForm(): APFormControl {
    var control: APFormControl = useMemo<APFormControl>(() => {

        return {
            fields: new Set(),
            validate: async () => {
                var validation = true;
                for (const item of Array.from(control.fields)) {
                    var resp = await item.validate();
                    validation = validation && resp;
                }
                return validation;
            },
            reset: () => {
                for (const item of Array.from(control.fields)) {
                    item.reset();
                }
            },
            submit: () => {
                console.log("Submit the form");
            }
        };
    }, []);

    return control;

}
