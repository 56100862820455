import React from 'react';
import { APClone } from './Common';


export function APStack(props: { children: any, style?: React.CSSProperties, ref?: any }) {
    return <div style={{ ...props.style, position: 'relative' }} ref={props.ref}>
        {props.children}
    </div>
}

type Alignment = 'topLeft' | 'topCenter' | 'topRight' | 'centerLeft' | 'center' | 'centerRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'fill';


export function APAlign(props: { children: React.ReactElement, style?: React.CSSProperties, align: Alignment, noClone?: boolean }) {
    var align: any;

    switch (props.align) {
        case 'topLeft': align = { top: "0", left: "0" }; break;
        case 'topCenter': align = { top: "0", left: "50%", transform: "translateX(-50%)" }; break;
        case 'topRight': align = { top: "0", right: "0" }; break;
        case 'centerLeft': align = { top: "50%", left: "0", transform: "translateY(-50%)" }; break;
        case 'center': align = { top: "50%", left: "50%", transform: "translate(-50%,-50%)" }; break;
        case 'centerRight': align = { top: "50%", right: "0", transform: "translateY(-50%)" }; break;
        case 'bottomLeft': align = { bottom: "0", left: "0", }; break;
        case 'bottomCenter': align = { bottom: "0", left: "50%", transform: "translateX(-50%)" }; break;
        case 'bottomRight': align = { bottom: "0", right: "0", }; break;
        case 'fill': align = { height: "100%", width: "100%" }; break;
    }


    return <APClone style={{ ...props.style, position: 'absolute', ...align }} noClone={props.noClone}>
        {props.children}
    </APClone>
}