import { RouteComponentProps } from "@reach/router";
import { APAlign, APButton, APColumn, APExpanded, APImage, APRow, APSizedBox, APStack, APText, convertBase64, handleErrorMessage, launchDialog, useFilePicker } from "ap-components";
import { useEffect, useState } from "react";
import ProcessingDialog from "./ProcessingDialog";
import { Person, PlayArrow } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";
import ImageCircleCropDialog from "../../Common/ImageCircleCropDialog";
import axios from "axios";

export default function ProfileFrameDownloader(props: RouteComponentProps) {
    var [img, setImg] = useState<string>();
    var [selectedImage, setSelectedImage] = useState<string>();
    var { files, onClick, HiddenFileInput, reset } = useFilePicker();
    var file: File | null = null;
    const { width: windowWidth } = useWindowDimensions();

    if (files) {
        file = files[0];
    }

    useEffect(() => {
        if (file) {
            try {
                mergeImage(file);
            } catch (error) {
                handleErrorMessage(error);
            }
        }
    }, [file])

    async function mergeImage(file: File) {
        try {
            var base64 = await convertBase64(file) as string;
            var newBase64 = await launchDialog<string>(deferred => <ImageCircleCropDialog deferred={deferred} base64={base64} />);
            setSelectedImage(newBase64);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')
            if (!ctx) {
                handleErrorMessage(new Error('No 2d context'));
                return;
            }
            canvas.height = 500;
            canvas.width = 500;
            ctx.beginPath();
            ctx.arc(250, 250, 220, 0, 2 * Math.PI);
            ctx.clip();
            ctx.stroke();

            var ProfilePic = new Image();
            ProfilePic.src = newBase64;

            ProfilePic.onload = function () {
                var FrameTemplate = new Image();
                FrameTemplate.src = 'freedomFighterFrame/FFF3.png';

                ctx.drawImage(ProfilePic, 24, 20, 450, 450);
                FrameTemplate.onload = async function () {
                    try {
                        ctx.drawImage(FrameTemplate, -5, -5, 510, 510);
                        await launchDialog<void>(deferred => <ProcessingDialog deferred={deferred} />, { disableClose: true });
                        setImg(canvas.toDataURL("image/png"));
                    } catch (error) {
                        handleErrorMessage(error)
                    }
                }
            }
        } catch (error) {
            handleErrorMessage(error);
        }
    }

    async function downloadImage() {
        try {
            var a = document.createElement("a");
            a.href = img!;
            a.download = "Profile Frame.jpg";
            a.click();

            try {
                // This records the download event on Google sheet
                await axios.post("https://n8n.assetplus.in/webhook/a88e21da-7ab5-49b7-bba3-21697a70181e");
            } catch (error) { }

            // await launchDialog<void>(deferred => <EmailDialog deferred={deferred} />);
        } catch (error) {
            handleErrorMessage(error);
        }
    }

    if (windowWidth && windowWidth <= 600) {
        return (
            <APColumn mainAxisAlignment="center" crossAxisAlignment="center" mainAxisSize="max" style={{ color: "#133d6e" }}>
                <APColumn>
                    <APImage src="freedomFighterFrame/FrameSample.jpg" height="250" width="250" style={{ borderRadius: '50%', border: '4px dashed #b3b3b3' }} />
                    <APSizedBox height="20px" />
                    <VerticalArrowImage />
                    <APSizedBox height="20px" />
                    {
                        !img ?
                            <>
                                <APSizedBox style={{ border: "7px dashed #b3b3b3", borderRadius: "50%", height: "250px", width: "250px" }} noClone>
                                    <APStack style={{ display: "flex", flexDirection: "column" }} >
                                        <img
                                            srcSet="freedomFighterFrame/FFF3.png 1x,freedomFighterFrame/FFF3@2x.png 2x,freedomFighterFrame/FFF3@3x.png 3x"
                                            alt="Freedom Fighter Frame"
                                            height={250}
                                            width={250}
                                        />
                                        <APAlign align="topCenter">
                                            <Person style={{ fontSize: "100px", top: "18px" }} />
                                        </APAlign>
                                    </APStack>
                                </APSizedBox>
                                <APSizedBox height="20px" />
                                <APButton variant="contained" onClick={onClick} style={{ backgroundColor: "#d23833", color: "white", borderRadius: "25px" }} >Upload Photo</APButton>
                                <HiddenFileInput accept="image/*" />
                            </>
                            :
                            <>
                                <APSizedBox style={{ border: "7px dashed #b3b3b3", borderRadius: "50%", height: "250px", width: "250px", padding: "8px" }}>
                                    <APImage src={img} height="240" width="240" style={{ borderRadius: '50%' }} />
                                </APSizedBox>
                                <APSizedBox height="20px" />
                                <APButton variant="contained" onClick={downloadImage} style={{ backgroundColor: "#d23833", color: "white", borderRadius: "25px", width: "120px" }} >Download</APButton>
                            </>
                    }
                </APColumn>
            </APColumn>
        )
    }

    return (
        <APColumn mainAxisAlignment="center" crossAxisAlignment="stretch" mainAxisSize="max" gap="20px" style={{ color: "#133d6e", padding: "0 24px" }}>
            <APRow>
                <APExpanded>
                    <APColumn>
                        <APImage src="freedomFighterFrame/FrameSample.jpg" height="250" width="250" style={{ borderRadius: '50%', border: '4px dashed #b3b3b3' }} />
                    </APColumn>
                </APExpanded>

                <APExpanded noClone>
                    <ArrowImage />
                </APExpanded>

                <APExpanded>
                    <APColumn>
                        <APColumn
                            style={{ height: "250px", width: "250px", borderRadius: '50%', border: '4px dashed #b3b3b3' }}
                            mainAxisSize="max"
                            mainAxisAlignment="center"
                            crossAxisAlignment="center"
                        >
                            {
                                selectedImage &&
                                <div
                                    style={{ height: "224px", width: "224px", overflow: "hidden", borderRadius: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                                >
                                    <APImage src={selectedImage} style={{ maxHeight: "100%", height: "100%", width: "auto", objectFit: "cover" }} />
                                </div>

                            }
                        </APColumn>
                    </APColumn>
                </APExpanded>

                <APExpanded noClone>
                    <ArrowImage />
                </APExpanded>

                <APExpanded noClone>
                    <>
                        <APColumn mainAxisAlignment="center" crossAxisAlignment="center">
                            {
                                img ?
                                    <APColumn style={{ border: '4px dashed #b3b3b3', borderRadius: "50%", height: "250px", width: "250px" }}>
                                        <APImage src={img} height="240" width="240" style={{ borderRadius: '50%' }} />
                                    </APColumn>
                                    :
                                    <APText>Your badge will appear here</APText>
                            }
                        </APColumn>
                        <HiddenFileInput accept="image/*" />
                    </>
                </APExpanded>
            </APRow>
            <APRow>
                <APExpanded>
                    <APText style={{ fontSize: "18px", textAlign: "center", lineHeight: 1.6 }}>
                        Get your Financial <br /> Freedom Fighter Badge
                    </APText>
                </APExpanded>
                <APExpanded noClone>
                    <ArrowImage hidden />
                </APExpanded>
                <APExpanded noClone>
                    <APRow mainAxisAlignment="center">
                        <APButton variant="contained" color="primary" onClick={onClick} style={{ borderRadius: "25px" }} >
                            <span>Upload {`${selectedImage ? "Again" : "Photo"}`}</span>
                        </APButton>
                    </APRow>
                </APExpanded>
                <APExpanded noClone>
                    <ArrowImage hidden />
                </APExpanded>
                <APExpanded noClone>
                    <>
                        {
                            img &&
                            <APRow mainAxisAlignment="center">
                                <APButton variant="contained" onClick={downloadImage} style={{ backgroundColor: "#d23833", color: "white", borderRadius: "25px" }} >Download</APButton>
                            </APRow>
                        }
                    </>
                </APExpanded>
            </APRow>
        </APColumn >
    )
}

function VerticalArrowImage({ hidden }: { hidden?: boolean }) {
    return (
        <APColumn mainAxisAlignment="center" style={{ visibility: hidden ? "hidden" : undefined }}>
            <Divider style={{ height: "20px", width: "4px", backgroundColor: "#808080", borderRadius: "4px" }} />
            <APSizedBox height="4px" />
            <Divider style={{ height: "20px", width: "4px", backgroundColor: "#808080", borderRadius: "4px" }} />
            <APSizedBox height="4px" />
            <Divider style={{ height: "20px", width: "4px", backgroundColor: "#808080", borderRadius: "4px" }} />
            <PlayArrow style={{ color: "#808080", transform: "rotate(90deg)" }} />
        </APColumn>
    )
}

function ArrowImage({ hidden }: { hidden?: boolean }) {
    return (
        <APRow mainAxisAlignment="center" style={{ visibility: hidden ? "hidden" : undefined }}>
            <Divider style={{ height: "4px", width: "20px", backgroundColor: "#808080", borderRadius: "4px" }} />
            <APSizedBox width="4px" />
            <Divider style={{ height: "4px", width: "20px", backgroundColor: "#808080", borderRadius: "4px" }} />
            <APSizedBox width="4px" />
            <Divider style={{ height: "4px", width: "20px", backgroundColor: "#808080", borderRadius: "4px" }} />
            <PlayArrow style={{ color: "#808080" }} />
        </APRow>
    )
}