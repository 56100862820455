import { useLocation } from "@gatsbyjs/reach-router";
import { RouteComponentProps } from "@reach/router";
import { APColumn, APText, APImage, APSizedBox, APPalette, APStack, APAlign, WebStoryTile, APRow, APFormFieldText, APButton, handleErrorMessage, getIt, APForm, useAPForm, launchDialog, NArray, APAsyncButton, APExpanded, REGEX } from "ap-components";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";
import React, { useEffect, useState } from "react";
import PartnerApiServices from "../../Services/PartnerApiServices";
import { AdvisorInfo, WebStoryModel } from "./Models/WebStoryModel";
import OtpConfirmationDialog from "./OTPConfirmationDialog";
import CMS2Services from "../../Services/CMS2Services";
import { Skeleton } from "@mui/material";

interface IUserForm {
    firstName: string;
    phoneNumber: string;
}
const defaultImage = "../icons/icon-profile.svg";
export default function WebStory(_: RouteComponentProps) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const advisorId = searchParams.get("advisorId");
    const id = searchParams.get("id");
    const { width } = useWindowDimensions();


    const [currentStory, setCurrentStory] = useState(0);
    const [advisorInfo, setAdvisorInfo] = useState<AdvisorInfo>();
    // using downloadLink for three different screens, undefined initial screen , "" on the the existing user success screen and with link for new users
    const [downloadLink, setDownloadLink] = useState<string>();
    const [webStory, setWebStory] = useState<WebStoryModel>();

    function handleToggleEvents(event: React.MouseEvent<HTMLImageElement, MouseEvent>) {
        const trackTouch = event.nativeEvent.offsetX;
        const captureWidth = width! < 600 ? width! / 3 : 90;

        if (trackTouch >= captureWidth) {
            const nextStory = currentStory + 1;
            if (nextStory <= slides.length) {
                setCurrentStory(nextStory);
            }
            return;
        }
        const prevStory = currentStory - 1;
        if (currentStory !== 0) {
            setCurrentStory(prevStory);
        }
    }

    async function getPartnerDetails() {
        try {
            if (advisorId) {
                const res = await getIt(PartnerApiServices).getAdvisorInfo(advisorId);
                setAdvisorInfo(res);
            }
        } catch (error) {
            handleErrorMessage(error);
        }
    }

    async function getWebStory() {
        try {
            if (id) {
                const res = await getIt(CMS2Services).getWebStoryPosterById(id);
                setWebStory(res);
            }
        } catch (error) {
            console.log({ error });

        }
    }

    async function updateShareCount() {
        try {
            if (id) {
                await getIt(CMS2Services).webStoryShareCount({ _id: id });
            }
        } catch (error) {
            console.log({ error });
        }
    }

    async function onFormSubmit(form: IUserForm) {
        try {
            const res = await getIt(PartnerApiServices).generateOTP(
                {
                    ...form,
                    title: webStory?.title ?? "",
                    subAdvisorId: advisorId!,
                    campaign: "webstories"
                }
            )
            if (res.eventAdded) {
                setDownloadLink("");
            }
            if (res.otpGenerated) {
                const url: string = await launchDialog<string>(
                    (deferred) => (
                        <OtpConfirmationDialog
                            deferred={deferred}
                            initialForm={{
                                ...form,
                                subAdvisorId: advisorId ?? "",
                                title: webStory?.title ?? ""
                            }}
                        />
                    )
                );
                setDownloadLink(url);
            }
        } catch (error) {
            if (error instanceof Error) {
                if (error.message.includes("has already been registered")) {
                    return setDownloadLink("https://www.assetplus.in/link/app");
                }
            }
            handleErrorMessage(error);
        }
    }

    useEffect(() => {
        getPartnerDetails();
        getWebStory();
        updateShareCount();
    }, []);

    var slides = webStory?.slides.map(i => i.url!) ?? [];
    const isFormVisible = (currentStory === webStory?.slides.length) || !webStory;


    var contentComponent;


    if (isFormVisible) {
        if (downloadLink === "") {
            contentComponent = <div>
                <APAlign align="topLeft">
                    <APColumn>
                        <WebStoryTile variant="secondary" thickness={3} storyCount={slides.length + 1} currentStory={currentStory} />
                    </APColumn>
                </APAlign>
                <APAlign align="center">
                    <APColumn>
                        <APImage
                            height={"400px"}
                            src="/success-frame.png"
                        />
                    </APColumn>
                </APAlign>
            </div>
        }
        else {
            contentComponent = <FormSlide
                currentStory={currentStory}
                slides={slides}
                advisorInfo={advisorInfo}
                downloadLink={downloadLink}
                onFormSubmit={onFormSubmit}
            />
        }
    }
    else {
        contentComponent = <StoryStack
            advisorInfo={advisorInfo}
            currentStory={currentStory}
            images={slides}
            isMobileSize={width! < 600}
        />
    }


    if (!advisorInfo) {
        contentComponent =
            <APExpanded>
                <APColumn style={{ backgroundColor: APPalette.white, padding: "21px 15px" }} mainAxisSize="max" mainAxisAlignment="spaceBetween">
                    <APRow crossAxisAlignment="stretch" mainAxisAlignment="spaceBetween">
                        <APRow gap="12px" mainAxisSize="min" >
                            <APSizedBox width="48px" height="48px">
                                <Skeleton width={48} height={48} variant="circular" />
                            </APSizedBox>
                            <APColumn crossAxisAlignment="start" gap="8px">
                                <Skeleton variant="rounded" width={121} height={16} />
                                <Skeleton width={69} height={8} variant="rounded" />
                            </APColumn>
                        </APRow>
                        <Skeleton variant="rounded" width={52} height={32} />
                    </APRow>
                    <APRow mainAxisAlignment="end">
                        <APColumn crossAxisAlignment="start" gap="12px">
                            <Skeleton variant="rounded" width={117} height={24} />
                            <Skeleton variant="rounded" width={214} height={24} />
                            <Skeleton variant="rounded" width={148} height={24} />
                            <Skeleton variant="rounded" width={282} height={24} />
                            <Skeleton variant="rounded" width={282} height={84} />
                        </APColumn>
                    </APRow>
                    <APRow mainAxisAlignment="end">
                        <Skeleton variant="rectangular" width="82px" height={16} />
                    </APRow>
                </APColumn>
            </APExpanded>
    }



    // svh
    return <APColumn
        style={{ width: "100vw", height: "100svh" }}
        mainAxisSize='max'
        crossAxisAlignment='center'
        mainAxisAlignment={"center"}
    >
        <APColumn>
            <APStack>
                <div
                    style={{
                        height: width! < 600 ? "100svh" : "640px",
                        width: width! < 600 ? "100vw" : "360px",
                        overflow: "hidden",
                        backgroundColor: isFormVisible ? APPalette.white : APPalette.black
                    }}
                    onClick={handleToggleEvents}
                >
                    {contentComponent}
                </div>
            </APStack>

        </APColumn>
    </APColumn >
}


function FormSlide({ advisorInfo, slides, currentStory, downloadLink, onFormSubmit }:
    {
        advisorInfo?: AdvisorInfo, slides: string[], currentStory: number,
        downloadLink?: string, onFormSubmit: (form: IUserForm) => Promise<void> | void
    }) {
    const [form] = useState<IUserForm>({
        firstName: "",
        phoneNumber: ""
    });

    const control = useAPForm();

    return <>
        <APAlign align="topLeft">
            <APSizedBox
                height={"159px"}
                style={{
                    backgroundColor: APPalette["brand-100"],
                }}
            >
                {
                    advisorInfo?.image_banner ? <APImage
                        style={{
                            objectFit: "contain",
                            height: "inherit",
                            width: "100%"
                        }}
                        src={advisorInfo?.image_banner}
                    /> : <APSizedBox height="159px" width="100%" />
                }
            </APSizedBox>
        </APAlign>
        <APAlign align="topCenter">
            <APSizedBox>
                <APImage
                    style={{
                        maxWidth: "102px",
                        maxHeight: "77px",
                        marginTop: "133px",
                        boxShadow: "0px 3px 6px 0px rgba(35, 39, 48, 0.20)"
                    }}
                    src={advisorInfo?.image_logo ? advisorInfo.image_logo : "../web_story_logo.png"} />
            </APSizedBox>
        </APAlign>
        <APAlign align="topLeft" >
            <APColumn>
                <WebStoryTile variant="secondary" thickness={2} storyCount={slides.length + 1} currentStory={currentStory} />
            </APColumn>
        </APAlign>
        <APAlign align="topCenter">
            <APColumn crossAxisAlignment="stretch" style={{ marginTop: "218px", padding: "0 16px" }}>
                <APText center variant="h3">
                    {advisorInfo?.title}
                </APText>
                <APText center variant="paragraph-small" >
                    {advisorInfo?.arnCode}
                </APText>
                <APText maxLines={2} center variant="paragraph-small" >
                    {advisorInfo?.subtitle}
                </APText>
                <APSizedBox height="24px" />
                {
                    downloadLink ?
                        <APColumn>
                            <APText variant="h3">Congratulations!</APText>
                            <APText variant="h3">You have successfully registered.</APText>
                            <APSizedBox height="24px" />
                            <APText center variant="paragraph-small">
                                You can now download the app by clicking the button below
                            </APText>
                            <APSizedBox height="32px" />
                            <APAsyncButton
                                style={{ width: "216px" }}
                                onClick={async () => window.open(downloadLink, "_blank")}
                                size="medium">
                                Open/Download App
                            </APAsyncButton>
                            <APSizedBox height="4px" />
                            <APText color={APPalette["grey-500"]} variant="paragraph-xsmall">
                                After downloading, please go to Login
                            </APText>
                        </APColumn>
                        :
                        <APColumn crossAxisAlignment="stretch" gap="8px">
                            <APText variant="h3" >
                                Enter your details below to know more
                            </APText>
                            <div
                                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                    e.stopPropagation();
                                }}>
                                <APForm control={control}>
                                    <APColumn crossAxisAlignment="stretch">
                                        <APFormFieldText
                                            validator={(v) => {
                                                if (!v.length) {
                                                    return "Invalid Name"
                                                }
                                                return null;
                                            }}
                                            onChanged={(v) => {
                                                form.firstName = v;
                                            }}
                                            label="Name"
                                        />
                                        <APFormFieldText
                                            validator={(v) => {
                                                if (!REGEX.PHONE.test(v)) {
                                                    return "Invalid Phone Number"
                                                }
                                                return null;
                                            }}

                                            onChanged={(v) => {
                                                form.phoneNumber = v;
                                            }}
                                            label="Phone"
                                        />
                                        <APSizedBox height="8px" />
                                        <APButton
                                            onClick={async () => {
                                                const validate = await control.validate();
                                                if (validate) {
                                                    onFormSubmit(form);
                                                }
                                            }}
                                        >Submit</APButton>
                                    </APColumn>
                                </APForm>
                            </div>
                        </APColumn>
                }
            </APColumn>
        </APAlign>
    </>
}


function StoryStack({ advisorInfo, currentStory, images, isMobileSize }: { advisorInfo?: AdvisorInfo, currentStory: number, images: string[], isMobileSize?: boolean }) {

    const [drag, setDrag] = useState(false);

    async function handleMove() {
        setDrag(_ => true);
        await new Promise((resolve) => {
            setTimeout(resolve, 2800)
        })
        setDrag(_ => false);
    }

    useEffect(() => {
        if (currentStory !== 0) {
            setDrag(false);
        }
    }, [currentStory])


    return <>
        <APAlign align="fill">
            <div
                onTouchMove={currentStory === 0 && !drag ? handleMove : undefined}
                style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage: `${drag ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),` : ""} url("${images[currentStory]}")`
                }}
            // src={images[currentStory]} 
            />

        </APAlign>
        <APAlign align="fill" >
            <div
                style={{
                    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0 ), rgba(0, 0, 0, 0.4 ) )`,
                    height: '80px',
                }}
            ></div>
        </APAlign>
        <APAlign align="fill" >
            <div
                style={{
                    backgroundSize: "cover",
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0,0.4))`,
                    marginTop: isMobileSize ? "95svh" : "605px",
                    height: '35px',
                }}
            ></div>
        </APAlign>
        {drag && <APAlign align="fill" >
            <APRow mainAxisSize="max" mainAxisAlignment="spaceBetween">
                <APRow>
                    <RenderArrow icon="/icons/icon-prev.png" label="Tap Back" />
                    <APColumn crossAxisAlignment="start" style={{ width: "50px", height: "100vh", overflow: "hidden", marginLeft: "-20px" }} gap="6px">
                        {
                            NArray(100).map(item => <div key={item} style={{ width: "1px", height: "6px", backgroundColor: APPalette.white }}></div>)
                        }
                    </APColumn>
                </APRow>
                <RenderArrow icon="/icons/icon-next.png" label="Tap Next" />
            </APRow>
        </APAlign>}
        <APAlign align="topLeft">
            <APColumn>
                <WebStoryTile variant="secondary" thickness={2} currentStory={currentStory} storyCount={images.length + 1} />
                <APRow crossAxisAlignment="stretch" style={{ padding: "0 8px" }}>
                    <APSizedBox>
                        <APImage variant="avatar"
                            width="40px"
                            height="40px"
                            src={advisorInfo?.profile_picture ? advisorInfo?.profile_picture : defaultImage} />
                    </APSizedBox>
                    <APSizedBox width="6px" />
                    <APRow
                        mainAxisSize="max"
                    >
                        <APColumn crossAxisAlignment="start">
                            <div style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: APPalette.white
                            }}>
                                {advisorInfo?.title}
                            </div>
                            <div style={{
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "16px",
                                color: APPalette.white
                            }}>
                                {advisorInfo?.arnCode}
                            </div>
                        </APColumn>
                    </APRow>
                    {
                        advisorInfo?.image_logo &&
                        <APRow mainAxisSize="min" mainAxisAlignment="end">
                            <APImage
                                style={{ borderRadius: "4px", width: "52px", height: "35px" }}
                                src={advisorInfo.image_logo}
                            />
                        </APRow>
                    }
                </APRow>
            </APColumn>
        </APAlign>
        <APAlign align="bottomLeft">
            <APRow style={{ padding: "10px 20px" }} mainAxisAlignment="end">
                <APSizedBox height="23px" width="53px" >
                    <APImage src="/icons/icon-assetplus.svg" />
                </APSizedBox>
            </APRow>
        </APAlign>
    </>
}

function RenderArrow({ label, icon }: { label: string, icon: string }) {
    return (<APColumn gap="16px">
        <img
            className="animate-navigation"
            style={{
                width: "48px",
                height: "48px",
            }}
            src={icon} alt="" />
        <APText style={{ fontSize: "16px", lineHeight: "24px" }} color={APPalette.white}>
            {label}
        </APText>
    </APColumn>);
}