import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { APText } from "../elements/APText";
import { APRow } from "../layout/APFlex";
import { APPalette } from '../utils';


export function APSwitcher<T>({
    options, onSelect, initial, width = "150px", variant = "default"
}: { options: T[]; onSelect: (label: T) => any; initial?: T; width?: string; variant?: "default" | "primary" }) {

    const [index, setIndex] = useState(() => {
        if (initial !== undefined) {
            return options.indexOf(initial);
        }
        return 0;
    });

    useEffect(() => {
        onSelect(options[index]);
        return () => {
        };
    }, [index]);

    let color = "#666";
    switch (variant) {
        case 'primary':
            color = APPalette['info-300'];
            break;
    }


    return <APRow mainAxisSize={'min'} crossAxisAlignment='center' mainAxisAlignment='spaceBetween' style={{ width: width, color }}>
        <IconButton onClick={() => setIndex((options.length + index - 1) % options.length)} size={'small'}>
            <NavigateBefore style={{ fontSize: "26px" }} htmlColor={color} />
        </IconButton>
        <APText ellipsis style={{ textTransform: 'uppercase', fontWeight: 600, fontSize: "13px" }} color={color}>{options[index]}</APText>
        <IconButton onClick={() => { setIndex((options.length + index + 1) % options.length); }} size={'small'}>
            <NavigateNext style={{ fontSize: "26px" }} htmlColor={color} />
        </IconButton>
    </APRow>;

}
