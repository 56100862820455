import { ErrorBoundary } from "react-error-boundary";
import { APCenter, APColumn } from "../layout";
import { APCard, APText } from "../elements";
import APIconButton from "../icons/APIconButton";
import { APIcon } from "../icons/APIcon";
import { APPalette } from "./APPalette";
import { useLocation } from "react-router-dom";
import { useForceUpdate } from "../hooks";
import { useEffect, useState } from "react";

function ErrorBox({ error, resetErrorBoundary }: any) {

    const [path] = useState({ loc: window.location.pathname });

    useLocation();

    useEffect(() => {
        if (path.loc != window.location.pathname) {
            resetErrorBoundary();
            path.loc = window.location.pathname;
        }
    }, [window.location.pathname]);

    return <APCenter><APCard>
        <APColumn gap={"12px"} mainAxisSize="min" crossAxisAlignment="center">
            <APText variant="h3">Something went wrong!</APText>
            <APText variant="subtext" color={APPalette["grey-500"]}>{"Please try again or \nreach out to our support team"}</APText>
            <APIconButton onClick={() => {
                resetErrorBoundary();
            }} ><APIcon icon="refreshDouble"></APIcon></APIconButton>
            <APText variant="subtext" color={APPalette["grey-400"]} maxLines={1} style={{ maxWidth: "320px" }}>Error: {error.message} </APText>

        </APColumn>
    </APCard>
    </APCenter>
}

export function APErrorWrap({ children }: { children: JSX.Element }) {


    return <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => {
        return <ErrorBox error={error} resetErrorBoundary={resetErrorBoundary}></ErrorBox>
    }}>{children}</ErrorBoundary>
}