export default class MasterTable {
    static OwnershipOptions = [
        { label: "Self", value: "SE" },
        { label: "Spouse", value: "SP" },
        { label: "Dependent Children", value: "DC" },
        { label: "Dependent Siblings", value: "DS" },
        { label: "Dependent Parents", value: "DP" },
        { label: "Guardian", value: "GD" }
    ]

    static RelationshipOptions = [
        "Father", "Mother", "Sibling", "Husband", "Wife", "Son", "Daughter", "Grandson", "Granddaughter", "Grandfather", "Grandmother", "Uncle", "Aunt",
    ]
}