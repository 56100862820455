import { APButton, APDialogCard, APImage } from '../elements';
import { APText } from "../elements/APText";
import { APRow } from "../layout/APFlex";
import { Deferred } from '../utils/Deferred';

export function ConfirmationDialog(props: { deferred: Deferred<boolean>; title: string; body: string; yesAction?: string; noAction?: string; }) {
    return (
        <APDialogCard
            style={{ minWidth: "378px", maxWidth: "500px" }}
            title={props.title}
            leading={<APImage src='/icons/icon-warning-dialog.svg' />}
            actions={
                <APRow mainAxisAlignment='end' gap='8px'>
                    <APButton size='small' type='secondary' onClick={() => props.deferred.resolve(false)}>{props.noAction ?? "Cancel"}</APButton>
                    <APButton size='small' onClick={() => props.deferred.resolve(true)}>{props.yesAction ?? "Yes"} </APButton>
                </APRow>
            }
        >
            <APText variant='paragraph-small'> {props.body} </APText>
        </APDialogCard>
    );
}
