import {
    APAsyncButton,
    APButton,
    APColumn,
    APDialogCard,
    APForm,
    APFormFieldText,
    APFormSubmit,
    APRow,
    APSizedBox,
    APText,
    Deferred,
    getIt,
    handleErrorMessage,
    showSnackbar,
    useAPForm,
    useCountDown,
} from "ap-components";
import React, { useEffect, useState } from "react";
import { launchPage } from "../../Common/Utils";
import { AppDownloadLinkWithUserToken } from "./utils/downloadLinkForUsers";
import { UserSignUP } from "./Models/WebStoryModel";
import PartnerApiServices from "../../Services/PartnerApiServices";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";

const passwordPatternRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export default function OtpConfirmationDialog({
    deferred,
    initialForm,
}: {
    deferred: Deferred<string>;
    initialForm: { firstName: string; phoneNumber: string; subAdvisorId: string, title?: string };
}) {
    var control = useAPForm();

    let [form] = useState<UserSignUP>({
        phoneNumber: initialForm.phoneNumber,
        firstName: initialForm.firstName,
        lastName: "",
        password: "",
        otp: "",
        subAdvisorId: initialForm.subAdvisorId,
        title: initialForm.title ?? "",
        campaign: "webstories"

    });

    let [time, { start }] = useCountDown(30000, 1000);

    useEffect(() => {
        start();
    }, []);

    if (!form.phoneNumber) {
        launchPage("/");
        return null;
    }

    const { width } = useWindowDimensions();

    async function generateOtpAndSetTimer() {
        try {
            await getIt(PartnerApiServices).generateOTP({
                firstName: form.firstName,
                phoneNumber: form.phoneNumber,
                title: form.title,
                campaign: "webstories"
            })
            start();
            showSnackbar(`OTP resent to ${form.phoneNumber}`);
        } catch (error) {
            handleErrorMessage(error);
        }
    }

    const isMobileSize = width! < 640;
    return (
        <APDialogCard
            title="OTP Confirmation"
            style={{ minWidth: "none !important", maxWidth: "372px" }}
        >
            <APForm control={control} style={{ width: "100%" }}>
                <APColumn crossAxisAlignment="stretch">

                    <APSizedBox height="8px" />
                    <APText center>
                        Please enter the OTP that was sent to <b>{form.phoneNumber}</b>
                    </APText>
                    <APSizedBox height="16px" />
                    <APFormFieldText
                        label="Enter OTP"
                        keyboardType="text"
                        onChanged={(e) => (form.otp = e)}
                        validator={(v) => {
                            if (v.length !== 4) {
                                return "Please enter 4 digit OTP";
                            }
                            return null;
                        }}
                    />
                    <APSizedBox height="16px" />
                    <APText variant="paragraph-small">
                        Set a new password for your account below
                    </APText>
                    <APSizedBox height="8px" />
                    <APFormFieldText
                        label="Create New Password"
                        obscureText
                        onChanged={(e) => (form.password = e)}
                        validator={(v) => {
                            if (v.length < 6) {
                                return "Password should contain minimum of 6 characters";
                            }
                            return null;
                        }}
                    />
                    <APFormFieldText
                        label="Re-enter New Password"
                        obscureText
                        validator={(v) => {
                            if (form.password != v) {
                                return "Passwords do not match";
                            }
                            return null;
                        }}
                    />
                    <APSizedBox height="8px" />
                    <APText style={{ fontSize: "12px" }}>
                        By signing up, you agree to our &nbsp;
                        <a
                            href="https://www.assetplus.in/#!/info/tnc"
                            target="_blank"
                        >
                            Terms &amp; Conditions
                        </a>
                        &nbsp; and &nbsp;
                        <a
                            href="https://www.assetplus.in/#!/info/privacy"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        .
                    </APText>
                    <APSizedBox height="16px" />
                    <APRow mainAxisAlignment="spaceBetween">
                        <APAsyncButton
                            type="secondary"
                            size={!isMobileSize ? "large" : undefined}
                            nonSubmit
                            onClick={!time ? async (): Promise<void> => generateOtpAndSetTimer() : undefined}
                        >
                            {`Resend OTP ${time ? `${time / 1000} secs` : ""}`}
                        </APAsyncButton>

                        <APFormSubmit
                            style={{ marginTop: "0", width: "100px" }}
                            size={isMobileSize ? "medium" : undefined}
                            onClick={async () => {
                                if (await control.validate()) {
                                    try {
                                        const res = await getIt(PartnerApiServices).verifyOTP(form);
                                        deferred.resolve(
                                            AppDownloadLinkWithUserToken(
                                                form.subAdvisorId!,
                                                res.token
                                            )
                                        );
                                    } catch (error) {
                                        handleErrorMessage(error);
                                    }
                                }
                            }}
                        >
                            Submit
                        </APFormSubmit>
                    </APRow>
                </APColumn>
            </APForm>
        </APDialogCard>
    );
}
