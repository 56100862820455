import { RouteComponentProps } from '@gatsbyjs/reach-router';
import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, useDeviceType } from 'ap-components';
import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import { useRef, useState } from 'react'
import CalculatorFormulas from '../CalculatorFormulas';
import CalculatorInput from '../CalculatorInput';
import { ChartValue, ScreenShot } from './Emi';

export default function Inflation(props: RouteComponentProps) {

    const targetElementRef = useRef<HTMLDivElement>(null);
    const deviceType = useDeviceType()

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Inflation Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };

    const [initialValue, setInitialValue] = useState({
        currentAmount: 200000,
        investmentPeriod: 5,
        inflationRate: 12
    })

    var output = CalculatorFormulas.inflationCalculation(initialValue);
    var futureCost = output.targetAmount

    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>

            <APRow mainAxisAlignment="spaceBetween" >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>Inflation Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >
                        <APImage src="/icons/icon-camera.svg" />
                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>

            <APSizedBox height="16px" />

            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}  >
                <APExpanded flex={2} >
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>
                        <CalculatorInput
                            label="Current cost"
                            dataType="amount"
                            value={initialValue.currentAmount}
                            sliderMax={1000000}
                            sliderMin={10000}
                            sliderStep={10000}
                            inputMin={100}
                            inputMax={1000000000}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, currentAmount: value });
                            }}
                        />
                        <APSizedBox height="24px" />
                        <CalculatorInput
                            label="Duration"
                            dataType="age"
                            value={initialValue.investmentPeriod}
                            sliderMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            inputMin={1}
                            inputMax={50}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, investmentPeriod: value });
                            }}
                        />
                        <APSizedBox height="24px" />
                        <CalculatorInput
                            label="Inflation rate"
                            dataType="percentage"
                            value={initialValue.inflationRate}
                            sliderMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            inputMin={1}
                            inputMax={30}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, inflationRate: value });
                            }}
                        />

                    </div>
                </APExpanded>

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot label="Current cost" value={initialValue.currentAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Duration" value={initialValue.investmentPeriod} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="percentage" label="Interest rate" value={initialValue.inflationRate} />
                    </div>
                </APExpanded>




                <APSizedBox width="32px" />

                <APExpanded flex={3}  >
                    <APColumn mainAxisAlignment='start' style={{ padding: "8px", background: APPalette["grey-50"], borderRadius: "16px" }} >
                        <ChartValue label={'Future Cost'} value={futureCost} />
                    </APColumn>
                </APExpanded>
            </div>
        </div>

    )
}
