import { useEffect, useState } from "react";

export function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            window.addEventListener('resize', function () {
                setWindowDimensions(getWindowDimensions());
            });
            return () => window.removeEventListener('resize', function () {
                setWindowDimensions(getWindowDimensions());
            });
        }
    }, [hasWindow]);

    return windowDimensions;
}