import { APText } from "../elements";
import { APIcon } from "../icons/APIcon";
import { APColumn, APExpanded, APRow, APSizedBox } from "../layout";
import { APChartColors, APPalette } from "../utils";
import { JourneySection, JourneyStep } from "./APJourney";


export function JourneyStepHead(props: { step: JourneyStep | JourneySection, status: string, currentStepId: string, onStepSelect: (stepId: string) => void }) {

    let isSelected = props.currentStepId.startsWith(props.step.title);

    let subStepProgress = 0;

    if (props.step instanceof Object && "steps" in props.step) {
        props.step.steps.forEach((subStep, index) => {
            let stepId = props.step.title + "___" + subStep.title;
            if (props.currentStepId === stepId) {
                subStepProgress = index;
            }
        });
    }

    let isJourneySection = props.step instanceof Object && "steps" in props.step;

    return <APRow key={props.step.title}
        mainAxisSize="max"
        crossAxisAlignment="start"
        onClick={() => {
            if (props.step instanceof Object && "steps" in props.step) {
                props.onStepSelect(props.step.title + "___" + props.step.steps[0].title);
            } else {
                props.onStepSelect(props.step.title);
            }
        }}
        style={{
            padding: "8px", borderRadius: "8px", border: "1px solid",
            borderColor: isSelected ? APPalette["brand-300"] : APPalette["grey-200"],
            backgroundColor: isSelected ? APChartColors["blue-50"] : "transparent"
        }}>
        <APExpanded>
            <APColumn crossAxisAlignment="start">
                <APText variant="h4" color={isSelected ? APPalette["brand-300"] : APPalette["grey-700"]}>{props.step.title}</APText>
                <APText variant="paragraph-xsmall" color={isSelected ? APPalette["brand-300"] : APPalette["grey-500"]}>{props.status}</APText>
                {isJourneySection && <APSizedBox height="8px"></APSizedBox>}
                {props.step instanceof Object && "steps" in props.step && props.step.steps.map((subStep, index) => {

                    let stepId = props.step.title + "___" + subStep.title;
                    let isStepBefore = subStepProgress > index;

                    return <APRow key={props.step.title + "_" + subStep.title}
                        mainAxisSize="max" crossAxisAlignment="center"
                        onClick={isStepBefore ? (ev) => {
                            props.onStepSelect(stepId);
                            ev.stopPropagation();
                        } : undefined}
                        gap={"8px"}
                    >
                        <div style={{ borderRadius: "8px", backgroundColor: APPalette["grey-400"], height: "8px", width: "8px" }}></div>
                        <APText variant="subtext" >{subStep.title}</APText>
                    </APRow>
                }).flatMap((element, index, array) =>
                    index < array.length - 1 ? [element, <div style={{ marginLeft: "3.5px", width: "1px", height: "16px", backgroundColor: APPalette["grey-400"] }}></div>] : [element]
                )}

            </APColumn>
        </APExpanded>
        <APIcon icon="right" size={20} color={isSelected ? APPalette["brand-300"] : APPalette["grey-700"]} />
    </APRow>;
}