import React, { Fragment, useEffect, useState } from "react";
import { APColumn, APExpanded, APIntrinsicHeight, APPadding, APRow, APScrollView, APSizedBox } from "../layout";
import { APCard, APInkWell, APTag, APText } from "../elements";
import { Divider, SvgIcon } from "@mui/material";
import { APIcon } from "../icons/APIcon";
import { APPalette } from "../utils";
import { APForm, APFormSubmit, useAPForm } from "../forms";
import { APJourneyTile } from "./APJourneyTile";


type APJourneyAppProps = {
    title: string;
    description?: string;
    mode: "linear" | "non-linear";
    trailing?: React.ReactNode;
    steps: (JourneySectionApp | JourneyStepApp)[],
    style?: React.CSSProperties;
    onStepChange?: (stepId: string) => void;
    initialStepId?: string;

}

export function APJourneyApp(props: APJourneyAppProps) {


    let stepIdArray = props.steps.map((step, index) => {
        if (isJourneySection(step)) {
            return step.steps.map((subStep, index) => {
                return { id: (step.code ?? step.title) + "___" + (subStep.code ?? subStep.title), step: subStep };
            })
        } else {
            return { id: (step.code ?? step.title), step };
        }
    }).flat();

    let [currentStepId, setCurrentStepId] = useState<string>(props.initialStepId ?? stepIdArray[0].id);
    let currentStepIndex = stepIdArray.findIndex(a => a.id === currentStepId);
    let currentStep = stepIdArray[currentStepIndex]?.step;
    let control = useAPForm();
    let [errorMessage, setErrorMessage] = useState<string | undefined>();


    function isJourneySection(step: JourneySectionApp | JourneyStepApp): step is JourneySectionApp {
        return 'steps' in step;
    }

    function goToNextStep() {
        let currentIndex = stepIdArray.findIndex(a => a.id === currentStepId);
        if (currentIndex < stepIdArray.length - 1) {
            setCurrentStepId(stepIdArray[currentIndex + 1].id);
        }
        //TODO: All steps completed
    }

    function goToPrevStep() {
        let currentIndex = stepIdArray.findIndex(a => a.id === currentStepId);
        if (!!currentIndex && currentIndex <= stepIdArray.length - 1) {
            setCurrentStepId(stepIdArray[currentIndex - 1].id);
        }
    }

    useEffect(() => {
        setErrorMessage(undefined);
        props.onStepChange?.(currentStepId);
    }, [currentStepId]);




    return (
        <APColumn style={{ ...props.style, padding: 0, }}>
            <APColumn style={{ background: APPalette.white, }} mainAxisSize="max" crossAxisAlignment="stretch">
                <APColumn style={{ padding: "8px 16px" }} gap="8px" crossAxisAlignment="stretch">
                    <APRow mainAxisSize="max" crossAxisAlignment="start" mainAxisAlignment="center">
                        {!!currentStepIndex &&
                            <APInkWell onClick={goToPrevStep}>
                                <APIcon icon={"left"} size={24} color={APPalette["grey-700"]} />
                            </APInkWell>

                        }
                        <APExpanded>
                            <APColumn>
                                <APText center variant="h3">{currentStep?.title ?? ""}</APText>
                                <APText center color={APPalette["grey-500"]} variant="paragraph-xsmall">{currentStep?.description ?? ""}</APText>
                            </APColumn>
                        </APExpanded>
                        {props.trailing}
                    </APRow>
                    <APJourneyTile totalSteps={stepIdArray.length} currentStep={currentStepIndex} thickness={2} />
                </APColumn>
                <APExpanded>
                    {currentStep &&
                        <APScrollView>
                            <APForm control={control}>
                                <APColumn mainAxisSize="min" crossAxisAlignment="stretch" style={{ padding: "16px", backgroundColor: APPalette["grey-50"] }}>
                                    <APCard style={{ borderRadius: '8px' }}>
                                        {currentStep.form}
                                    </APCard>
                                </APColumn>
                            </APForm>
                        </APScrollView>
                    }
                </APExpanded>
                {errorMessage && (
                    <APRow style={{ padding: "16px", backgroundColor: APPalette["grey-50"] }} mainAxisAlignment="center">
                        {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
                    </APRow>
                )}
                <APRow style={{ padding: "0px 16px 16px 16px" }}>
                    <APExpanded>
                        <APFormSubmit size="large" onClick={async () => {
                            setErrorMessage(undefined);
                            if (await control.validate()) {
                                try {
                                    await currentStep?.onSubmit();
                                    if (props.mode === "linear") {
                                        goToNextStep();
                                    }
                                } catch (err: any) {
                                    setErrorMessage(err.message);
                                }
                            }
                        }}>{(props.mode == "linear" && currentStepIndex + 1 != stepIdArray.length) ? "Next" : "Submit"}</APFormSubmit>
                    </APExpanded>
                </APRow>
            </APColumn>
        </APColumn>);
}

export type JourneySectionApp = {
    code?: string;
    title: string;
    description: string;
    steps: JourneyStepApp[];
}


export type JourneyStepApp = {
    code?: string;
    title: string;
    description: string;
    form: React.ReactNode;
    onSubmit: () => Promise<any>;
}
