function sipFormula(amount: number, annualinc: number, returns: number, duration: number): number {
    var period = duration * 12;
    var totalsub = (1 + (returns / 1200));
    var sum = 0;
    for (var i = 1; i <= period; i++) {
        var installmentAmount = amount + Math.floor(1.0 * (i - 1) / 12) * annualinc;
        sum += installmentAmount * (Math.pow(totalsub, (period - i + 1)));
    }
    return sum;
}

export function lumpsumFormula(amount: number, returns: number, period: number): number {
    var totalsub = (1 + (returns / 100));
    var sum = (amount * Math.pow(totalsub, period));
    return sum;
}

function getLumpsumAmount(amount: number, returns: number, period: number): number {
    var totalsub = (1 + (returns / 100));
    var sum = (amount / Math.pow(totalsub, period));
    return sum;
}

export function getSipAmountFormula(investmentValue: number, returns: number, duration: number): number {

    var period = duration * 12
    var totalsub = (1 + (returns / 1200));
    var sum = 0;
    var sipAmount = 0;

    for (var i = 1; i <= period; i++) {
        sum += (Math.pow(totalsub, (period - i + 1)));
    }

    sipAmount = Math.ceil(investmentValue / sum);
    if (sum == 0) {
        sipAmount = 0
    }
    return sipAmount;
}
function sip(investmentAmount: number, duration: number, rate: number) {
    let data: { maturityValue: number, totalInvestedAmount: number, gain: number, time: number }[] = [];
    for (let j = 1; j <= duration; j++) {
        var maturityValue = sipFormula(investmentAmount, 0, rate, j);
        let sum = 0;
        for (let i = 1; i <= (j * 12); i++) {
            let installmentAmount = investmentAmount
            sum += installmentAmount;
        }
        var totalInvestedAmount = sum;
        var gain = maturityValue - totalInvestedAmount;
        data.push({ maturityValue: maturityValue, totalInvestedAmount: totalInvestedAmount, gain: gain, time: j });
    }
    return data
}

export function maturityAmount(interest: number, investmentPeriod: number, amount: number, selectedPeriod: string) {
    let rate = selectedPeriod == 'Monthly' ? (interest / 12) / 100 : interest / 100;
    let totalTime = selectedPeriod == 'Monthly' ? investmentPeriod * 12 : investmentPeriod;
    let totalAmount = amount * ((Math.pow((1 + rate), totalTime) - 1) * (1 + rate) / rate)
    return totalAmount
}
export const taxSaved = (amount: number, investmentPeriod: number, selectedPeriod: string) => {
    let taxSlabPercentage = 30;
    let taxSaved80C = 0;
    let taxSaved80CCD = 0;
    let principalAmt = selectedPeriod == 'Monthly' ? amount * 12 : amount;
    let yearlySaving = principalAmt > 150000 ? 150000 : principalAmt;
    taxSaved80C = yearlySaving * (taxSlabPercentage / 100) * investmentPeriod;

    if (principalAmt > 150000) {
        if ((principalAmt - 150000) > 50000) {
            taxSaved80CCD = 50000 * (taxSlabPercentage / 100) * investmentPeriod;
        } else {
            taxSaved80CCD = (principalAmt - 150000) * (taxSlabPercentage / 100) * investmentPeriod;
        }
    }

    return {
        totalTaxSaved: taxSaved80C + taxSaved80CCD,
        taxSaved80C,
        taxSaved80CCD
    }
}
export default class {

    static crorepatiCalculation(inputs: any) {
        let data: { [key: string]: string | number } = {};
        let investmentPeriod = parseFloat(inputs.targetAge) - parseFloat(inputs.currentAge);
        data.targetInflatedAmount = lumpsumFormula(parseFloat(inputs.targetAmount), parseFloat(inputs.inflationRate), investmentPeriod);
        data.growthSavingsAmount = lumpsumFormula(parseFloat(inputs.currentSavingsAmount), parseFloat(inputs.sipReturnsRate), investmentPeriod);
        data.requiredTargetAmount = data.targetInflatedAmount - data.growthSavingsAmount;
        data.requiredSipAmount = getSipAmountFormula(data.requiredTargetAmount, parseFloat(inputs.sipReturnsRate), investmentPeriod);
        data.investedSipAmount = data.requiredSipAmount * investmentPeriod * 12;
        data.totalGrowthAmount = data.requiredTargetAmount - data.investedSipAmount;
        return data;
    }

    static sipInstallments(inputs: any) {
        let data: { [key: string]: string | number } = {};
        let investmentPeriod = parseFloat(inputs.investmentPeriod);
        data.targetInflatedAmount = lumpsumFormula(parseFloat(inputs.targetAmount), parseFloat(inputs.inflationRate), investmentPeriod);
        data.growthSavingsAmount = lumpsumFormula(parseFloat(inputs.currentSavingsAmount), parseFloat(inputs.sipReturnsRate), investmentPeriod);
        data.requiredTargetAmount = data.targetInflatedAmount - data.growthSavingsAmount;
        data.requiredSipAmount = getSipAmountFormula(data.requiredTargetAmount, parseFloat(inputs.sipReturnsRate), investmentPeriod);
        data.investedSipAmount = data.requiredSipAmount * investmentPeriod * 12;
        data.totalGrowthAmount = data.requiredTargetAmount - data.investedSipAmount;
        return data;
    }

    // networthCalculator(inputs) {
    //     let data = {};
    //     Object.assign(data, inputs);
    //     data.totalAssets = data.sharesAmount + data.fixedIncomeAssetsAmount + data.bankAmount + data.propertyAmount + data.commoditiesAmount;
    //     data.totalLiabilities = data.homeLoanAmount + data.otherLoanAmount + data.taxOwedAmount + data.outstandingAmount + data.creditCardAmount + data.otherLiabilitiesAmount;
    //     data.networth = data.totalAssets - data.totalLiabilities;
    //     return data;
    // }

    //   Education Calculator
    static educationPlanner(inputs: any) {
        let data: { [key: string]: number } = {};
        let differenceAge = (parseFloat(inputs.targetAge) - parseFloat(inputs.currentAge));
        data.requiredInflatedAmount = lumpsumFormula(parseFloat(inputs.requiredAmount), parseFloat(inputs.inflationRate), differenceAge);
        data.requiredAmount = parseFloat(inputs.requiredAmount);
        data.currentSavingsAmount = lumpsumFormula(parseFloat(inputs.currentSavingsAmount), parseFloat(inputs.expectedReturns), differenceAge);
        data.additionalSavings = data.requiredInflatedAmount - data.currentSavingsAmount;
        data.monthlySip = getSipAmountFormula(data.additionalSavings, parseFloat(inputs.expectedReturns), differenceAge)
        if (inputs.requiredAmount < 100 || inputs.currentAge < 0 || inputs.targetAge < 16 || inputs.expectedReturns < 1) {
            data.requiredInflatedAmount = 0
            data.requiredAmount = 0;
            data.currentSavingsAmount = 0;
            data.additionalSavings = 0;
            data.monthlySip = 0;
        }
        return data;
    }

    // Retirement Calculator
    static retirementCalculator(inputs: any) {
        let data: { [key: string]: number } = {};
        let ageDiff = (parseFloat(inputs.targetAge) - parseFloat(inputs.currentAge));
        let monthlyExpenses = parseFloat(inputs.targetAmount) * (Math.pow(1 + (parseFloat(inputs.inflationRate) / 100), ageDiff));
        let retirementAmountCompounded = lumpsumFormula(parseFloat(inputs.currentSavingsAmount), parseFloat(inputs.expectedReturns), ageDiff);
        data.retirementAmount = monthlyExpenses * 12 * ((parseFloat(inputs.lifeExpectancy) - parseFloat(inputs.targetAge)));
        data.sipAmount = getSipAmountFormula((data.retirementAmount - retirementAmountCompounded), parseFloat(inputs.expectedReturns), ageDiff);
        data.additionalSavings = data.retirementAmount - retirementAmountCompounded;
        data.retirementAmountCompounded = retirementAmountCompounded;

        if (inputs.targetAmount < 100 || inputs.currentAge < 18 || inputs.targetAge < 40 || inputs.lifeExpectancy < 80 || inputs.expectedReturns < 1) {
            data.retirementAmount = 0;
            data.sipAmount = 0;
            data.additionalSavings = 0;
            data.retirementAmountCompounded = 0;
        }
        return data;
    }

    // SIP return calculator
    static sipReturnsCalculation(inputs: any) {
        let data: { maturityValue: number, totalInvestedAmount: number, gain: number, time: number }[] = [];
        var duration = parseFloat(inputs.investmentPeriod);
        var investmentAmount = parseFloat(inputs.investmentAmount);
        var rate = parseFloat(inputs.returnsRate);
        var annualInc = parseFloat(inputs.annualIncrement)

        for (let j = 1; j <= duration; j++) {
            var maturityValue = sipFormula(investmentAmount, annualInc, rate, j);
            let sum = 0;
            for (let i = 1; i <= (j * 12); i++) {
                let installmentAmount = investmentAmount + Math.floor(1.0 * (i - 1) / 12) * annualInc;
                sum += installmentAmount;
            }
            var totalInvestedAmount = sum;
            var gain = maturityValue - totalInvestedAmount;
            data.push({ maturityValue: maturityValue, totalInvestedAmount: totalInvestedAmount, gain: gain, time: j });
        }
        if (inputs.investmentAmount < 100 || inputs.investmentPeriod < 1 || inputs.returnsRate < 1) {
            data.push({ maturityValue: 0, totalInvestedAmount: 0, gain: 0, time: 0, })
        }
        return data;
    }


    static goalCalculatorSip(inputs: any) {
        let inflatedAmount = lumpsumFormula(parseFloat(inputs.requiredAmount), parseFloat(inputs.inflationRate), parseFloat(inputs.investmentPeriod));
        let monthlySavingsAmount = getSipAmountFormula(inflatedAmount, parseFloat(inputs.sipReturnsRate), parseFloat(inputs.investmentPeriod));
        var ans = sip(monthlySavingsAmount, parseFloat(inputs.investmentPeriod), parseFloat(inputs.sipReturnsRate));
        if (inputs.requiredAmount < 1000 || inputs.inflationRate < 1 || inputs.investmentPeriod < 1 || inputs.sipReturnsRate < 1) {
            // todo make output 0
        }
        return ans;
    }

    //   Goal Calculator
    static goalCalculatorLumpsum(inputs: any) {
        let data: { [key: string]: number } = {};
        // if (inputs.investmentType == "Lumpsum") {
        data.inflatedAmount = lumpsumFormula(parseFloat(inputs.requiredAmount), parseFloat(inputs.inflationRate), parseFloat(inputs.investmentPeriod));
        data.lumpsumRequired = (data.inflatedAmount) / ((1 + inputs.sipReturnsRate / 100) ** (inputs.investmentPeriod))
        data.gains = (data.inflatedAmount - data.lumpsumRequired)
        if (inputs.requiredAmount < 100 || inputs.investmentPeriod < 1 || inputs.sipReturnsRate < 1) {
            data.inflatedAmount = 0;
            data.lumpsumRequired = 0;
            data.gains = 0;
        }
        return data;
    }

    ppfCalculator(inputs: any) {
        let data: { [key: string]: string | number } = {};
        let tableBody = [];
        let numberOfYears = 0;
        let openingBalance = 0;
        let closingBalance = 0;
        let totalInvestment = 0;
        let closingBalanceArr: number[] = []

        let withdrawalLimit = function (i: number) {
            if (i > 5)
                return Math.round(closingBalanceArr[i - 3] / 2);
            return "-"
        }

        let loan = function (i: number) {
            if (i > 1)
                return Math.round(closingBalanceArr[i - 2] / 4);
            return "-"
        }

        if (2019 - parseFloat(inputs.dateOfInvestment) >= 15)
            numberOfYears = 14;
        else
            numberOfYears = 2019 - parseFloat(inputs.dateOfInvestment);

        let tableHeaders = [
            "Year",
            "Interest Rate",
            "Opening Balance",
            "Annual Investment",
            "Total Investment",
            "Interest",
            "Closing Balance",
            "Premature Withdrawal",
            "Loan Possible"
        ];

        // formula correct only need to correct interest rate structure

        for (let i = 0; i <= numberOfYears; i++) {
            openingBalance = closingBalance;
            totalInvestment = openingBalance + parseFloat(inputs.investmentAmount);
            let interest = Math.round(totalInvestment * ppfReturnsRate[`${parseFloat(inputs.dateOfInvestment) + i}`] / 100);
            closingBalance = totalInvestment + interest;
            closingBalanceArr.push(closingBalance);

            tableBody.push([
                parseFloat(inputs.investmentAmount) + i,
                ppfReturnsRate[`${parseFloat(inputs.dateOfInvestment) + i}`],
                openingBalance,
                data.investmentAmount,
                totalInvestment,
                interest,
                closingBalance,
                withdrawalLimit(i),
                loan(i)
            ]);
        }
        return data;
    }

    // etfCalculator(inputs) {
    //     let data = {};
    //     Object.assign(data, inputs);

    //     data.tableHeaders = [
    //         "Age",
    //         "Opening EPF Balance",
    //         "Employee contribution",
    //         "Employer contribution",
    //         "Closing EPF Balance",
    //         "Diverted to Pension Fund",
    //     ];

    //     let openingBalance = data.etfBalance;
    //     let openingPensionBalance = data.currentPensionFund

    //     for (let i = data.currentAge; i <= data.targetAge; i++) {
    //         openingBalance = closingBalance;
    //         totalInvestment = openingBalance + data.investmentAmount;
    //         let interest = Math.round(totalInvestment * ppfReturnsRate[data.dateOfInvestment + i] / 100);
    //         closingBalance = totalInvestment + interest;
    //         closingBalanceArr.push(closingBalance);

    //         data.tableBody.push([
    //             data.dateOfInvestment + i,
    //             ppfReturnsRate[data.dateOfInvestment + i],
    //             openingBalance,
    //             data.investmentAmount,
    //             totalInvestment,
    //             interest,
    //             closingBalance,
    //             withdrawalLimit(i),
    //             loan(i)
    //         ]);
    //     }

    //     return data;
    // }


    // Lumpsum Calculator
    static lumpsumCalculation(inputs: any) {
        let data: { maturity: number, gain: number, time: number, amount: number }[] = [];
        var duration = parseFloat(inputs.investmentPeriod);
        var amount = parseFloat(inputs.investedAmount);
        var rate = parseFloat(inputs.inflationRate);
        for (var i = 1; i <= duration; i++) {
            var maturityAmount = lumpsumFormula(amount, rate, i);
            var gains = maturityAmount - amount;
            var time = i;
            data.push({ maturity: maturityAmount, gain: gains, time: time, amount: amount });
        }
        if (inputs.investedAmount < 100 || inputs.investmentPeriod < 1 || inputs.inflationRate < 1) {
            data.push({ maturity: 0, gain: 0, time: 0, amount: 0 })
        }
        return data;
    }


    // Inflation Calculator
    static inflationCalculation(inputs: any) {
        let data: { [key: string]: number } = {};
        data.targetAmount = lumpsumFormula(parseFloat(inputs.currentAmount), parseFloat(inputs.inflationRate), parseFloat(inputs.investmentPeriod));
        data.growthAmount = (data.targetAmount - parseFloat(inputs.currentAmount));
        data.totalInvestedAmount = parseFloat(inputs.currentAmount)
        if (inputs.currentAmount < 100 || inputs.investmentPeriod < 1 || inputs.inflationRate < 1) {
            data.targetAmount = 0;
            data.growthAmount = 0;
            data.totalInvestedAmount = 0;
        }
        return data;
    }

    // Emi Calculator
    static emiCalculation(inputs: any) {
        let data: { [key: string]: number } = {};
        let rate = parseFloat(inputs.interestRate) / 1200;
        let tenure = parseFloat(inputs.loanPeriod) * 12;
        data.monthlyAmount = parseFloat(inputs.loanAmount) * rate * (Math.pow((1 + rate), tenure) / (Math.pow((1 + rate), tenure) - 1));
        data.totalAmount = data.monthlyAmount * tenure;
        data.interestAmount = data.totalAmount - parseFloat(inputs.loanAmount);
        data.loanAmount = parseFloat(inputs.loanAmount);
        if (inputs.loanAmount < 100 || inputs.interestRate < 1 || inputs.loanPeriod < 1) {
            data.monthlyAmount = 0;
            data.totalAmount = 0;
            data.interestAmount = 0;
            data.loanAmount = 0;
        }
        return data;
    }


    // Compound Interest Calculator
    static compoundCalculation(inputs: any) {
        let data: { [key: string]: number } = {};
        let n: number;
        let r: number;
        if (inputs.interval === "Quarterly") {
            n = 4;
            r = parseFloat(inputs.interestRate) / 100 / n;
        } else if (inputs.interval === "Monthly") {
            n = 12;
            r = parseFloat(inputs.interestRate) / 100 / n;
        } else if (inputs.interval === "Half-Yearly") {
            n = 2;
            r = parseFloat(inputs.interestRate) / 100 / n;
        } else {
            n = 1;
            r = parseFloat(inputs.interestRate) / 100;
        }

        data.principalAmount = parseFloat(inputs.principalAmount);
        data.maturityAmount = parseFloat(inputs.principalAmount) * Math.pow(1 + r, n * parseFloat(inputs.investmentPeriod))
        if (inputs.principalAmount < 100 || inputs.investmentPeriod < 1 || inputs.interestRate < 1) {
            data.principalAmount = 0;
            data.maturityAmount = 0;
        }
        return data
    }

    static npsCalculation(inputs: { amount: number, interest: number, tenure: number }) {
        return sipFormula(inputs.amount, 0, inputs.interest, inputs.tenure)
    }

}

var ppfReturnsRate: { [key: string]: number } = {
    "1980": 8,
    "1981": 8.5,
    "1982": 8.5,
    "1983": 9,
    "1984": 9.5,
    "1985": 10,
    "1986": 12,
    "1987": 12,
    "1988": 12,
    "1989": 12,
    "1990": 12,
    "1991": 12,
    "1992": 12,
    "1993": 12,
    "1994": 12,
    "1995": 12,
    "1996": 12,
    "1997": 12,
    "1998": 12,
    "1999": 12,
    "2000": 11,
    "2001": 9.5,
    "2002": 9,
    "2003": 8,
    "2004": 8,
    "2005": 8,
    "2006": 8,
    "2007": 8,
    "2008": 8,
    "2009": 8,
    "2010": 8,
    "2011": 8.6,
    "2012": 8.8,
    "2013": 8.7,
    "2014": 8.7,
    "2015": 8.7,
    "2016": 8.1,
    "2017": 8.7,
    "2018": 8.7,
    "2019": 8.7

    // more complex structure than this
}



