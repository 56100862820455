import React from "react";
import { APTypography } from "../utils/APTypography";
import { APPalette } from "../utils";

const DialogTextVariantsArray = ['dialogTitle', 'dialogDescription'] as const;
type DialogTextVariants = typeof DialogTextVariantsArray[number];
const dialogTextVariants: { [Key in DialogTextVariants]: React.CSSProperties; } = {
    dialogTitle: { letterSpacing: "0.5px", lineHeight: "22px", fontSize: "18px", fontWeight: 700, /* textAlign: "center", */ color: "#333333", textTransform: 'uppercase' },
    dialogDescription: { letterSpacing: "0.5px", lineHeight: "16px", fontSize: "15px", },
};

const CardTextVariantsArray = ['cardTitle', 'cardSubtitle', 'cardTitleLarge'] as const;
type CardTextVariants = typeof CardTextVariantsArray[number];
const cardTextVariants: { [key in CardTextVariants]: React.CSSProperties; } = {
    cardTitleLarge: { fontSize: "15px", lineHeight: "18px", fontWeight: 700, color: "#333", letterSpacing: "0.8px" },
    cardTitle: { /* fontSize: "13px", */ lineHeight: "13px", letterSpacing: "0.89px", fontWeight: 700, color: "#333", },
    cardSubtitle: { /*fontSize: "14px",*/ lineHeight: "14px", letterSpacing: "0.25px", fontWeight: 600, color: "#4f4f4f" },
};

const CommonTextVariantsArray = ['normal', 'maintext', 'subtext'] as const;
type CommonTextVariants = typeof CommonTextVariantsArray[number];
const commonTextVariants: { [key in CommonTextVariants]: React.CSSProperties; } = {
    normal: { /* fontSize: "13px",  */ lineHeight: "18px", },
    maintext: { /* fontSize: "14px", */ fontWeight: 600 },
    subtext: { /* fontSize: "13px", */ color: "#828282" },
};

const HeadingVariantsArray = ["H3"] as const
type HeadingVariants = typeof HeadingVariantsArray[number];
const headingVariants: { [Key in HeadingVariants]: React.CSSProperties } = {
    H3: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.5
    }
}

const TextVariantsArray = ['dialogTitle', 'dialogDescription', 'cardTitle', 'cardSubtitle', 'cardSubText', 'cardTitleLarge', 'normal', 'maintext', 'subtext'];
const TextVariantsMap: { [K in TextVariants]: React.CSSProperties } = {
    ...dialogTextVariants, ...cardTextVariants, ...commonTextVariants, ...headingVariants
};

/**
 * @deprecated
 */
export type TextVariants = DialogTextVariants | CardTextVariants | CommonTextVariants | HeadingVariants;
export type TypographyVariants = keyof typeof APTypography;

export interface APTextProps {
    /**
     * Typography of the design system
     * @default paragraph-large
     */
    variant?: TextVariants | TypographyVariants | 'listTitle' | 'listDescription';
    children: any;
    /**
     * override typography
     */
    style?: React.CSSProperties;
    /**
     * provides palette positive-300 if value>0 else negative-300.
     * Also changes the variant to paragraph-small and at this point I am too afraid to override with the passed variant 😵‍💫
     */
    colored?: number;
    onClick?: () => any;
    /**
     * @deprecated use maxLines instead
     */
    ellipsis?: boolean;
    strike?: boolean;
    /**
     * centers the text if parent width > content width
     */
    center?: boolean;
    /**
     * override text color
     */
    color?: string;
    /**
     * applies ellipses at the specified max line
     */
    maxLines?: number;
}

/**
 * @author `Abhishek Sinha`
 */
export function APText({
    children, variant = 'paragraph-medium', style, colored, onClick, ellipsis, strike, center, color, maxLines
}: APTextProps) {
    var mStyle: React.CSSProperties = {
        // textAlign: "justify",
        // textJustify: "inter-character",
        ...style
    };

    if (ellipsis) {
        mStyle.overflow = 'hidden';
        mStyle.textOverflow = 'ellipsis';
        mStyle.whiteSpace = 'nowrap';
    }

    if (maxLines) {
        mStyle.overflow = 'hidden';
        mStyle.textOverflow = 'ellipsis';
        mStyle.WebkitLineClamp = maxLines
        mStyle.whiteSpace = "normal"
        mStyle.display = "-webkit-box"
        mStyle.WebkitBoxOrient = "vertical"
    }

    if (colored !== undefined) {
        variant = "paragraph-small"
        if (colored > 0) {
            mStyle.color = APPalette["positive-300"];
        }
        if (colored < 0) {
            mStyle.color = APPalette["negative-300"];
        }
    }

    switch (variant) {
        case "normal":
            variant = "paragraph-large"
            break;
        case "subtext":
            variant = "paragraph-small"
            break;
        case "maintext":
            variant = "paragraph-large"
            break;
        case "dialogTitle":
            variant = "h3"
            break;
        case "cardTitleLarge":
            variant = "h2"
            break;
        case "cardTitle":
            variant = "h3"
            break;
        case "cardSubtitle":
            variant = "h4"
            break;
        case 'listTitle':
            variant = "h4"
            break;
        case 'listDescription':
            variant = "paragraph-small"
            break;
    }

    if (onClick) {
        mStyle.cursor = "pointer";
    }

    if (center) {
        mStyle.textAlign = "center";
    }

    if (color) {
        mStyle.color = color;
    }

    let nStyle: React.CSSProperties = {};
    if (!TextVariantsArray.includes(variant)) {
        nStyle = convertVariantToStyle(variant as TypographyVariants)

    }
    else {
        nStyle = TextVariantsMap[variant as TextVariants];
    }

    return (
        <div style={{ ...nStyle, ...mStyle }} onClick={onClick}>
            {
                strike ?
                    <del>{children}</del>
                    :
                    children
            }
        </div>
    );
}

function convertVariantToStyle(variant: TypographyVariants): React.CSSProperties {
    try {
        return APTypography[variant] as React.CSSProperties;
    } catch (error) {
        return {}
    }
}