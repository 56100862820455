import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { default as React } from 'react';
import { showDialog, sleep } from '..';

type Severity = 'positive' | 'negative' | 'info' | 'warning';

export async function showSnackbar(content: string, severity?: Severity) {
    let newSeverity: 'success' | 'info' | 'warning' | 'error';
    switch (severity) {
        case 'positive': newSeverity = 'success';
            break;
        case 'negative': newSeverity = 'error';
            break;
        case 'info': newSeverity = 'info';
            break;
        case 'warning': newSeverity = 'warning';
            break;
        default:
            newSeverity = 'success';
            break;
    }

    var close = showDialog(
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={true}
            autoHideDuration={3000}
        >
            <Alert
                severity={newSeverity}
                onClose={() => close()}
                iconMapping={{
                    error: <InternalSVGImage name="error-snackbar" />,
                    success: <InternalSVGImage name="check-snackbar" />,
                    info: <InternalSVGImage name="info-snackbar" />,
                    warning: <InternalSVGImage name="warning-snackbar" />
                }}
            >
                {content}
            </Alert>
        </Snackbar>
    );
    await sleep(3000);
    close();
}

export function InternalSVGImage({ name, size = 20, style }: { name: string, size?: number, style?: React.CSSProperties }) {
    return (
        <img src={`/icons/icon-${name}.svg`} alt="" height={size} width={size} style={style} />
    )
}