import { Router, RouteComponentProps } from '@gatsbyjs/reach-router';
import RegulatoryValidation from '.';
import SuccessPage from './SuccessPage';


export default function RegulatoryValidationRoutes(props: RouteComponentProps) {
  return (
    <Router style={{ display: "flex", height: "100%" }}>
      <RegulatoryValidation path="/" default />
      <SuccessPage path="/successPage" />
    </Router>
  )
}
