import { Type } from "class-transformer"
export class AdvisorInfo {
    advisorName!: string;
    image_banner!: string;
    image_logo!: string;
    image_splash!: string;
    title!: string;
    subtitle!: string;
    phone!: string;
    arnCode!: string;
    profile_picture!: string
}

export class WebStoryModel {
    _id!: string
    views!: number
    @Type(() => HashTags)
    hashtags?: HashTags[] = []
    @Type(() => Localizations)
    localizations: Localizations[] = []
    @Type(() => Resource)
    title!: string
    description!: string
    locale!: string
    marketing_category!: string
    @Type(() => Category)
    category!: Category
    published_at!: Date;
    popular?: boolean;
    slides!: Resource[]
    share!: number;

}


export class HashTags {
    name!: string

}

export class Localizations {
    id!: string
    locale!: string
}


export class Resource {
    url?: string
}

export class Category {
    category!: string
    sub_category!: string
}


export class GenerateOTPRequest {
    firstName!: string;
    phoneNumber!: string;
    title?: string;
    subAdvisorId?: string;
    campaign!: string;
}

export class GenerateOTPResponse {
    eventAdded!: boolean;
    otpGenerated!: boolean;
}

export class UserSignUP extends GenerateOTPRequest {
    lastName!: string;
    password!: string;
    otp!: string;
};


export class SignUpResponse {
    @Type(() => UserData)
    user!: UserData;
    token!: string;
    @Type(() => UserData)
    members!: UserData[];
}


export class UserData {
    user!: {
        activationStatus: {
            status: string;
            kyc: string;
            rejectedParams: [];
            rejectionCategories: [];
        };
        device: {};
        lastName: string;
        kycStatus: string;
        activationUpdated: string;
        allowPortfolioAccess: boolean;
        freezeNAV: boolean;
        _id: string;
        phoneNumber: number;
        firstName: string;
        subAdvisorId: string;
        city: string;
        state: string;
        createdAt: string;
        updatedAt: string;
        bseClientCode: number;
        lastIssuedAt: number;
        id: string;
    };
    token!: string;
}
