import React from "react";
import { CrossAxisAlignment, getCrossAxisAlignment, getMainAxisAlignment, MainAxisAlignment } from "./APFlex";

export function APIntrinsicHeight({ children, style, mainAxisAlignment, crossAxisAlignment }: { children: any, style?: React.CSSProperties, mainAxisAlignment: MainAxisAlignment, crossAxisAlignment: CrossAxisAlignment }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: getMainAxisAlignment(mainAxisAlignment), alignItems: getCrossAxisAlignment(crossAxisAlignment), ...style }}>
            {children}
        </div>
    )
}

export function APExpanded({ flex = 1, children, style, noClone = false }: {
    children: React.ReactElement
    flex?: number
    style?: React.CSSProperties
    noClone?: boolean
}) {

    return (
        <APClone style={{ ...style, flex: flex, minHeight: 0, minWidth: 0 }} noClone={noClone}>
            {children}
        </APClone>
    );
}

export function APCenter(props: { children: React.ReactNode, style?: React.CSSProperties }) {
    return (
        <div style={{ display: "grid", placeItems: "center", width: "100%", height: "100%", ...props.style }}>
            {props.children}
        </div>
    )
}

export function APPadding({ padding = "16px", margin, children, style, noClone }: {
    padding?: string | number | (string & {})
    margin?: string | number | (string & {})
    children: React.ReactElement,
    style?: React.CSSProperties,
    noClone?: boolean
}) {
    return (
        <APClone style={{ ...style, padding: padding, margin: margin }} noClone={noClone}>
            {children}
        </APClone>
    )
}

export function APStrike({ strike, children }: { strike?: boolean, children: any }) {
    if (strike) {
        return <del style={{ color: "#888" }}>{children}</del>;
    } else {
        return children
    }
}

export function APSizedBox(props: { noClone?: boolean, height?: string, width?: string, children?: React.ReactElement, style?: React.CSSProperties }) {
    return (
        <APClone noClone={props.noClone} style={{ ...props.style, height: props.height, width: props.width, minHeight: props.height, minWidth: props.width, maxHeight: props.height, maxWidth: props.width }}>
            {props.children}
        </APClone>
    );
}

export function APClone(props: {
    style?: React.CSSProperties,
    children?: React.ReactElement,
    noClone?: boolean
}) {
    if (props.children == null) {
        return <div style={props.style}></div>
    }

    if (props.noClone) {
        return <div style={{ ...props.style, ...props.children?.props.style }}>{props.children}</div>
    }

    var newStyle = { ...props.style, ...props.children?.props.style, };
    return React.cloneElement(props.children, { style: newStyle });
}

