import { useLocation } from "@gatsbyjs/reach-router";
import { APColumn, APSizedBox, APText, APRow, APButton, APCard, APFormFieldImage, showErrorDialog, APStack, APAlign, APIcon, CloseIcon, getIt, RightIcon, APPalette, APExpanded, JourneySectionApp, JourneyStepApp, JourneyStep, JourneySection } from "ap-components";
import { APTypography } from "ap-components/src/utils/APTypography";
import { useState, useRef, useEffect } from "react";
import Activation2Model from "../../Models/Activation2Model";
import ClientApiServices from "../../Services/ClientApiServices";
import MutualFundApiServices from "../../Services/MutualFundServices";

export default function PhotoStepperFunction({ activationData, updateActivationData }: { activationData: Activation2Model, updateActivationData: (activationData: Activation2Model) => void }): JourneyStepApp | JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [photoImage, setPhotoImage] = useState<string>();
    const [secondHolderPhotoImage, setSecondHolderPhotoImage] = useState<string>();

    async function getPhotoImage(holder: 'primary' | 'secondary' | 'guardian') {
        const signatureBase64 = await getIt(MutualFundApiServices).getFile({ token: token!, holder, file: "photo" });
        if (holder != 'secondary') {
            setPhotoImage(signatureBase64);
        } else {
            setSecondHolderPhotoImage(signatureBase64);
        }
    };

    useEffect(() => {
        if (activationData.imagePhoto) {
            getPhotoImage('primary');
        };
        if (activationData.secondHolderImagePhoto) {
            getPhotoImage('secondary');
        }
    }, []);

    if (activationData.holdingType == 'SI') {
        return {
            title: "Photo",
            code: "P",
            description: "",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn crossAxisAlignment='stretch' gap='8px'>
                        <APCard>
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please upload photo</APText>
                                <APText style={APTypography["paragraph-small"]}>• Please ensure the photo is clear, colored and taken on a light preferably white background.</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photo must be without cap or goggles.</APText>
                            </APColumn>
                        </APCard>
                        {activationData.secondHolderName && (
                            <APText style={APTypography.h4}>Photo for Holder 1</APText>
                        )}
                        <APFormFieldImage
                            label="Image"
                            key={photoImage}
                            src={photoImage}
                            onChange={(src) => {
                                console.log(":59");
                                setPhotoImage(src)
                            }}
                            validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                        />
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: async () => {
                if (!photoImage) {
                    throw new Error("Photo required");
                }
                const dataToSubmit: PhotoRequestForHolderClass = {
                    token: token!,
                    photoBase64: photoImage,
                    holder: 'primary'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).savePhotoImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        };
    }

    const steps = [];

    if (!activationData.firstHolderHasCkyc) {
        steps.push({
            title: "Photo for Holder 2",
            description: activationData.secondHolderName,
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn crossAxisAlignment='stretch' gap='8px'>
                        <APCard>
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please upload photo</APText>
                                <APText style={APTypography["paragraph-small"]}>• Please ensure the photo is clear, colored and taken on a light preferably white background.</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photo must be without cap or goggles.</APText>
                            </APColumn>
                        </APCard>
                        {activationData.secondHolderName && (
                            <APText style={APTypography.h4}>Photo for Holder 1</APText>
                        )}
                        <APFormFieldImage
                            label="Image"
                            key={photoImage}
                            src={activationData?.imagePhoto}
                            onChange={(src) => {
                                setPhotoImage(src)
                            }}
                            validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                        />
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: async () => {
                if (!photoImage) {
                    throw new Error("Photo required");
                }
                const dataToSubmit: PhotoRequestForHolderClass = {
                    token: token!,
                    photoBase64: photoImage,
                    holder: 'secondary'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).savePhotoImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        })
    };

    if (!activationData.secondHolderHasCkyc) {
        steps.push({
            title: "Photo for Holder 1",
            description: "",
            code: "FH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn crossAxisAlignment='stretch' gap='8px'>
                        <APCard>
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please upload photo</APText>
                                <APText style={APTypography["paragraph-small"]}>• Please ensure the photo is clear, colored and taken on a light preferably white background.</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photo must be without cap or goggles.</APText>
                            </APColumn>
                        </APCard>
                        <APFormFieldImage
                            label="Image"
                            key={secondHolderPhotoImage}
                            src={activationData?.imagePhoto}
                            onChange={(src) => {
                                setPhotoImage(src)
                            }}
                            validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                        />
                    </APColumn>
                </APColumn>
            ),
            onSubmit: async () => {
                if (!photoImage) {
                    throw new Error("Photo required");
                }
                const dataToSubmit: PhotoRequestForHolderClass = {
                    token: token!,
                    photoBase64: photoImage,
                    holder: 'primary'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).savePhotoImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        })
    }

    return {
        title: "Photo",
        code: "P",
        description: activationData.name,
        steps,
    };
};


export class PhotoRequestForHolderClass {
    token!: string;
    photoBase64!: string;
    holder!: 'primary' | 'secondary' | 'guardian'
}