import React, { useContext } from "react";
import { DialogContext } from "../dialogs/DialogHelper";
import { APColumn, APRow } from "../layout/APFlex";
import { APScrollView } from "../layout/APScrollView";
import { APExpanded } from "../layout/Common";
import { APCard } from "./APCard";
import { APText } from "./APText";
import { APPalette } from "../utils";
import { CloseIcon } from "../icons";
import APIconButton from "../icons/APIconButton";

export interface APDialogCardProps {
    style?: React.CSSProperties
    /**
     * title of the dialog
     */
    title: string
    children: React.ReactNode
    /**
     * adds any node before the dialog title
     * @private used in confirmation and error dialog
     */
    leading?: React.ReactNode
    /**
     * call to actions which will appear at the bottom of the dialog
     */
    actions?: React.ReactNode
    /**
     * adds any node after the dialog title
     */
    trailing?: JSX.Element;
    /**
     * @deprecated
     */
    contentDivider?: boolean;
    /**
     * @deprecated
     */
    contentDividerOffset?: string;
}

export function APDialogCard({
    style, title, children, leading, actions, trailing,
    contentDivider = false, contentDividerOffset
}: APDialogCardProps) {

    const dialogContext = useContext(DialogContext);

    return (

        <APCard style={{ padding: "16px", minWidth: "420px", maxWidth: "90vw", ...style, boxSizing: "border-box", borderRadius: "8px" }}>
            <APColumn gap='8px' mainAxisSize="max" crossAxisAlignment="stretch">
                {/* Card Header */}
                <APRow gap='8px' mainAxisAlignment='spaceBetween'>
                    {leading}
                    <APExpanded>
                        <APText variant='h3' color={APPalette['grey-700']} ellipsis> {title}</APText>
                    </APExpanded>
                    {trailing}
                    {
                        (!dialogContext.disableClose) &&
                        <APIconButton onClick={() => dialogContext.deferred?.reject(new Error("USER_CANCELLED"))}>
                            <CloseIcon />
                        </APIconButton>
                    }
                </APRow>
                {/* Card Body */}
                <APExpanded>
                    <APScrollView>
                        {children}
                    </APScrollView>
                </APExpanded>
                {/* Card Actions */}
                {actions}
            </APColumn>
        </APCard>
    )
}
