import Axios, { AxiosInstance } from "axios";
import { plainToClass } from "class-transformer";
import { singleton } from "tsyringe";
import { AdvisorInfo, GenerateOTPRequest, GenerateOTPResponse, SignUpResponse, UserSignUP } from "../App/WebStory/Models/WebStoryModel";

export const SERVER_URL = import.meta.env.REACT_APP_SERVER_URL ?? "";

@singleton()
export default class PartnerApiServices {
    instance: AxiosInstance = Axios.create({
        baseURL: SERVER_URL
    });

    constructor() {
        this.instance.interceptors.request.use((config) => {
            return config;
        }, (error => {
            return Promise.reject(error);
        }));

        this.instance.interceptors.response.use((v) => v, (error) => {
            if (error?.response?.data?.userMsg)
                error.message = error.response.data.userMsg;
            return Promise.reject(error);
        })
    }

    async getAdvisorInfo(advisorId: string): Promise<AdvisorInfo> {
        const responseFromGetAdvisorInfo = await this.instance.request({
            method: "POST",
            url: "/auth/getAdvisorInfo",
            data: {
                advisorId,
            },
        });
        return plainToClass(AdvisorInfo, responseFromGetAdvisorInfo.data);
    }


    async generateOTP(data: GenerateOTPRequest): Promise<GenerateOTPResponse> {
        const response = await this.instance.request({
            method: "POST",
            url: "/auth/generateSignupOTP",
            data
        });
        return plainToClass(GenerateOTPResponse, response.data);
    }

    async verifyOTP(data: UserSignUP): Promise<SignUpResponse> {
        const response = await this.instance.request({
            method: "POST",
            url: "/auth/signup",
            data
        });

        return plainToClass(SignUpResponse, response.data);
    }

}
