import { CircularProgress } from "@mui/material";
import { APColumn, APDialogCard, APSizedBox, APText, Deferred } from "ap-components";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";

export default function ProcessingDialog({ deferred }: { deferred: Deferred<void> }) {
    setTimeout(() => {
        return deferred.resolve();
    }, 2000);

    const { width: windowWidth } = useWindowDimensions();

    return (
        <APDialogCard title={""} style={{ minWidth: (windowWidth && windowWidth <= 600) ? "336px" : undefined }}>
            <APColumn>
                <CircularProgress />
                <APSizedBox height="12px" />
                <APText style={{ color: "#133d6e", fontSize: "18px", lineHeight: "1.6" }} > Generating your badge </APText>
            </APColumn>
        </APDialogCard>
    )
}