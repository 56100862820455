import { APColumn, APExpanded, APPalette, APText, APRow, APSizedBox, APButton, APImage, useDeviceType } from "ap-components";
import React, { useRef, useState } from "react";
import CalculatorInput from "../CalculatorInput";
import CalculatorFormulas from "../CalculatorFormulas";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import { ScreenShot } from "./Emi";
import { APCharts } from "../../../Common/Charting";


export default function Retirement(props: RouteComponentProps<{ calculator?: string }>) {

    const targetElementRef = useRef<HTMLDivElement>(null);

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);

    const deviceType = useDeviceType()

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Retirement Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };

    const [initialValue, setInitialValue] = useState({
        targetAmount: 50000,
        currentAge: 25,
        targetAge: 60,
        lifeExpectancy: 100,
        currentSavingsAmount: 500000,
        inflationRate: 6,
        expectedReturns: 12
    });

    var out = CalculatorFormulas.retirementCalculator(initialValue);


    var currentSavingValue = out.retirementAmountCompounded;
    var additionalSavings = out.additionalSavings;

    if (out.additionalSavings < 0) {
        additionalSavings = 0
    } else {
        additionalSavings = out.additionalSavings
    };

    var retirementAmount = (out.retirementAmount);
    var monthlySip = (out.sipAmount);
    var totalValue = (currentSavingValue + additionalSavings).currency(2, true)



    var option = {
        legend: {
            orient: 'horizontal',
            position: "center",
            data: [
                {
                    name: 'Current Savings Value on Retirement',


                }, {
                    name: 'Additional Savings Required',
                }
            ],

        },
        tooltip: {
            trigger: 'item',
        },
        title: {
            text: `${totalValue}`,
            left: 'center',
            top: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '60%'],
                avoidLabelOverlap: false,
                startAngle: 180,
                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency();
                    }
                },
                label: {
                    show: true,
                    formatter(param: any) {
                        return (param.value.currency(2, true))
                    }
                },

                data: [
                    {
                        value: additionalSavings,
                        name: 'Additional Savings Required',
                        itemStyle: {
                            color: '#268EFF'
                        }
                    },
                    {
                        value: currentSavingValue,
                        name: 'Current Savings Value on Retirement',
                        itemStyle: {
                            color: '#5DD27A'
                        }
                    },
                ]
            }
        ]
    };
    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween">
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>Retirement Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >

                        <APImage src="/icons/icon-camera.svg" />

                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />
            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }} >
                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>

                        <CalculatorInput
                            label="Current Monthly expenses"
                            dataType="amount"
                            value={initialValue.targetAmount}
                            sliderMax={200000}
                            sliderMin={10000}
                            sliderStep={10000}
                            inputMin={100}
                            inputMax={10000000}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, targetAmount: value })
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Current Age"
                            dataType="age"
                            value={initialValue.currentAge}
                            sliderMax={60}
                            sliderMin={18}
                            sliderStep={1}
                            inputMax={60}
                            inputMin={18}
                            onChange={(value) => {
                                let currentAge = value

                                let retirementAge = currentAge + 1;

                                retirementAge = retirementAge > 60 ? 60 : retirementAge

                                setInitialValue({ ...initialValue, currentAge: currentAge, targetAge: currentAge >= initialValue.targetAge ? retirementAge : initialValue.targetAge })

                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Expected Retirement Age"
                            dataType="age"
                            value={initialValue.targetAge}
                            sliderMax={80}
                            sliderMin={40}
                            sliderStep={1}
                            inputMax={80}
                            inputMin={40}
                            onChange={(value) => {
                                const retirementAge = value;
                                setInitialValue({
                                    ...initialValue,
                                    targetAge: retirementAge,
                                    currentAge: retirementAge > 0 ? retirementAge <= initialValue.currentAge ? retirementAge - 1 : initialValue.currentAge : 0,
                                    lifeExpectancy: initialValue.lifeExpectancy == retirementAge ? initialValue.lifeExpectancy + 1 : initialValue.lifeExpectancy
                                })
                            }}
                        />

                        <APSizedBox height="24px" />
                        <CalculatorInput
                            label="Life Expectancy"
                            dataType="age"
                            sliderStep={1}
                            value={initialValue.lifeExpectancy}
                            inputMax={120}
                            sliderMax={100}
                            inputMin={80}
                            sliderMin={80}
                            onChange={(value) => {
                                let lifeExpectancy = value
                                setInitialValue({ ...initialValue, lifeExpectancy: lifeExpectancy, targetAge: initialValue.targetAge === lifeExpectancy ? initialValue.targetAge - 1 : initialValue.targetAge })
                            }}
                        />

                        <APSizedBox height="24px" />
                        <CalculatorInput
                            label="Current Savings"
                            dataType="amount"
                            sliderStep={50000}
                            value={initialValue.currentSavingsAmount}
                            sliderMax={3000000}
                            inputMax={1000000000}
                            inputMin={0}
                            sliderMin={0}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, currentSavingsAmount: value })
                            }}
                        />

                        <APSizedBox height="24px" />
                        <CalculatorInput
                            label="Inflation Rate"
                            dataType="percentage"
                            value={initialValue.inflationRate}
                            sliderStep={1}
                            inputMax={20}
                            sliderMin={0}
                            inputMin={0}
                            sliderMax={20}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, inflationRate: value })
                            }}
                        />
                        <APSizedBox height="24px" />
                        <CalculatorInput
                            label="Expected Return  Rate"
                            dataType="percentage"
                            value={initialValue.expectedReturns}
                            inputMax={30}
                            inputMin={1}
                            sliderStep={1}
                            sliderMin={1}
                            sliderMax={30}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, expectedReturns: value })
                            }}
                        />

                    </div>
                </APExpanded>
                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot label="Current Monthly expenses" value={initialValue.targetAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Current Age" value={initialValue.currentAge} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Expected Retirement Age" value={initialValue.targetAge} />
                        <APSizedBox height='24px' />
                        <ScreenShot dataType="age" label="Life Expectancy" value={initialValue.lifeExpectancy} />
                        <APSizedBox height='24px' />
                        <ScreenShot label="Current Savings" value={initialValue.currentSavingsAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType='percentage' label="Inflation Rate" value={initialValue.inflationRate} />
                        <APSizedBox height='24px' />
                        <ScreenShot dataType='percentage' label="Expected Return Rate" value={initialValue.expectedReturns} />
                    </div>
                </APExpanded>

                <APSizedBox width="32px" />

                <APExpanded flex={3}>

                    <APColumn crossAxisAlignment="stretch" style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }} >

                        <APCharts height="320px" option={option} />

                        <APColumn mainAxisAlignment="start"  >

                            < LabelAndValue
                                label={"Retirement amount required as per current expenses"}
                                value={retirementAmount}
                            />
                            <APSizedBox height="16px" />
                            {
                                retirementAmount < currentSavingValue ?
                                    <APText variant="paragraph-small" style={{ color: APPalette["grey-600"] }}>
                                        Congratulations, your current savings cover all your retirement goals. You can keep investing for an higher quality of retirement life.
                                    </APText> :
                                    <>
                                        < LabelAndValue
                                            label={"Additional Saving Required"}
                                            value={additionalSavings}
                                        />
                                        <APSizedBox height="16px" />

                                        < LabelAndValue
                                            label={"Achievable by a monthly SIP of"}
                                            value={monthlySip}
                                            style={{ color: APPalette["info-300"] }}
                                        />
                                    </>
                            }
                        </APColumn>
                    </APColumn>
                </APExpanded>
            </div>
        </div>
    )
}

export function LabelAndValue({ label, value, style }: { label: string, value: number, style?: React.CSSProperties }) {
    return (
        <APColumn  >
            <APText variant="paragraph-small">
                {label}
            </APText>
            <APSizedBox height="6px" />
            <APText variant="h2" style={{ color: APPalette["grey-700"], ...style }}>
                {value.currency(2, true)}
            </APText>
        </APColumn>
    )
}