import { APCard, APColumn, APPalette, APRow, APSizedBox, APTag, APText } from 'ap-components';
import { Premium, RiderDetails, RiderPremium } from '../models/Quote';
import { Skeleton } from "@mui/material";
import { AddOn, Product } from '../models';

export function PriceBreakdownCard({ premium, paymentFrequency, paymentMode, style, cta, taxSaving, product, riders }: {
    premium?: Premium,
    paymentFrequency?: string,
    paymentMode?: string,
    taxSaving?: string,
    cta?: JSX.Element,
    product?: Product
    riders: RiderDetails[]
    style?: React.CSSProperties,
}) {

    // var riderBreakdown: { label: string, value: number }[] = []

    // if (premium && product) {
    //     premium.ridersPremium.forEach(rider => {

    //         const match = product.addOns.find(e => e.riderCode === rider.riderPlanCode)
    //         if (match) {
    //             riderBreakdown.push({
    //                 label: `${match.shortTitle}`,
    //                 value: rider.premium
    //             })
    //         } else {
    //             riderBreakdown.push({
    //                 label: rider.riderPlanCode.toUpperCase(),
    //                 value: rider.premium
    //             })
    //         }
    //     })
    // }

    const riderPriceBreakdown = getPriceBreakdownForRiders(product?.addOns ?? [], premium?.ridersPremium ?? [], riders);

    return (
        <APCard padding="12px" style={style}>
            <APColumn crossAxisAlignment="stretch" gap="16px">
                <APColumn crossAxisAlignment="start" gap="8px">
                    <APText variant="paragraph-xsmall" color={APPalette["grey-500"]}>Price breakdown</APText>
                    <APColumn crossAxisAlignment="start">
                        <APColumn crossAxisAlignment="start" gap="8px">
                            <APColumn crossAxisAlignment='start'>
                                <PriceDetails color={APPalette['grey-600']} label="Base Premium" value={`${premium?.basePremium?.currency() ?? "-"}`} />
                                <APText color={APPalette['grey-500']} variant='paragraph-xsmall' >(inclusive of taxes)</APText>
                            </APColumn>
                            {
                                riderPriceBreakdown.length > 0 &&
                                riderPriceBreakdown.map(item => (
                                    !!item.premium ? <APRow key={item.title}>
                                        <PriceDetails
                                            color={APPalette['grey-600']}
                                            label={item.title + `${item.sumAssured ? ` (${(+item.sumAssured)?.currency(1, true)})` : ""}`}
                                            value={`${item.premium?.currency()}`}
                                        />
                                    </APRow> : <></>
                                ))
                            }
                            {
                                !!(premium?.discount) && <APRow mainAxisAlignment='spaceBetween' >
                                    <APText variant='paragraph-small' >Discount</APText>
                                    <APText variant='h4' color={APPalette['brand-300']} >- {premium.discount.currency()}</APText>
                                </APRow>
                            }

                            <APRow mainAxisAlignment="spaceBetween" gap="4px">
                                <APColumn crossAxisAlignment='start'>
                                    <APText variant='paragraph-small' >Total Premium</APText>
                                    <APText color={APPalette['grey-500']} variant='paragraph-xsmall' >(inclusive of taxes)</APText>
                                </APColumn>
                                {
                                    premium?.totalPremiumWithTax ?
                                        <APColumn crossAxisAlignment="end">
                                            <APText variant='h3' >{premium.totalPremiumWithTax.currency()}</APText>
                                            {
                                                paymentMode &&
                                                <APText color={APPalette['grey-500']} variant='paragraph-xsmall' >
                                                    {` (${paymentMode === "single" ? "One time" : `${paymentFrequencyLabel(paymentFrequency)}`}ly)`}
                                                </APText>
                                            }
                                        </APColumn>
                                        :
                                        <Skeleton width={100} height={35} />
                                }
                            </APRow>
                        </APColumn>
                    </APColumn>
                </APColumn>
                {
                    taxSaving &&
                    <APTag variant="positive" size="small">{taxSaving}</APTag>
                }
                {
                    cta && cta
                }
            </APColumn>
        </APCard>
    )
}


function getPriceBreakdownForRiders(addOns: AddOn[], riderPremiums: RiderPremium[], riderDetails: RiderDetails[]) {
    return riderPremiums.map(premiumRider => {
        const title = addOns.find(item => item.riderCode === premiumRider.riderPlanCode)?.shortTitle ?? premiumRider.riderPlanCode.toUpperCase();
        const sumAssured = riderDetails.find(item => item.riderPlanCode === premiumRider.riderPlanCode)?.sumAssured;
        return { title, sumAssured, premium: premiumRider.premium };
    })
}

function PriceDetails({ label, value, color }: { label: string, value: string, color?: string }) {
    return (
        <APRow mainAxisAlignment="spaceBetween" crossAxisAlignment="start" gap="4px">
            <APText variant="paragraph-small">{label}</APText>
            <APText variant="h4" color={color}>{value}</APText>
        </APRow>
    )
}

export function paymentFrequencyLabel(paymentFrequency?: string) {
    switch (paymentFrequency) {
        case "monthly":
            return "month"
        case "quarterly":
            return "quarter"
        case "halfYearly":
            return "half-year"
        default:
            return 'year';
    }
}