import { Divider } from '@mui/material';
import { APColumn, APDialogCard, APForm, APFormFieldDropdown, APFormFieldText, APFormSubmit, Deferred, handleErrorMessage, useAPForm } from 'ap-components'
import { useState } from 'react'
import MasterTable from './Models.tsx/MasterTable';
import { NomineeModel, NomineeValidationModel } from './Models.tsx/SelfDeclarationModel';

export default function NomineeDialog({ deferred, details }: { deferred: Deferred<NomineeValidationModel>, details: NomineeModel }) {
    const [form] = useState<NomineeValidationModel>({
        name: details.name ?? "",
        relationship: details.relationship ?? ""
    });

    var control = useAPForm();

    return (
        <APDialogCard title='Nominee Details' style={{ minWidth: "300px", maxWidth: "478px" }}>
            <APForm control={control}>
                <APColumn crossAxisAlignment="stretch">
                    <APFormFieldText
                        label='Nominee Name'
                        initialValue={form.name}
                        onChanged={(value) => form.name = value}
                        validator={(value) => {
                            if (value.length === 0) {
                                return "Nominee name required";
                            }

                            return null;
                        }}
                    />
                    <APFormFieldDropdown
                        label="Relationship"
                        initialValue={form.relationship}
                        items={MasterTable.RelationshipOptions.map(e => ({ label: e, value: e }))}
                        onChanged={(value) => {
                            if (value) {
                                form.relationship = value;
                            }
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Select nominee relationship";
                            }

                            return null;
                        }}
                    />
                    <Divider />
                    <APFormSubmit
                        onClick={async () => {
                            if (await control.validate()) {
                                try {
                                    deferred.resolve(form)
                                } catch (error) {
                                    handleErrorMessage(error)
                                }
                            }
                        }}>
                        Next
                    </APFormSubmit>
                </APColumn>
            </APForm>
        </APDialogCard>
    )
}
