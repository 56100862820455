import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router'
import { APAsyncButton, APButton, APColumn, APExpanded, APForm, APFormSubmit, APImage, APOtp, APPadding, APPalette, APSizedBox, APText, getIt, showErrorDialog, showSnackbar, useAPForm, useCountDown } from 'ap-components';
import { useEffect, useState } from 'react'
import MutualFundApiServices from '../../Services/MutualFundServices';

const addScript = ({ src, id, onLoad }: { src: string, id: string, onLoad: Function }) => {
	const script = document.createElement("script");
	script.src = src;
	script.id = id;
	script.async = true;
	script.onload = () => {
		if (onLoad) {
			onLoad();
		}
	};
	document.head.appendChild(script);
	return script;
};

const getSDKObject = (ssoToken: string) => {
	// @ts-ignore
	const sdkObj = new Volt({
		environment: import.meta.env.REACT_APP_ENVIRONMENT === "prod" ? "production" : "staging",
		customerSsoToken: ssoToken,
		voltPlatformCode: "ASSET_PLUS",
		launchMode: "div",
		divId: "voltMoneyMountingDiv",
	});

	// @ts-ignore
	window.volt = sdkObj

	return sdkObj;
}

export default function LAMFVoltMoney(props: RouteComponentProps) {
	const location = useLocation();
	const token = new URLSearchParams(location.search).get("token");
	var control = useAPForm();

	const [otpSent, setOtpSent] = useState(false);
	const [otpValue, setOtpValue] = useState<string | null>(null);
	const [userMaskedData, setUserMaskedData] = useState<{ maskedEmail: string, maskedPhoneNumber: string } | null>(null);
	const [viewOtpDiv, setViewOtpDiv] = useState(true);
	const [errorInOTPGeneration, setErrorInOTPGeneration] = useState(false);

	const [scriptLoaded, setScriptLoaded] = useState(false);
	const onScriptLoad = async () => { setScriptLoaded(true) }

	var [timeLeft, { start }] = useCountDown(60000, 1000);

	async function generateOtpHelper() {
		try {
			if (token) {
				const response = await getIt(MutualFundApiServices).generateOTP(token);
				setUserMaskedData({ maskedEmail: response.maskedEmail, maskedPhoneNumber: response.maskedPhoneNumber });
				showSnackbar("OTP sent");
				setOtpSent(true);
				start();
			}
		} catch (error: any) {
			setErrorInOTPGeneration(true);
			await showErrorDialog(error.message)
		}
	}

	useEffect(() => {
		generateOtpHelper();
		addScript({ src: "voltsdk.js", id: "voltMoneySDK", onLoad: onScriptLoad });
	}, [token])



	async function handleSubmit(enteredOtp: string) {
		try {
			if (scriptLoaded && token) {
				const res = await getIt(MutualFundApiServices).getLamfSdkCredentials({ mwebToken: token, otp: enteredOtp });
				if (res.message === "OTP_VERIFIED") {
					setViewOtpDiv(false);
					const sdkObj = getSDKObject(res.ssoToken);
					sdkObj.submit(res.authToken, res.voltCustomerCode, res.ssoToken);
				}
			}
		} catch (error: any) {
			await showErrorDialog(error.message)
		}
	}

	return (
		<APExpanded>
			<APColumn
				mainAxisAlignment="center"
				crossAxisAlignment="center"
				mainAxisSize="max"
				gap="8px"
			>
				<div id="voltMoneyMountingDiv" />

				{
					viewOtpDiv &&
					<div id='otpDiv'>
						<APPadding>
							<APColumn>

								<APForm control={control}>
									<APColumn mainAxisAlignment="center">
										<APImage width="57px" height="50px" src="../img/messageIcon.svg" />
										<APSizedBox height="16px" />
										<APText color={APPalette["grey-700"]} variant="h2">
											OTP verification
										</APText>
										<APSizedBox height="6px" />
										<APText variant="paragraph-xsmall" center>
											We'll need to verify your identity before redirecting you to our partner VoltMoney
										</APText>
										<APSizedBox height="26px" />
										<APColumn style={{ width: "343px" }}>
											{
												userMaskedData &&
												<APText center>
													Please enter the OTP sent to your current registered mobile
													number <b>{userMaskedData.maskedPhoneNumber}</b> and email <b>{userMaskedData.maskedEmail}</b>
												</APText>
											}
											<APSizedBox height="8px" />
											<APOtp digits={6}
												onChanged={(value) => setOtpValue(value)}
											/>
											<APSizedBox height='4px' />

											{
												otpValue?.length === 6 ? <APButton
													onClick={async () => {
														await handleSubmit(otpValue)
													}}>
													Submit
												</APButton> : <APButton>
													Submit
												</APButton>
											}
										</APColumn>
									</APColumn>
								</APForm>
								<APAsyncButton
									onClick={
										(timeLeft > 0 && otpSent)
											? undefined
											: async () => await generateOtpHelper()}
									type='negative'
									style={{ color: "#5745BA" }}
								>
									Resend OTP {timeLeft / 1000 === 0 || errorInOTPGeneration ? `?` : `${timeLeft / 1000} sec`}
								</APAsyncButton>
							</APColumn>
						</APPadding>
					</div>
				}
			</APColumn>
		</APExpanded>
	);
}
