import React, { useEffect, useState } from "react";
import { APColumn, APExpanded, APIntrinsicHeight, APRow, APScrollView } from "../layout";
import { APCard, APTag, APText } from "../elements";
import { Divider } from "@mui/material";
import { APIcon } from "../icons/APIcon";
import { APPalette, CONST_DEBUG } from "../utils";
import { APForm, APFormSubmit, useAPForm } from "../forms";
import { JourneyProgressBar } from "./JourneyProgressBar";
import { JourneyStepHead } from "./JourneyStepHead";


type APJourneyProps = {
    title: string;
    description?: string;
    mode: "linear" | "non-linear";
    trailing?: React.ReactNode;
    steps: (JourneySection | JourneyStep)[],
    style?: React.CSSProperties;
    onStepChange?: (stepId: string) => void;
    initialStepId?: string;
}

export function APJourneyWeb(props: APJourneyProps) {

    let stepIdArray = props.steps.map((step, index) => {
        if (isJourneySection(step)) {
            return step.steps.map((subStep, index) => {
                return { id: step.title + "___" + subStep.title, step: subStep };
            })
        } else {
            return { id: step.title, step };
        }
    }).flat();

    let [currentStepId, setCurrentStepId] = useState<string>(props.initialStepId ?? stepIdArray[0].id);
    let currentStepIndex = stepIdArray.findIndex(a => a.id === currentStepId);
    let currentStep = stepIdArray[currentStepIndex]?.step;

    let currentTopLevelStep = currentStepId.split("___")[0];
    let currentTopLevelIndex = props.steps.findIndex(a => a.title === currentTopLevelStep);



    let [errorMessage, setErrorMessage] = useState<string | undefined>();

    let control = useAPForm();

    function isJourneySection(step: JourneySection | JourneyStep): step is JourneySection {
        return 'steps' in step;
    }

    function goToNextStep() {
        let currentIndex = stepIdArray.findIndex(a => a.id === currentStepId);
        if (currentIndex < stepIdArray.length - 1) {
            setCurrentStepId(stepIdArray[currentIndex + 1].id);
        }
    }

    useEffect(() => {
        setErrorMessage(undefined);
        props.onStepChange?.(currentStepId);
    }, [currentStepId]);


    return <APCard style={{ ...props.style, padding: 0 }}>
        <APColumn mainAxisSize="max" crossAxisAlignment="stretch">
            <APRow mainAxisSize="max" style={{ padding: "16px 16px 12px" }} >
                <APExpanded>
                    <APColumn crossAxisAlignment="start"  >
                        <APText variant="cardTitle" >{props.title}</APText>
                        {props.description && <APText variant="subtext" >{props.description}</APText>}
                    </APColumn>
                </APExpanded>
                {props.trailing}
            </APRow>
            <JourneyProgressBar totalSteps={stepIdArray.length} currentStep={currentStepIndex}></JourneyProgressBar>
            <APExpanded>
                <APRow crossAxisAlignment="stretch" >
                    <APScrollView>
                        <APColumn style={{ width: "268px", padding: "16px" }} gap="16px">
                            {props.steps.map((step, index) => {

                                let currentStepStatus = "Completed";
                                if (index == currentTopLevelIndex) {
                                    currentStepStatus = "In Progress";
                                }
                                if (index > currentTopLevelIndex) {
                                    currentStepStatus = "Pending";
                                }

                                return <JourneyStepHead key={step.title}
                                    step={step}
                                    status={currentStepStatus}
                                    currentStepId={currentStepId} onStepSelect={(stepId) => {
                                        if (stepIdArray.findIndex(a => a.id === stepId) < currentStepIndex) {
                                            setCurrentStepId(stepId);
                                        }
                                    }} />
                            }
                            )}
                        </APColumn>
                    </APScrollView>
                    <div style={{ backgroundColor: APPalette["grey-200"], width: "1px" }}></div>
                    {currentStep &&
                        <APExpanded key={"step_" + currentStepId}>
                            <APColumn mainAxisSize="max" crossAxisAlignment="stretch">
                                <APColumn style={{ padding: "16px 16px 4px 16px" }} crossAxisAlignment="start">
                                    <APText variant="h4">{currentStep.title}</APText>
                                    <APText variant="paragraph-xsmall">{currentStep.description}</APText>
                                </APColumn>
                                <APExpanded>
                                    <APForm control={control}>
                                        <APColumn mainAxisSize="max" crossAxisAlignment="stretch">
                                            <APExpanded>
                                                <APScrollView style={{ padding: "8px 64px 16px 16px" }}>
                                                    {currentStep.form}
                                                </APScrollView>
                                            </APExpanded>
                                            <Divider ></Divider>
                                            <APRow mainAxisSize="max" mainAxisAlignment="end" style={{ padding: "16px 16px" }} gap="16px">
                                                {errorMessage && <APTag variant="warning">{errorMessage}</APTag>}
                                                <APFormSubmit size="medium" style={{ marginTop: 0 }} onClick={async () => {
                                                    setErrorMessage(undefined);
                                                    if (await control.validate()) {
                                                        try {
                                                            await currentStep?.onSubmit();
                                                            if (props.mode === "linear") {
                                                                goToNextStep();
                                                            }
                                                        } catch (err: any) {
                                                            setErrorMessage(err.message);
                                                        }
                                                    }
                                                }}>{(props.mode == "linear" && currentStepIndex + 1 != stepIdArray.length) ? "Next" : "Submit"}</APFormSubmit>
                                            </APRow>
                                        </APColumn>
                                    </APForm>
                                </APExpanded>
                            </APColumn>
                        </APExpanded>
                    }
                </APRow>
            </APExpanded>
        </APColumn>

    </APCard>;
}

export type JourneySection = {
    title: string;
    description: string;
    steps: JourneyStep[];
}


export type JourneyStep = {
    title: string;
    description: string;
    form: React.ReactNode;
    onSubmit: () => Promise<any>;
}
