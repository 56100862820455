import Axios, { AxiosInstance } from "axios";
import { plainToClass } from "class-transformer";
import { singleton } from "tsyringe";
import SelfDeclarationModel, { NomineeValidationModel, SelfAPIReq, SelfValidationModel } from "../App/RegulatoryValidation/Models.tsx/SelfDeclarationModel";
import Activation2Model, { ContactModel, HoldingType, TaxStatus } from "../Models/Activation2Model";
import { CheckPanDetailsClass, ClientActivationResponseSchema } from "../App/ClientActivation/ClientActivation";
import { DematDetailsType, MinorDetailsType, PersonalRequestDetails } from "../App/ClientActivation/PersonalDetails";
import { SaveAddressDetailsRequestClass } from "../App/ClientActivation/AddressDetails";
import { NomineeDetailsRequestClass } from "../App/ClientActivation/NomineeDetails";
import { SignatureRequestForHolderClass } from "../App/ClientActivation/SignaturePage";
import { VerifyBankRequestClass } from "../App/ClientActivation/BankVerificationPage";
import { PhotoRequestForHolderClass } from "../App/ClientActivation/PhotoPage";

export const SERVER_URL = import.meta.env.REACT_APP_SERVER_URL ?? "";

@singleton()
export default class ClientApiServices {
    instance: AxiosInstance = Axios.create({
        baseURL: SERVER_URL
    });

    constructor() {
        this.instance.interceptors.request.use((config) => {
            return config;
        }, (error => {
            return Promise.reject(error);
        }));

        this.instance.interceptors.response.use((v) => v, (error) => {
            if (error?.response?.data?.userMsg) {
                error.message = error.response.data.userMsg;
            };
            if (error?.response?.data?.metaData) {
                error.metaData = error.response.data.metaData;
            };
            return Promise.reject(error);
        })
    }

    async generatePhoneOTP(req: { phone: string }, token: String) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/generatePhoneOTP",
            data: req,
            headers: {
                Authorization: "Bearer " + token
            }
        });
    }

    async verifyPhoneOTP(req: { phone: string, otp: string }, token: String) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/verifyPhoneOTP",
            data: req,
            headers: {
                Authorization: "Bearer " + token
            }
        });
    }

    async generateEmailOTP(req: { email: string }, token: String) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/generateEmailOTP",
            data: req,
            headers: {
                Authorization: "Bearer " + token
            }
        });
    }

    async verifyEmailOTP(req: { email: string, otp: string }, token: String) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/verifyEmailOTP",
            data: req,
            headers: {
                Authorization: "Bearer " + token
            }
        });
    }

    async getDetails(token: string) {
        var response = await this.instance.request({
            method: 'GET',
            url: "/user/activation/selfDeclaration/details",
            headers: {
                Authorization: "Bearer " + token
            }
        });

        return plainToClass(SelfDeclarationModel, response.data)
    }

    async updateSelfValidation(data: SelfAPIReq, token: string) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/updateSelf",
            headers: {
                Authorization: "Bearer " + token
            },
            data
        });
    }

    async updateNomineeValidation(data: NomineeValidationModel, token: string) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/updateNominee",
            headers: {
                Authorization: "Bearer " + token
            },
            data
        });
    }

    async updateMemberSelfValidation(data: SelfValidationModel, token: string) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/updateMemberSelf",
            headers: {
                Authorization: "Bearer " + token
            },
            data
        });
    }

    async updateMemberNomineeValidation(data: NomineeValidationModel, token: string) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/updateMemberNominee",
            headers: {
                Authorization: "Bearer " + token
            },
            data
        });
    }

    async updateMemberRelationshipValidation(relationship: string, token: string) {
        await this.instance.request({
            method: 'POST',
            url: "/user/activation/selfDeclaration/updateMemberRelationship",
            headers: {
                Authorization: "Bearer " + token
            },
            data: {
                relationship
            }
        });
    }
    async kycContactValidation(panNumber: string): Promise<{ link: string }> {
        var res = await this.instance.request({
            method: 'POST',
            url: "/mweb/kycContactValidation",
            data: {
                panNumber
            }
        });

        return { link: res.data.link };
    }

    async getLamfSdkCredentials({ mwebToken, otp }: { mwebToken: string, otp: string }): Promise<{ message: string, voltCustomerCode: string, authToken: string, ssoToken: string }> {
        let res = await this.instance.request({
            method: "POST",
            url: "/mweb/lamf/getVoltSdkCredentials",
            data: {
                mwebToken,
                otp,
            }
        });
        return res.data;
    }

    async generateOTP(token: string): Promise<{ message: string, voltCustomerCode: string, authToken: string, ssoToken: string }> {
        let res = await this.instance.request({
            method: "GET",
            url: `/mweb/lamf/generateOtp?token=${token}`,
        });
        return res.data;
    }

}

export type ValidatePANDetailsRequestType = { token: string, firstHolderPanDetails: CheckPanDetailsClass, secondHolderPanDetails?: CheckPanDetailsClass };

// 026291800001191
// YESB0000262