import { RouteComponentProps } from '@gatsbyjs/reach-router';
import { APCenter, APColumn, APImage, APSizedBox, APText } from "ap-components";

export default function SuccessPage(props: RouteComponentProps) {
    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch' style={{ width: "100vw" }} mainAxisAlignment="center">
            <APColumn>
                <APImage src="/icons/greenCheck.svg" height="80px" width="80px" />
                <APSizedBox height="32px" />
                <APText>
                    Your self declaration has been recorded.
                </APText>
                <APSizedBox height="6px" />
                <APText>
                    You can now close this page.
                </APText>
            </APColumn>
        </APColumn>
    )
}