import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, useDeviceType } from "ap-components";
import React, { useRef, useState } from "react";
import CalculatorInput from "../CalculatorInput";
import CalculatorFormulas from "../CalculatorFormulas";
import html2canvas from "html2canvas";
import saveAs from "file-saver";

export default function Emi() {

    const targetElementRef = useRef<HTMLDivElement>(null);

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);
    const deviceType = useDeviceType();

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Emi Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };


    const [initialValue, setInitialValue] = useState({
        loanAmount: 200000,
        loanPeriod: 5,
        interestRate: 12
    })

    var output = CalculatorFormulas.emiCalculation(initialValue);
    var loanAmount = output.loanAmount
    var totalAmount = output.totalAmount
    var interest = output.interestAmount
    var monthlyEmi = output.monthlyAmount
    var loanAmountPercentage = (loanAmount * 100) / (totalAmount)
    var interestPercentage = (interest) * 100 / (totalAmount)

    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween" style={{ height: "40px" }} >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>EMI Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >

                        <APImage src="/icons/icon-camera.svg" />

                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />

            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}  >

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>
                        <CalculatorInput
                            label="Loan Amount"
                            dataType="amount"
                            value={initialValue.loanAmount}
                            inputMin={100}
                            sliderStep={50000}
                            inputMax={1000000000}
                            sliderMin={50000}
                            sliderMax={1000000}
                            onChange={(value) => {

                                setInitialValue({ ...initialValue, loanAmount: value })
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Duration"
                            dataType="age"
                            value={initialValue.loanPeriod}
                            inputMin={1}
                            sliderStep={1}
                            inputMax={50}
                            sliderMax={30}
                            sliderMin={1}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, loanPeriod: value })
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Interest rate"
                            dataType="percentage"
                            value={initialValue.interestRate}
                            inputMin={1}
                            inputMax={30}
                            sliderMax={30}
                            sliderStep={1}
                            sliderMin={1}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, interestRate: value })
                            }}
                        />
                    </div>
                </APExpanded>

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot dataType="amount" label="Loan Amount" value={initialValue.loanAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Duration" value={initialValue.loanPeriod} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="percentage" label="Interest rate" value={initialValue.interestRate} />
                    </div>
                </APExpanded>

                <APSizedBox width="32px" />

                <APExpanded flex={3}>
                    <APColumn crossAxisAlignment="stretch" style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }} >
                        <APText variant="h3" style={{ color: APPalette["grey-700"] }}>
                            {totalAmount.currency(2, true)}
                        </APText>
                        <APSizedBox height="8px" />
                        <APRow>
                            {/* TODO->indigo 400 is not in APPalette */}
                            <APSizedBox width={`${loanAmountPercentage.toString()}%`} height="24px" style={{ background: "#7978DE", borderRadius: "8px 0px 0px 8px" }} />
                            {/* TODO->orange 300 is not in APPalette */}
                            <APSizedBox width={`${interestPercentage.toString()}%`} height="24px" style={{ background: "#FFBF66", borderRadius: "0px 8px 8px 0px" }} />
                        </APRow>

                        <APSizedBox height="8px" />

                        <APRow>
                            <APSizedBox height="6.9px" width="6.9px" style={{ borderRadius: "50%", background: "#7978DE", }} />
                            <APSizedBox width="4px" />
                            <APText variant="paragraph-xsmall" style={{ color: APPalette["grey-600"] }}>
                                {`Loan Amount (${loanAmount.currency(2, true)})`}
                            </APText>
                            <APSizedBox width="8px" />
                            <APSizedBox height="6.9px" width="6.9px" style={{ borderRadius: "50%", background: "#FFBF66", }} />
                            <APSizedBox width="4px" />
                            <APText variant="paragraph-xsmall" style={{ color: APPalette["grey-600"] }}>
                                {`Interest Payable (${interest.currency(2, true)})`}
                            </APText>
                        </APRow>

                        <APSizedBox height="16px" />

                        <APRow crossAxisAlignment="start" mainAxisAlignment="spaceBetween" >
                            <ChartValue label="Loan Amount" value={loanAmount} style={{ color: "#7978DE" }} />
                            <APSizedBox width="36px" />
                            <APSizedBox>
                                <APColumn gap="6px" >
                                    <APText color={APPalette['grey-600']} variant='paragraph-small'>Total Amount Payable</APText>
                                    <APColumn crossAxisAlignment="center">
                                        <APText center={true} variant='h2'>{totalAmount.currency(2, true)}</APText>
                                    </APColumn>
                                    <APSizedBox height="16px" />
                                    <APText color={APPalette['grey-600']} variant='paragraph-small'>Monthly EMI</APText>
                                    <APColumn crossAxisAlignment="center">
                                        <APText center={true} variant='h2'>{monthlyEmi.currency(2, true)}</APText>
                                    </APColumn>
                                </APColumn>
                            </APSizedBox>
                            <APSizedBox width="36px" />
                            <ChartValue label={"Interest Payable"} value={interest} style={{ color: "#FFBF66" }} />
                        </APRow>

                    </APColumn>
                </APExpanded>
            </div>
        </div>

    )
}

export function ChartValue({ label, value, style }: { label: string, value: number, style?: React.CSSProperties }) {
    const deviceType = useDeviceType()
    return (
        <APSizedBox >
            <APColumn crossAxisAlignment={deviceType == "mobile" ? "start" : "center"} gap="6px" >
                <APText color={APPalette['grey-600']} variant='paragraph-small'>{label}</APText>
                <APColumn crossAxisAlignment={deviceType == "mobile" ? "start" : "center"} >
                    <APText center={true} variant='h2' style={{ ...style }}>{value?.currency(2, true)}</APText>
                </APColumn>
            </APColumn>
        </APSizedBox>
    )
}

export function ScreenShot({ label, value, style, dataType }: { label: string, value: string | number, style?: React.CSSProperties, dataType?: string }) {
    switch (dataType) {

        case "age":
            value = `${value.toString()} years`
            break;
        case "percentage":
            value = value.toString() + "％"
            break;
        default:
            value
            break;
    }
    return (
        <APSizedBox >
            <APColumn crossAxisAlignment="center" gap="6px" >
                <APText color={APPalette['grey-600']} variant='paragraph-small'>{label}</APText>
                <APColumn crossAxisAlignment="center">
                    <APText center={true} variant='h2' style={{ ...style }}>{value}</APText>
                </APColumn>
            </APColumn>
        </APSizedBox>
    )
}