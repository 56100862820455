/**
 *  Check the following functionality if you ever change something in this component
 * 1. Focus on next input on entering a value
 * 2. Focus on previous input on deleting a value
 * 3. Paste functionality
 * 4. Error handling
 * 5. Validation
 * 6. Reset
 * 7. onChange
 * 8. onComplete
 */

import { useState, useEffect, useContext, useRef } from 'react';
import { APText } from '../elements';
import { APColumn, APSizedBox } from '../layout';
import { APPalette, NArray } from '../utils';
import { APFormContext } from './APForm';
import { APFormControl, APFormFieldItem } from './Common';

interface OTPInputProps {
    digits: 2 | 3 | 4 | 5 | 6 | 7 | 8;
    onChanged?: (value: string) => void | Promise<void>
    onComplete?: (value: string) => void | Promise<void>
    validator?: (v: string) => React.ReactNode | null | Promise<React.ReactNode | null>
    control?: APFormControl
    /**
     * @default "8px 2px"
     * "have 2px side padding to accommodate the border shadow in case of overflow hidden"
     */
    padding?: string
    error?: React.ReactNode
    helperText?: React.ReactNode
}

export function APOtp(props: OTPInputProps) {
    const [digits, setDigits] = useState(Array(props.digits).fill(''));
    const otp = digits.join('');

    let containerRef = useRef<HTMLDivElement>(null);

    let control2: APFormControl | undefined = useContext(APFormContext);
    if (props.control !== undefined) {
        control2 = props.control;
    }
    const [error, setError] = useState<React.ReactNode | null>(null);

    async function checkValues(): Promise<boolean> {
        let errorMessage = null;
        if (props.validator) {
            errorMessage = await props.validator(otp);
        }
        setError(errorMessage);
        return !errorMessage;
    }

    let fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setDigits(Array(props.digits).fill(''));
        }
    };

    useEffect(() => {
        const inputs = containerRef.current?.children ?? [];
        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i] as HTMLInputElement;
            input.addEventListener('input', handleInput);
            input.addEventListener('keydown', handleKeydown);
            input.addEventListener('paste', handlePaste);
        }
        return () => {
            for (let i = 0; i < inputs.length; i++) {
                const input = inputs[i] as HTMLInputElement;
                input.removeEventListener('input', handleInput);
                input.removeEventListener('keydown', handleKeydown);
                input.removeEventListener('paste', handlePaste);
            }
        };
    }, []);

    useEffect(() => {
        if (props.onChanged) {
            props.onChanged(otp)
        }
        if (props.onComplete && otp.length === props.digits) {
            props.onComplete(otp);
        }
        if (control2 !== undefined) {
            control2.fields.add(fieldItem);
        }
        return () => {
            if (control2 !== undefined) {
                control2.fields.delete(fieldItem);
            }
        };
    })

    useEffect(() => {
        (async () => {
            if (props.validator && error !== null) {
                setError(await props.validator(otp));
            }
            if (props.onChanged)
                props.onChanged(otp);
        })();

    }, [otp]);

    const handleInput = (e: any,) => {
        let value = e.target.value,
            target = e.target,
            index = parseInt(e.target.dataset.index as string, 10);

        const nextInput: HTMLInputElement | null = containerRef.current?.children[index + 1] as HTMLInputElement;

        // e.inputType === 'insertFromPaste'
        // This solves the problem of giving an extra character "1" when pasting a value
        setDigits((prev) => {
            const next = [...prev];
            let _val = value
            if (!/\d/.test(_val) || e.inputType === 'insertFromPaste') {
                _val = '';
            }
            next[index] = _val;
            return next;
        });

        if (!/\d/.test(value) || e.inputType === 'insertFromPaste') {
            target.value = '';
            return
        }

        if (value.length === 1 && nextInput) {
            nextInput.focus();
        }
    }

    const handleKeydown = (e: any) => {
        let value = e.target.value,
            key = e.key,
            index = parseInt(e.target.dataset.index as string, 10);

        const prevInput: HTMLInputElement | null = containerRef.current?.children[index - 1] as HTMLInputElement;

        if (['Delete', 'Backspace'].includes(key) && value.length === 0 && prevInput) {
            prevInput.focus();
            prevInput.select();
        }
    }

    const processInput = (target: HTMLInputElement, index: number, value: string) => {
        const nextInput: HTMLInputElement | null = containerRef.current?.children[index + 1] as HTMLInputElement;

        setDigits((prev) => {
            const next = [...prev];
            let _val = value
            if (!/\d/.test(_val)) {
                _val = '';
            }
            next[index] = _val;
            return next;
        });

        if (!/\d/.test(value)) {
            target.value = '';
            return;
        }

        target.value = value; // Update the input value with the single-digit input

        if (value.length === 1 && nextInput) {
            nextInput.focus();
        }
    };

    const handlePaste = (e: any) => {
        // @ts-ignore
        const clipboardData = e.clipboardData || window.clipboardData;
        if (!clipboardData) {
            return;
        }

        const pastedData = clipboardData.getData('text');
        const inputs = containerRef.current?.children ?? [];

        let currentIndex = parseInt(e.target.dataset.index as string, 10);
        for (let i = 0; i < pastedData.length && currentIndex < props.digits; i++) {
            const input = inputs[currentIndex] as HTMLInputElement;
            processInput(input, currentIndex, pastedData.charAt(i));
            currentIndex++;
        }
    };

    const isError = !!props.error || !!error;
    let message = props.helperText;

    if (!!props.error) {
        message = props.error;
    }

    if (!!error) {
        message = error;
    }

    return (
        <APColumn style={{ padding: props.padding ?? "8px 2px", width: "fit-content" }} crossAxisAlignment='stretch'>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }} ref={containerRef}>
                {
                    NArray(props.digits).map((_, i) => (
                        <input
                            key={i}
                            type="text"
                            className={`${isError ? 'ap-otp-input-error' : 'ap-otp-input'}`}
                            maxLength={1}
                            data-index={i}
                            style={{
                                borderColor: isError ? APPalette['negative-300'] : otp.length === props.digits ? APPalette['brand-300'] : undefined,
                                boxShadow: otp.length === props.digits ? "none" : undefined
                            }}
                        />
                    ))
                }
            </div>
            {
                message &&
                <>
                    <APSizedBox height='4px' />
                    <APText variant='paragraph-xsmall' color={isError ? APPalette['negative-300'] : APPalette['grey-500']}>{message}</APText>
                </>
            }
        </APColumn>
    );
};