import React from "react";

interface GridLayout {
    /**
     * number of columns in the grid
     */
    count: number;
    children: any;
    /**
     * even spacing through both vertical and horizontal axis
     */
    spacing?: number;
    /**
     * custom spacing through vertical and horizontal axis
     */
    gapProps?: string;
    /**
     * spacing in between 2 rows
     */
    rowGap?: string;
    /**
     * spacing in between 2 columns
     */
    columnGap?: string;
}

export function APGrid(props: GridLayout) {

    var gridGap = props.spacing ? `${props.spacing}px` : props.gapProps;

    var mStyle: React.CSSProperties = {
        display: "grid", width: "100%",
        gridGap: gridGap ?? `${props.rowGap ?? 0} ${props.columnGap ?? 0}`,
        gridTemplateColumns: `repeat(${props.count},minmax(0,1fr))`,
    };

    return (
        <div style={mStyle}>
            {React.Children.map(props.children, (child) => {
                if (child)
                    return React.cloneElement(child, { style: { maxWidth: "100%" } });
            })}
        </div>
    );
}
