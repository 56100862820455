import { Type } from "class-transformer"

export default class SelfDeclarationModel {
    name!: string
    @Type(() => SelfModel)
    self: SelfModel = new SelfModel()
    @Type(() => NomineeModel)
    nominee: NomineeModel = new NomineeModel()
    @Type(() => MemberModel)
    members?: MemberModel[]
}

export class SelfModel {
    email?: string
    mobile?: string
    emailOwner?: string
    mobileOwner?: string
    status: string = "PENDING"
}

export class NomineeModel {
    name?: string
    relationship?: string
    status: string = "PENDING"
}

export class MemberModel {
    userId!: string
    name!: string
    @Type(() => SelfModel)
    self: SelfModel = new SelfModel()
    @Type(() => NomineeModel)
    nominee: NomineeModel = new NomineeModel()
    relationship?: string
    status: string = "PENDING"
}

export interface SelfValidationModel {
    email: string
    mobile: string
    emailOwner: string
    mobileOwner: string
}

export interface NomineeValidationModel {
    name: string
    relationship: string
}

export interface SelfAPIReq {
    email?: string;
    mobile?: string;
    emailOwner?: string;
    mobileOwner?: string;
    nominee?: string;
    nomineeRelation?: string;
}