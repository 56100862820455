import { getIt, handleErrorMessage, Deferred, APDialogCard } from "ap-components";
import { useState } from "react";
import ClientApiServices from "../../Services/ClientApiServices";
import OTPVerification from "./OTPVerification";

export default function VerificationDialog({ phone, email, token, deferred }: { deferred: Deferred<void>, phone: string, email: string, token: string }) {

    const [isPhoneOtpGenerated, setIsPhoneOtpGenerated] = useState(false);

    return (
        <APDialogCard title="OTP Verification" style={{ minWidth: "200px", maxWidth: "478px" }}>
            {
                !isPhoneOtpGenerated ?
                    <OTPVerification
                        key="phone_key"
                        description={`A 4 digit OTP has been sent to ${phone}`}
                        onGenerate={async () => {
                            try {
                                await getIt(ClientApiServices).generatePhoneOTP({ phone }, token);
                            } catch (error) {
                                handleErrorMessage(error);
                            }
                        }}
                        onVerify={async (otp) => {
                            try {
                                await getIt(ClientApiServices).verifyPhoneOTP({ phone, otp }, token);
                                setIsPhoneOtpGenerated(true);
                            } catch (error) {
                                handleErrorMessage(error);
                            }
                        }}
                    />
                    :
                    <OTPVerification
                        key="email_key"
                        description={`A 4 digit OTP has been sent to ${email}`}
                        onGenerate={async () => {
                            try {
                                await getIt(ClientApiServices).generateEmailOTP({ email }, token);
                            } catch (error) {
                                handleErrorMessage(error);
                            }
                        }}
                        onVerify={async (otp) => {
                            try {
                                await getIt(ClientApiServices).verifyEmailOTP({ email, otp }, token);
                                deferred.resolve()
                            } catch (error) {
                                handleErrorMessage(error);
                            }
                        }}
                    />
            }
        </APDialogCard>
    )
}