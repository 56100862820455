import { useLocation } from "@gatsbyjs/reach-router";
import { useState } from "react";
import { Constants } from "./ClientActivation";
import { APTypography } from "ap-components/src/utils/APTypography";
import Activation2Model from "../../Models/Activation2Model";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneyStepApp, useAPForm, Deferred, showErrorDialog, APColumn, APPalette, APText, APExpanded, APFormFieldText, APFormFieldDropdown, getIt } from "ap-components";

type AddressDetailsType = {
    line1: string;
    line2: string;
    line3: string;
    pincode: string;
    city: string;
    state: string;
    secondHolderLine1: string;
    secondHolderLine2: string;
    secondHolderline3: string;
    secondHolderPincode: string;
    secondHolderCity: string;
    secondHolderState: string;
    foreignAddressLine1: string;
    foreignAddressLine2: string;
    foreignAddressline3: string;
    foreignAddressPincode: string;
    foreignAddressCity: string;
    foreignAddressState: string;
    foreignAddressCountry: string;
};

export default function AddressDetailsStepperFunction({ activationData, updateActivationData }: { activationData: Activation2Model, updateActivationData: (activationData: Activation2Model) => void }): JourneyStepApp {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const baseDetailsPageControl = useAPForm();
    const [form, setForm] = useState<AddressDetailsType>({
        line1: activationData.permanentAddress?.line1 ?? '',
        line2: activationData.permanentAddress?.line2 ?? '',
        line3: activationData.permanentAddress?.line3 ?? '',
        pincode: activationData.permanentAddress?.pin ?? '',
        city: activationData.permanentAddress?.city ?? '',
        state: activationData.permanentAddress?.state ?? '',
        secondHolderLine1: activationData.secondHolderAddress?.line1 ?? '',
        secondHolderLine2: activationData.secondHolderAddress?.line2 ?? '',
        secondHolderline3: activationData.secondHolderAddress?.line3 ?? '',
        secondHolderPincode: activationData.secondHolderAddress?.pin ?? '',
        secondHolderCity: activationData.secondHolderAddress?.city ?? '',
        secondHolderState: activationData.secondHolderAddress?.state ?? '',
        foreignAddressLine1: activationData.foreignAddress?.line1 ?? '',
        foreignAddressLine2: activationData.foreignAddress?.line2 ?? '',
        foreignAddressline3: activationData.foreignAddress?.line3 ?? '',
        foreignAddressPincode: activationData.foreignAddress?.pin ?? '',
        foreignAddressCity: activationData.foreignAddress?.city ?? '',
        foreignAddressState: activationData.foreignAddress?.state ?? '',
        foreignAddressCountry: activationData.foreignAddress?.country ?? '',
    });

    return (
        {
            title: "Address Details",
            code: "AD",
            description: "",
            form: (<APColumn style={{ color: APPalette['grey-500'] }} crossAxisAlignment='stretch' gap='8px'>
                {!!activationData.secondHolderName && (
                    <APText style={APTypography.h4}>Holder 1: Fill details for {activationData?.name}</APText>
                )}
                {activationData.taxStatus == '02' && (<APText style={APTypography.h4}>Guardian Details</APText>)}
                <APExpanded>
                    <APFormFieldText
                        initialValue={activationData.permanentAddress?.line1}
                        label='Address Line 1'
                        keyboardType="text"
                        onChanged={(v) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                line1: v
                            }));
                        }}
                        validator={(v) => {
                            if (!v) {
                                return "Address Line 1 required"
                            };

                            if (v.length > 40) {
                                return "Cannot exceed 40 characters"
                            };

                            return null;
                        }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldText
                        initialValue={activationData.permanentAddress?.line2}
                        label='Address Line 2'
                        keyboardType="text"
                        onChanged={(v) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                line2: v
                            }));
                        }}
                        validator={(v) => {
                            if (!v) {
                                return "Address Line 2 required"
                            };
                            if (v.length > 40) {
                                return "Cannot exceed 40 characters"
                            };
                            return null;
                        }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldText
                        initialValue={activationData.permanentAddress?.line3}
                        label='Address Line 3'
                        keyboardType="text"
                        onChanged={(v) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                line3: v
                            }));
                        }}
                        validator={(v) => {
                            if (v.length > 40) {
                                return "Cannot exceed 40 characters"
                            };
                        }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldText
                        initialValue={activationData.permanentAddress?.pin}
                        label='Pincode'
                        keyboardType="text"
                        onChanged={(v) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                pincode: v
                            }));
                        }}
                        validator={(v) => {
                            if (!v) {
                                return "Pincode required"
                            };
                            if (v.length != 6) {
                                return "Pincode should be 6 characters"
                            };
                            return null;
                        }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldText
                        initialValue={activationData.permanentAddress?.city}
                        label='City'
                        keyboardType="text"
                        onChanged={(v) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                city: v
                            }));
                        }}
                        validator={(v) => {
                            if (!v) {
                                return "City required"
                            };
                            if (v.length > 40) {
                                return "Cannot exceed 40 characters"
                            };
                            return null;
                        }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldDropdown
                        initialValue={form.state}
                        items={Constants.states}
                        key={form.state}
                        label='State'
                        onChanged={(v) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                state: v!
                            }));
                        }}
                        validator={(v) => {
                            if (!v) {
                                return 'State is Required';
                            }
                            return null;
                        }}
                        style={{ height: "40px" }}
                    />
                </APExpanded>
                {!!activationData.secondHolderName && (<>
                    <APText style={APTypography.h4}>Holder 2: Fill details for {activationData?.secondHolderName}</APText>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.secondHolderAddress?.line1}
                            label='Address Line 1'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    secondHolderLine1: v!
                                }));
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;

                                if (!v) {
                                    return "Address Line 1 required"
                                };

                                if (v.length > 40) {
                                    return "Cannot exceed 40 characters"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.secondHolderAddress?.line2}
                            label='Address Line 2'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    secondHolderLine2: v!
                                }));
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;

                                if (!v) {
                                    return "Address Line 2 required"
                                };

                                if (v.length > 40) {
                                    return "Cannot exceed 40 characters"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.secondHolderAddress?.line3}
                            label='Address Line 3'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    secondHolderLine3: v!
                                }));
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.secondHolderAddress?.pin}
                            label='Pincode'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    secondHolderPincode: v!
                                }));
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;

                                if (!v) {
                                    return "Pincode required"
                                };

                                if (v.length != 6) {
                                    return "Pincode should be 6 characters"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.secondHolderAddress?.city}
                            label='City'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    secondHolderCity: v!
                                }));
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;

                                if (!v) {
                                    return "City required"
                                };

                                if (v.length > 40) {
                                    return "Cannot exceed 40 characters"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            initialValue={activationData.secondHolderAddress?.state}
                            items={Constants.states}
                            label='State'
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    secondHolderState: v!
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return 'State is Required';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                </>)
                }
                {
                    ['21', '24'].includes(activationData.taxStatus ?? '') && (<>
                        <APText style={APTypography.h4}>Holder 1: Foreign Address for {activationData?.name}</APText>
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.foreignAddress?.line1}
                                label='Address Line 1'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressLine1: v!
                                    }));
                                }}
                                validator={(v) => {
                                    if (!["21", "24"].includes(activationData.taxStatus ?? '')) return null;

                                    if (!v) {
                                        return "Address Line 1 required"
                                    };

                                    if (v.length > 40) {
                                        return "Cannot exceed 40 characters"
                                    };

                                    return null;
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.foreignAddress?.line2}
                                label='Address Line 2'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressLine2: v!
                                    }));
                                }}
                                validator={(v) => {
                                    if (!["21", "24"].includes(activationData.taxStatus ?? '')) return null;

                                    if (!v) {
                                        return "Address Line 2 required"
                                    };

                                    if (v.length > 40) {
                                        return "Cannot exceed 40 characters"
                                    };

                                    return null;
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.foreignAddress?.line3}
                                label='Address Line 3'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressLine3: v!
                                    }));
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.foreignAddress?.pin}
                                label='Pincode'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressPincode: v!
                                    }));
                                }}
                                validator={(v) => {
                                    if (!["21", "24"].includes(activationData.taxStatus ?? '')) return null;

                                    if (!v) {
                                        return "Pincode required"
                                    };

                                    if (v.length != 6) {
                                        return "Pincode should be 6 characters"
                                    };

                                    return null;
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.foreignAddress?.city}
                                label='City'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressCity: v!
                                    }));
                                }}
                                validator={(v) => {
                                    if (!["21", "24"].includes(activationData.taxStatus ?? '')) return null;

                                    if (!v) {
                                        return "City required"
                                    };

                                    if (v.length > 40) {
                                        return "Cannot exceed 40 characters"
                                    };

                                    return null;
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.foreignAddress?.state}
                                label='State'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressState: v!
                                    }));
                                }}
                                validator={(v) => {
                                    if (!["21", "24"].includes(activationData.taxStatus ?? '')) return null;

                                    if (!v) {
                                        return "State required"
                                    };

                                    return null;
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldDropdown
                                initialValue={activationData.foreignAddress?.country}
                                items={Constants.countries}
                                label='Country'
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        foreignAddressCountry: v!
                                    }));
                                }}
                                validator={(v) => {
                                    if (!["21", "24"].includes(activationData.taxStatus ?? '')) return null;

                                    if (!v) {
                                        return "Country required"
                                    };

                                    return null;
                                }}
                                style={{ height: "40px" }}
                            />
                        </APExpanded>
                    </>)
                }
            </APColumn>),
            onSubmit: async () => {

                const dataToSubmit: SaveAddressDetailsRequestClass = {
                    token: token!,
                    addressDetails: { line1: form.line1, line2: form.line2, line3: form.line3 ?? '', city: form.city, pin: form.pincode, state: form.state }
                };

                if (['21', '24'].includes(activationData.taxStatus ?? '')) {
                    dataToSubmit.foreignAddressDetails = { line1: form.foreignAddressLine1, line2: form.foreignAddressLine2, line3: form.foreignAddressline3, city: form.foreignAddressCity, pin: form.foreignAddressPincode, state: form.foreignAddressState, country: form.foreignAddressCountry }
                }

                if (activationData.holdingType == 'AS') {
                    dataToSubmit.secondHolderAddress = { line1: form.secondHolderLine1, line2: form.secondHolderLine2, line3: form?.secondHolderline3 ?? '', city: form.secondHolderCity, pin: form.secondHolderPincode, state: form.secondHolderState }
                }

                const updatedActivationData = await getIt(MutualFundApiServices).saveAddressDetails(dataToSubmit);

                updateActivationData(updatedActivationData);
            },
        }
    );
}


class AddressRequestClass {
    line1!: string
    line2!: string
    line3?: string
    city!: string
    pin!: string
    state!: string
}

class ForeignAddressRequestClass {
    line1!: string
    line2!: string
    line3?: string
    city!: string
    state!: string
    pin!: string
    country!: string
}

export class SaveAddressDetailsRequestClass {
    token!: string;
    addressDetails!: AddressRequestClass;
    secondHolderAddress?: AddressRequestClass;
    foreignAddressDetails?: ForeignAddressRequestClass
}