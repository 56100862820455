import { redirectTo, RouteComponentProps } from "@gatsbyjs/reach-router";
import { APCard, APColumn, APForm, APFormFieldText, APFormSubmit, APImage, APRow, APText, getIt, handleErrorMessage, REGEX, useAPForm } from "ap-components";
import { useState } from "react";
import ClientApiServices from "../../Services/ClientApiServices";

export function KYCContactValidation(props: RouteComponentProps) {

    var control = useAPForm();

    var [panNumber, setPanNumber] = useState<string>("");

    return <APColumn style={{ padding: "40px 20px" }} gap="28px" crossAxisAlignment="center">
        <APImage src={"/assetplus/aplight.svg"} height={26}></APImage>
        <APCard style={{ width: "90vw", maxWidth: "500px" }} >
            <APForm control={control} >
                <APColumn crossAxisAlignment="stretch" mainAxisSize="min" gap="2px">
                    <APText variant="h2">KYC Contact Validation</APText>
                    <APText variant="subtext">Please enter your PAN to check validation status</APText>
                    <APFormFieldText
                        label="PAN Number"
                        validator={(v) => {

                            if (!v || !REGEX.PAN.test(v)) {
                                return "Invalid PAN"
                            }
                            return null;
                        }}
                        onChanged={(v) => {
                            setPanNumber(v);
                        }}
                    ></APFormFieldText>

                    <APFormSubmit
                        onClick={async () => {
                            try {
                                var result = await getIt(ClientApiServices).kycContactValidation(panNumber)
                                if (result.link) {
                                    window.location.href = result.link;
                                }
                            } catch (err) {
                                console.log(err);
                                handleErrorMessage(err);
                            }
                        }}
                    >
                        Proceed
                    </APFormSubmit>
                </APColumn>

            </APForm>
        </APCard>
    </APColumn>;
}