import { RouteComponentProps } from '@reach/router'
import { APExpanded, APCenter, APColumn, APText } from 'ap-components'

export default function NotFoundPage(props: RouteComponentProps) {
    return (
        <APExpanded>
            <APCenter>
                <APColumn>
                    <APText variant="dialogTitle">You may now close this page</APText>
                </APColumn>
            </APCenter>
        </APExpanded>
    )
}
