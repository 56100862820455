import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, useDeviceType } from 'ap-components';
import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import { useRef, useState } from "react";
import { APCharts } from '../../../Common/Charting';
import CalculatorFormulas from '../CalculatorFormulas';
import CalculatorInput from '../CalculatorInput';
import { ChartValue, ScreenShot } from './Emi';


export default function SipReturns() {
    const targetElementRef = useRef<HTMLDivElement>(null);

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);
    const deviceType = useDeviceType()

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important");
                in2Ref.current?.style.setProperty("display", "block", "important");
                in3Ref.current?.style.setProperty("display", "none", "important");
                in4Ref.current?.style.setProperty("display", "block", "important");

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "SIP Returns Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important");
                in2Ref.current?.style.setProperty("display", "none", "important");
                in3Ref.current?.style.setProperty("display", "block", "important");
                in4Ref.current?.style.setProperty("display", "none", "important");

            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };

    const [initialValue, setInitialValue] = useState({
        investmentAmount: 30000,
        investmentPeriod: 5,
        returnsRate: 12,
        annualIncrement: 1500
    })

    var output = CalculatorFormulas.sipReturnsCalculation(initialValue);
    var maturityValue = output[output.length - 1]?.maturityValue;
    var gain = output[output.length - 1]?.gain
    var totalInvested = output[output.length - 1]?.totalInvestedAmount;

    var option = {
        legend: {
            data: ['Gains', 'Total Investment'],
            position: "center"
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none',
            },
        },
        grid: {
            left: '12%',
            bottom: "11%",
            right: "1%"
        },
        xAxis: [
            {
                type: 'category',
                data: output.map(year => year.time),
                axisPointer: {
                    type: 'shadow',
                    label: {
                        formatter: (v: any) => {
                            return "Year " + v.value;
                        }
                    }
                },
                axisLabel: {
                    formatter: 'Year {value}'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                min: 0,
                axisLabel: {
                    formatter: (v: number) => {
                        return v.currency(1, true);
                    }
                }
            },
        ],
        series: [
            {
                name: 'Total Investment',
                type: 'bar',
                stack: 'maturity',
                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency(2, true);
                    }
                },
                itemStyle: {

                    color: '#268EFF'
                },
                data: output.map(sum => sum.totalInvestedAmount),
            },
            {
                name: 'Gains',
                type: 'bar',
                stack: 'maturity',
                itemStyle: {
                    color: '#5DD27A'
                },
                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency(2, true);
                    }
                },
                data: output.map(gain => gain.gain),
            },

        ]
    }
    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween" >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>SIP Returns Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >
                        <APImage src="/icons/icon-camera.svg" />
                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />
            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}  >

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>
                        <CalculatorInput
                            label="Monthly Investment"
                            dataType="amount"
                            value={initialValue.investmentAmount}
                            sliderMax={200000}
                            sliderMin={5000}
                            sliderStep={1000}
                            inputMax={200000}
                            inputMin={100}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, investmentAmount: value });
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Duration"
                            dataType="age"
                            value={initialValue.investmentPeriod}
                            sliderMax={30}
                            sliderMin={1}
                            inputMax={50}
                            inputMin={1}
                            sliderStep={1}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, investmentPeriod: value });
                            }}
                        />
                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Expected Returns"
                            dataType="percentage"
                            value={initialValue.returnsRate}
                            sliderMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            inputMax={30}
                            inputMin={1}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, returnsRate: value });
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Annual Inc."
                            dataType="amount"
                            value={initialValue.annualIncrement}
                            sliderMax={initialValue.investmentAmount * 0.3}
                            sliderMin={0}
                            sliderStep={100}
                            inputMin={0}
                            inputMax={10000000}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, annualIncrement: value });
                            }}
                        />
                    </div>
                </APExpanded>

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot label="Investment Amount" value={initialValue.investmentAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Duration" value={initialValue.investmentPeriod} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="percentage" label="Expected Returns" value={initialValue.returnsRate} />
                        <APSizedBox height='24px' />
                        <ScreenShot label="Annual Inc." value={initialValue.annualIncrement} />
                    </div>
                </APExpanded>
                <APSizedBox width="32px" />
                <APExpanded flex={3} >
                    <APColumn crossAxisAlignment='stretch' style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }} >
                        <APCharts height="320px" option={option} />
                        <div style={{
                            display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "8px", justifyContent: "space-between"
                        }} >
                            < ChartValue label={"Total Invested"} value={totalInvested} style={{ color: "#268EFF" }} />

                            < ChartValue label={"Maturity Value"} value={maturityValue} style={{ color: APPalette["grey-700"] }} />

                            < ChartValue label={"Gains"} value={gain} style={{ color: "#5DD27A" }} />
                        </div>
                    </APColumn>
                </APExpanded>

            </div>
        </div>
    )
}
