import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { styles } from 'ap-components'

const theme = createTheme({
    ...adaptV4Theme(styles),
    // components: {
    //     MuiOutlinedInput: {
    //         styleOverrides: {
    //             root: {
    //                 ":hover": {
    //                     borderColor: "red"
    //                 }
    //             },
    //             input: {
    //                 ":hover": {
    //                     borderColor: "red"
    //                 }
    //             }
    //         }
    //     }
    // }
});

export default theme
