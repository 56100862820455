import { getIt } from "ap-components";
import Axios, { AxiosInstance } from "axios";
import { singleton } from "tsyringe";
import SharedPrefs from "./SharedPrefs";
import { PolicyDetails } from "../App/HealthInsurance/Models/PolicyDetails";

@singleton()
export default class InsHealthService {
    instance: AxiosInstance = Axios.create({
        baseURL: import.meta.env.REACT_APP_INS_HEALTH_URL
    });
    constructor() {
        this.instance.interceptors.request.use(config => {
            // if (getIt(SharedPrefs).isAuth()) {
            config.headers.Authorization = "Bearer " + getIt(SharedPrefs).getInsHealthToken();
            // }
            return config;
        }, (err) => Promise.reject(err));

        this.instance.interceptors.response.use(e => e, err => {
            if (err.response.data.userMsg)
                err.message = err.response.data.userMsg;
            return Promise.reject(err);
        })
    }
    async getPolicyDetails(): Promise<PolicyDetails> {
        var response = await this.instance.request({
            method: 'GET',
            url: '/mweb/getPolicyDetails',
        });
        return response.data
    }
}