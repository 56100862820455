import { useLayoutEffect, useState } from "react";


export function useDeviceType() {

    let [size, setSize] = useState<"mobile" | "tablet" | "desktop">(() => {
        if (window.innerWidth < 768) {
            return "mobile";
        } else if (window.innerWidth < 1024) {
            return "tablet";
        } else {
            return "desktop";
        }

    });

    useLayoutEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) {
                setSize("mobile");
            } else if (window.innerWidth < 1024) {
                setSize("tablet");
            } else {
                setSize("desktop");
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
}