import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { APColumn, APExpanded, APPalette, APRow, APScrollView, APSizedBox, useDeviceType } from "ap-components";


import RetirementCalculator from "./Calculators/Retirement";
import Lumpsum from "./Calculators/Lumpsum";
import Emi from "./Calculators/Emi";
import Education from "./Calculators/Education";
import Inflation from "./Calculators/Inflation";
import CompoundInterest from "./Calculators/CompoundInterest";
import SipReturns from "./Calculators/SipReturns";
import GoalSetting from "./Calculators/GoalSetting";


export default function Calculator(props: RouteComponentProps<{ calculator?: string }>) {
    const typeOfCalculator = props?.calculator;

    function getCalculatorPage() {
        switch (typeOfCalculator) {
            case 'retirement_fund': return <RetirementCalculator />;
            case 'lumpsum': return <Lumpsum />
            case 'compound_interest': return <CompoundInterest />
            case 'emi': return <Emi />
            case 'education': return <Education />
            case 'inflation': return <Inflation />
            case 'sip_returns': return <SipReturns />
            case 'goal_setting': return <GoalSetting />
            default:
                return null;
        }
    }
    let deviceType = useDeviceType();


    return (
        <APColumn crossAxisAlignment="stretch" mainAxisSize="max" style={{ paddingRight: "8px" }}>
            <APExpanded  >
                <APScrollView style={{ padding: "24px" }}>
                    <APColumn
                        crossAxisAlignment="stretch"
                        style={{ padding: "16px", backgroundColor: APPalette.white, borderRadius: "16px" }} >
                        <APSizedBox height="16px" />
                        <APRow crossAxisAlignment="start" >
                            <APExpanded flex={6}>
                                <div >
                                    {
                                        getCalculatorPage()
                                    }
                                </div>
                            </APExpanded>
                            {deviceType != "mobile" && <APExpanded flex={1}>
                                <div></div>
                            </APExpanded>}
                        </APRow>
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    )
}

