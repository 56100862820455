import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router'
import { APAsyncButton, APCard, APColumn, APExpanded, APIcon, APImage, APPalette, APRow, APScrollView, APSizedBox, APText, getIt, handleErrorMessage } from 'ap-components';
import { PriceBreakdownCard, Product, QuoteModel, StrapiInsuranceTerm } from 'insurance-term';
import { useEffect, useState } from 'react';
import InsuranceTermServices from '../../Services/InsuranceTermServices';
import moment from 'moment';
import CMS2Services from '../../Services/CMS2Services';
import saveAs from 'file-saver'
import { Skeleton } from '@mui/material';


export default function InsuranceTerm(props: RouteComponentProps) {
    const location = useLocation()
    const token = new URLSearchParams(location.search).get("token");
    const [quote, setQuote] = useState<QuoteModel>()
    const [strapi, setStrapi] = useState<StrapiInsuranceTerm>()

    async function getQuoteDetails() {
        if (!token) return;

        try {
            const res = await getIt(InsuranceTermServices).getQuoteDetails(token)
            setQuote(res)
        } catch (error) {
            handleErrorMessage(error)
        }
    }

    // async function getPremium() {
    //     if (!token) return;

    //     try {
    //         const res = await getIt(InsuranceTermServices).getPremium(token)
    //         setPremium(res)
    //     } catch (error) {
    //         handleErrorMessage(error)
    //     }
    // }

    async function getProducts() {
        try {
            const response = await getIt(CMS2Services).getTermInsurance()
            setStrapi(response)
        } catch (error) {
            handleErrorMessage(error)
        }
    }

    useEffect(() => {
        getQuoteDetails()
        // getPremium()
        getProducts()
    }, [token])

    if (!token) {
        window.location.href = "/";
    }

    let selectedProduct: Product | undefined = undefined;

    if (strapi || quote) {
        selectedProduct = strapi?.products?.find(item => item.provider === quote?.provider)
    }

    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch'>
            <APExpanded>
                <APScrollView>
                    <APColumn>
                        <APColumn crossAxisAlignment='stretch' style={{ maxWidth: "600px", padding: "16px" }} gap='16px'>
                            <APRow mainAxisAlignment='center'>
                                <APImage width={181} height={24} src='/Assetplus_Logo.png' />
                            </APRow>
                            {/* <APCard padding='12px'>
                                <APColumn crossAxisAlignment='stretch' gap="8px">
                                    
                                    <APColumn crossAxisAlignment='start' gap='8px'>
                                       
                                        <APRow >
                                            {
                                                quote &&
                                                <APText variant='paragraph-large'>
                                                    {`${quote.gender === "male" ? "He" : quote.gender === "female" ? "She" : "He/She"} will be paying a premium of `}
                                                    <APText style={{ display: "inline" }} variant='h3'>{`${quote?.premium?.totalPremiumWithTax.currency()}`}</APText>
                                                    {` ${getFrequencyText(quote)} `}
                                                    {
                                                        quote.policyPremiumTerm !== 1 &&
                                                        <APText style={{ display: "inline" }} variant='h3'>{`${quote.policyPremiumTerm} years`}</APText>
                                                    }
                                                </APText>
                                            }
                                        </APRow>
                                    </APColumn>
                                </APColumn>
                            </APCard> */}

                            <APCard padding='12px'>
                                <APColumn crossAxisAlignment='stretch' gap='12px'>
                                    <APRow gap="8px">
                                        {
                                            selectedProduct?.providerIcon?.url ?
                                                <APImage style={{
                                                    maxWidth: "36px",
                                                    maxHeight: "24px"
                                                }} src={selectedProduct.providerIcon.url} />
                                                :
                                                <APSizedBox width="36px" height="24px" style={{ backgroundColor: APPalette["grey-500"] }} />
                                        }
                                        {
                                            selectedProduct?.planName ?
                                                <APExpanded>
                                                    <APText variant="h3">{selectedProduct?.planName}</APText>
                                                </APExpanded>
                                                : <Skeleton width={300} height={25} />
                                        }
                                    </APRow>
                                    {quote && <APRow>
                                        <APText>
                                            {`${quote.userName} will have a life cover of `}
                                            <APText variant='h3' style={{ display: "inline" }}>{quote.sumAssured?.currency(1, true)}</APText>
                                            {" "} till the age of {" "}
                                            <APText variant='h3' style={{ display: "inline" }}>{quote.coverUpto} years</APText>
                                        </APText>
                                    </APRow>}
                                    <InfoItem
                                        label="Name"
                                        value={quote?.userName ?? "-"}
                                    />
                                    <InfoItem
                                        label="Quote"
                                        value={quote?.quoteName ?? "-"}
                                    />
                                    <APRow crossAxisAlignment="start" gap="8px">
                                        <InfoItem
                                            label="Age"
                                            value={quote?.dob ? moment().diff(quote?.dob, 'years').toString() : "-"}
                                        />
                                        <InfoItem
                                            label='Occupation'
                                            value={quote?.occupation ?? "-"}
                                        />
                                    </APRow>
                                    <APRow crossAxisAlignment="start" gap="8px">
                                        <InfoItem
                                            label='Smoke/Tobacco'
                                            value={quote?.isSmoker ? "Yes" : "No"}
                                        />
                                        <InfoItem
                                            label='Annual Income'
                                            value={quote?.annualIncome ? quote?.annualIncome.toString() : "-"}
                                        />
                                    </APRow>
                                    <APRow mainAxisSize="min" crossAxisAlignment="start" gap="16px">
                                        {
                                            selectedProduct?.policyBrochureFile?.url &&
                                            <APRow onClick={() => saveAs(selectedProduct!.policyBrochureFile?.url)} mainAxisSize="min" crossAxisAlignment="start" gap="8px"  >
                                                <APIcon icon="download" size={20} color={APPalette['info-300']} />
                                                <APText variant="link-medium">Policy Brochure</APText>
                                            </APRow>
                                        }
                                        <APRow
                                            onClick={async () => {
                                                if (!token || !quote) return;
                                                try {
                                                    const url = await getIt(InsuranceTermServices).getPremiumPdf(token);
                                                    const provider = quote.provider;
                                                    if (['bajaj', 'hdfc'].includes(provider ?? "")) {
                                                        const loadUrl = await fetch(url);
                                                        const blob = await loadUrl.blob();
                                                        const urlCreator = window.URL || window.webkitURL;
                                                        const link = urlCreator.createObjectURL(blob);
                                                        window.open(link, "_blank");
                                                        return;
                                                    }
                                                    window.open(url, "_blank");

                                                } catch (error) {
                                                    handleErrorMessage(error)
                                                }
                                            }}
                                            mainAxisSize="min" crossAxisAlignment="start" gap="8px">
                                            <APIcon icon="download" size={20} color={APPalette['info-300']} />
                                            <APText variant="link-medium">Benefit Illustration</APText>
                                        </APRow>
                                    </APRow>
                                </APColumn>
                            </APCard>

                            {
                                quote &&
                                <PriceBreakdownCard
                                    product={selectedProduct!}
                                    premium={quote?.premium}
                                    paymentFrequency={quote?.paymentFrequency}
                                    paymentMode={quote?.getPaymentMode?.()} riders={quote?.riders} />
                            }

                            {quote && !quote.userId && <APAsyncButton size='large' onClick={async () => {
                                try {
                                    if (!token) {
                                        return;
                                    }
                                    const res = await getIt(InsuranceTermServices).leadSignUp(token);
                                    window.open(res, "_blank");
                                } catch (error) {
                                    handleErrorMessage(error);
                                }
                            }}>Sign up with AssetPlus</APAsyncButton>}
                        </APColumn>
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    )
}
function InfoItem({ label, value }: { label: string, value: string }) {
    return <APColumn crossAxisAlignment="start" gap="4px">
        <APText variant="paragraph-xsmall" color={APPalette["grey-500"]}>{label}</APText>
        <APText variant="h4">{value}</APText>
    </APColumn>;
}


function getFrequencyText(quote: QuoteModel) {
    if (quote.policyPremiumTerm == 0 || !quote.paymentFrequency) {
        return "";
    }
    if (quote.policyPremiumTerm == 1) {
        return "for one time";
    }
    switch (quote.paymentFrequency) {
        case "monthly":
            return "every month for a term of";
        case "halfYearly":
            return "every six months for a term of";
        case "quarterly":
            return "every three months for a term of";
        case "annually":
            return "every term for";
        default:
            return "";
    }
}