import { useContext, useEffect, useRef, useState } from 'react';
import { showConfirmationDialog } from '../dialogs';
import ShowImage from '../helpers/ShowImage';
import { APFormContext } from './APForm';
import { APFormControl, APFormFieldItem } from './Common';

export interface APFormFieldImageProps {
    control?: APFormControl,
    validator?: (src?: string) => string | null | Promise<string | null>,
    onChange?: (src?: string, file?: File) => void,
    src?: string,
    label?: string,
    alt?: string,
    padding?: string,
    margin?: string,
    aspectRatio?: number,
    /**
     * @default default
     */
    variant?: "default" | "avatar",
    accept?: string
}

export function APFormFieldImage({ src, control, validator, onChange, label, alt, padding, margin, aspectRatio, variant = "default", accept }: APFormFieldImageProps) {

    let inputRef = useRef<any>(null);

    const [base64String, setBase64String] = useState<string | undefined>(src);
    const [error, setError] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File>();

    var control2: APFormControl | undefined = useContext(APFormContext);

    if (control !== undefined) {
        control2 = control;
    }

    async function checkValues(): Promise<boolean> {
        var errorMessage = null;
        if (validator)
            errorMessage = await validator(base64String);
        setError(errorMessage)
        if (errorMessage) {
            inputRef.current?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
        return !errorMessage;
    }

    var fieldItem: APFormFieldItem = {
        validate: checkValues,
        reset: () => {
            setBase64String("");
            // updated file
            setSelectedFile(undefined);
        }
    }

    useEffect(() => {
        if (control2 !== undefined)
            control2.fields.add(fieldItem);
        return () => {
            if (control2 !== undefined)
                control2.fields.delete(fieldItem);
        };
    });

    useEffect(() => {
        (async () => {
            if (validator && error !== null)
                setError(await validator(base64String));
            if (onChange)
                onChange(base64String, selectedFile)
        })();
    }, [base64String])

    return (
        <ShowImage
            handleRemove={async () => {
                try {
                    if (await showConfirmationDialog(`Remove ${label}`, `Are you sure you want to remove ${label}?`)) {
                        setBase64String("");
                        // updated file
                        setSelectedFile(undefined);
                    }
                } catch (error) { }
            }}
            ref={inputRef}
            upload={(base64, file) => {
                setBase64String(base64);
                setSelectedFile(file);
            }}
            src={base64String}
            alt={alt}
            error={error}
            label={label}
            padding={padding}
            margin={margin}
            aspectRatio={aspectRatio}
            variant={variant}
            accept={accept}
        />
    )
}

