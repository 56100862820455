import { Tooltip } from '@mui/material';
import { APColumn, APImage, APPalette, APRow, APSizedBox, APText } from 'ap-components';
import APSlider from 'ap-components/src/forms/APSlider';
import React from 'react';

export type CalculatorDataType = "amount" | "percentage" | "age";

interface CalculatorInputProps {
    label: string;
    value: number;
    /**
     * @default 1
     */
    sliderMin?: number;
    /**
     * @default 100
     */
    sliderMax?: number;
    /**
     * @default 1
     */
    sliderStep?: number;
    /**
     * @default 1
     */
    inputMin?: number;
    /**
     * @default 100
     */
    inputMax?: number;
    dataType?: CalculatorDataType
    onChange?: (value: number) => void;
}

interface CustomInputProps {
    value: number;
    onChange: (value: number) => void;
    style?: React.CSSProperties;
    dataType?: CalculatorDataType;
    inputMin: number
    inputMax: number
}

export default function CalculatorInput({ label, value, sliderMax = 100, sliderMin = 1, inputMax = 100, inputMin = 1, dataType, onChange, sliderStep = 1 }: CalculatorInputProps) {
    return (
        <APColumn crossAxisAlignment='stretch'>
            <APRow mainAxisAlignment="spaceBetween"  >
                <APRow gap="3px" mainAxisAlignment='start' crossAxisAlignment="center" mainAxisSize="min" >
                    <APText variant="h5" style={{ color: APPalette.black }}>{label.toUpperCase()}</APText>
                </APRow>

                <CustomInput
                    value={value}
                    inputMin={inputMin}
                    inputMax={inputMax}
                    onChange={onChange!}
                    dataType={dataType}
                />

            </APRow >
            <APSlider
                value={value}
                max={sliderMax}
                min={sliderMin}
                step={sliderStep}
                onChange={(event: any, value: number | number[]) => {
                    if (onChange) {
                        onChange(value as number)
                    }
                }}
            />
        </APColumn>
    )
}


function CustomInput({ value, onChange, dataType, inputMax, inputMin }: CustomInputProps) {

    const inputStyle: React.CSSProperties = {
        border: 'none',
        outline: 'none',
        background: APPalette["brand-100"],
        color: value < inputMin ? "red" : APPalette['brand-300'],
        fontWeight: 600,
        fontSize: "14px",
        textAlign: "center",
        cursor: "pointer"
    };

    function numberToString(val: number): string {
        switch (dataType) {
            case "amount":
                return currencyFormatting(val)
            default:
                return val.toString();
        }

    }


    function stringToNumber(str: string) {
        let numericValue: number;
        let result = parseInt(str.split(',').join(''));

        if (isNaN(result)) {
            result = 0
        }
        switch (dataType) {
            case "amount":
                numericValue = result;
                break;
            default:
                numericValue = result;
                break;
        }

        if (inputMax !== undefined && numericValue > inputMax) {
            numericValue = inputMax;
        }
        // if (inputMin !== undefined && numericValue < 0) {
        //     numericValue = inputMin;
        // }

        onChange(numericValue);

    }
    const _val = numberToString(value);

    // compute prefix and suffix here from dataType
    let prefixSymbol, suffixSymbol;

    switch (dataType) {
        case "amount":
            prefixSymbol = "₹";
            break;
        case "age":
            suffixSymbol = "years";
            break;
        case "percentage":
            suffixSymbol = "％";
            break;
        default:
            prefixSymbol = undefined;
            suffixSymbol = undefined;
            break;
    }

    return (
        <APRow mainAxisSize='min' style={{ padding: "4px 8px", fontWeight: 600, background: APPalette["brand-100"], borderRadius: "4px", color: APPalette['brand-300'] }}>
            {
                value < inputMin &&
                <>
                    <Tooltip
                        style={{ height: "17px" }}
                        placement="top"
                        title={`Value can not be less than ${inputMin}`}
                    >
                        <span>{<APImage height="15px" width="15px" src="/icons/icon-circle.svg" />}</span>
                    </Tooltip>
                    <APSizedBox width="4px" />
                </>
            }
            {prefixSymbol}
            <input
                size={_val.length ?? 1}
                value={_val}
                onChange={(event) => {
                    const value = event.target.value.trim();
                    const eventValue = value.replace(/\D/g, '');
                    stringToNumber(eventValue)

                }}
                style={inputStyle}
            />
            {suffixSymbol}
        </APRow>
    );
};

export function currencyFormatting(num: any) {
    num = Math.round(num * 100) / 100
    num = parseFloat(num).toFixed(2);
    num = num + '' || '';
    let n1 = num.split('.');
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n1
    return num
}