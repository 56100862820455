import { RouteComponentProps } from "@reach/router";
import { APCenter, APColumn, APExpanded, APImage, APSizedBox, APText } from "ap-components";
import React from "react";

export default function WaitingResponse  (props:RouteComponentProps) {
    return (
      <APCenter>
          <APColumn  >
          <APImage src={"/insurance/icon-waiting.png"} />
              <APSizedBox height='8px'/>
              <APExpanded>

              <APText variant="normal" style={{fontSize:'16px',textAlign:'center',padding:'0 25px 0 20px'}}>
              Please wait while you are redirected to  Payment gateway
              </APText>
              </APExpanded>
            
          </APColumn>
      </APCenter>
    )
  }