import { Type } from "class-transformer";
import { RiderPremium } from "./Quote";

export class StrapiInsuranceTerm {
    _id!: string
    published_at!: Date
    @Type(() => Product)
    products!: Product[]
    @Type(() => TermBenefits)
    benefits!: TermBenefits[]
    @Type(() => Date)
    createdAt!: Date
    @Type(() => Date)
    updatedAt!: Date
    id!: string
}

export class Product {
    _id!: string
    provider!: string
    @Type(() => ProviderIcon)
    providerIcon!: ProviderIcon
    planName!: string
    discountCallout?: string
    maxCoverAge!: string
    claimSettlementRatio!: string
    termsAndConditions!: string
    @Type(() => Media)
    termsAndConditionsFile!: Media
    @Type(() => Media)
    policyBrochureFile!: Media
    @Type(() => Media)
    requiredDocumentFile!: Media
    @Type(() => UniqueSellingPoint)
    uniqueSellingPoint!: UniqueSellingPoint[]
    @Type(() => Highlights)
    highlightsCovered!: Highlights[]
    @Type(() => Highlights)
    highlightsUncovered!: Highlights[]
    policyBenefits!: Benefit[]
    @Type(() => AddOn)
    addOns!: AddOn[]
    faq!: string
    claimSettlementTime?: string
    operatingSince?: string
    getRiderPremiumWithShortTitle(ridersPremium: RiderPremium[]) {
        const formatterShortTitle = [];
        for (const rider of ridersPremium) {
            const matchRider = this.addOns.find(item => item.riderCode == rider.riderPlanCode);
            if (matchRider) {
                formatterShortTitle.push({ label: matchRider.shortTitle, value: rider.premium });
            }
            else {
                formatterShortTitle.push({ label: rider.riderPlanCode.toLocaleUpperCase(), value: rider.premium });
            }
        }
        return formatterShortTitle;
    }
}

class Media {
    url!: string
}

class UniqueSellingPoint {
    _id!: string
    text!: string
    id!: string
}

class TermBenefits {
    text!: string
    @Type(() => ProviderIcon)
    icon!: ProviderIcon
}

class Highlights {
    _id!: string
    title!: string
    subTitle!: string
    id!: string
}

class Benefit {
    icon!: ProviderIcon
    title!: string
    subtitle!: string
    learnMore!: string
}

class ProviderIcon {
    _id!: string
    url!: string
    provider!: string
    id!: string
}

export class AddOn {
    title!: string
    subtitle!: string
    learnMore!: string
    riderCode!: string
    shortTitle!: string
    disabled?: boolean
}