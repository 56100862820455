import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, APSwitcher, useDeviceType } from 'ap-components'
import saveAs from 'file-saver'
import html2canvas from 'html2canvas'
import { useRef, useState } from 'react'
import { APCharts } from '../../../Common/Charting'
import CalculatorFormulas, { getSipAmountFormula, lumpsumFormula } from '../CalculatorFormulas'
import CalculatorInput from '../CalculatorInput'
import { ChartValue, ScreenShot } from './Emi'

export default function GoalSetting() {

    const targetElementRef = useRef<HTMLDivElement>(null);
    const deviceType = useDeviceType()

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Goal Setting Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };

    const [initialValue, setInitialValue] = useState({
        investmentType: "Lumpsum",
        requiredAmount: 1000000,
        investmentPeriod: 5,
        sipReturnsRate: 12,
        inflationRate: 6
    })

    var optionLumpsum;
    var optionSip;
    var lumpsumOutPut;
    var lumpsumRequired;
    var inflationAdjustedGoal;
    var gains;
    var sipOutput;
    var totalInvested;
    var maturityValue;
    var inflationAdjusted;
    var gain;
    var monthlySavingsAmount;

    if (initialValue.investmentType.toLowerCase() == "lumpsum") {
        lumpsumOutPut = CalculatorFormulas.goalCalculatorLumpsum(initialValue);
        lumpsumRequired = lumpsumOutPut?.lumpsumRequired
        inflationAdjustedGoal = lumpsumOutPut?.inflatedAmount;
        gains = lumpsumOutPut?.gains
        optionLumpsum = {
            legend: {
                orient: 'horizontal',
                position: "center",
                data: [
                    {
                        name: 'Gains',
                    },
                    {
                        name: 'Lumpsum Required'
                    }

                ]
            },
            tooltip: {
                trigger: 'item',
            },

            title: {
                text: `${inflationAdjustedGoal?.currency(2, true)}`,
                left: 'center',
                top: 'center'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '60%'],
                    avoidLabelOverlap: false,
                    startAngle: 180,
                    tooltip: {
                        valueFormatter: function (value: number) {
                            return value.currency();
                        }
                    },
                    label: {
                        show: true,
                        formatter(param: any) {
                            return (param.value.currency(2, true))
                        }
                    },
                    data: [
                        {
                            value: lumpsumRequired,
                            name: 'Lumpsum Required',
                            itemStyle: {
                                color: '#268EFF'
                            }
                        },
                        {
                            value: gains,
                            name: 'Gains',
                            itemStyle: {
                                color: '#5DD27A'
                            }
                        },
                    ]
                }
            ]
        };

    }


    if (initialValue.investmentType.toLowerCase() == "sip") {

        sipOutput = CalculatorFormulas?.goalCalculatorSip(initialValue);
        inflationAdjusted = lumpsumFormula(initialValue.requiredAmount, initialValue.inflationRate, initialValue.investmentPeriod);
        monthlySavingsAmount = getSipAmountFormula(inflationAdjusted, initialValue.sipReturnsRate, initialValue.investmentPeriod);
        totalInvested = sipOutput[sipOutput.length - 1]?.totalInvestedAmount;
        maturityValue = sipOutput[sipOutput.length - 1]?.maturityValue;
        gain = sipOutput[sipOutput.length - 1]?.gain
        optionSip = {
            legend: {
                data: ['Gains', 'Total Investment'],
                position: "center",
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'none',
                },
            },
            grid: {
                bottom: "15%",
                right: "1%",
                left: "11%"
            },
            xAxis: [
                {
                    type: 'category',
                    data: sipOutput?.map(year => year?.time),
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            formatter: (v: any) => {
                                return "Year " + v.value;
                            }
                        }
                    },
                    axisLabel: {
                        formatter: 'Year {value}'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    axisLabel: {
                        formatter: (v: number) => {
                            return v.currency(1, true);
                        }
                    }
                },
            ],
            series: [
                {
                    name: 'Total Investment',
                    type: 'bar',
                    stack: 'maturity',
                    itemStyle: {
                        color: '#268EFF'
                    },
                    tooltip: {
                        valueFormatter: function (value: number) {
                            return value.currency();
                        }
                    },
                    data: sipOutput.map(sum => sum?.totalInvestedAmount),
                },
                {
                    name: 'Gains',
                    type: 'bar',
                    stack: 'maturity',
                    itemStyle: {
                        color: '#5DD27A'
                    },
                    tooltip: {
                        itemStyle: {
                            color: '#5DD27A'
                        },
                        valueFormatter: function (value: number) {
                            return value.currency();
                        }
                    },
                    data: sipOutput.map(gain => gain?.gain),
                },
            ]
        }

    }



    return (
        <div style={{ padding: "8px" }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween" >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>Goal Setting Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >
                        <APImage src="/icons/icon-camera.svg" />
                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />
            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}   >

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>

                        <APRow mainAxisAlignment="spaceBetween"   >
                            <APRow gap="3px" mainAxisAlignment='start' crossAxisAlignment="center" mainAxisSize="min" >
                                <APText variant="h3" style={{ color: APPalette['grey-600'] }}>I want to invest via</APText>
                            </APRow>
                            <APSwitcher
                                options={["SIP", "Lumpsum"]}
                                onSelect={(type) => {
                                    setInitialValue({ ...initialValue, investmentType: type })
                                }}
                                initial={initialValue.investmentType}
                                variant="primary" />
                        </APRow >

                        <APSizedBox height='24px' />

                        <CalculatorInput
                            label="Goal Amount"
                            dataType="amount"
                            value={initialValue.requiredAmount}
                            sliderMax={10000000}
                            sliderMin={50000}
                            sliderStep={50000}
                            inputMax={1000000000}
                            inputMin={100}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, requiredAmount: value });
                            }}
                        />

                        <APSizedBox height='24px' />

                        <CalculatorInput
                            label="Duration"
                            dataType="age"
                            value={initialValue.investmentPeriod}
                            sliderMax={50}
                            sliderMin={1}
                            sliderStep={1}
                            inputMin={1}
                            inputMax={50}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, investmentPeriod: value });
                            }}
                        />

                        <APSizedBox height='24px' />

                        <CalculatorInput
                            label="Expected Returns"
                            dataType="percentage"
                            value={initialValue.sipReturnsRate}
                            sliderMax={30}
                            sliderMin={1}
                            sliderStep={1}
                            inputMin={1}
                            inputMax={30}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, sipReturnsRate: value });
                            }}
                        />

                        <APSizedBox height='24px' />

                        <CalculatorInput
                            label="Inflation Rate"
                            dataType="percentage"
                            value={initialValue.inflationRate}
                            sliderMax={20}
                            sliderMin={0}
                            sliderStep={1}
                            inputMin={0}
                            inputMax={20}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, inflationRate: value });
                            }}
                        />
                    </div>
                </APExpanded>

                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot label="I want to invest as" value={initialValue.investmentType} />
                        <APSizedBox height='24px' />
                        <ScreenShot label="Goal Amount" value={initialValue.requiredAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Duration" value={initialValue.investmentPeriod} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="percentage" label="Expected Returns" value={initialValue.sipReturnsRate} />
                        <APSizedBox height='24px' />
                        <ScreenShot dataType="percentage" label="Inflation Rate" value={initialValue.inflationRate} />
                    </div>
                </APExpanded>

                <APSizedBox width="32px" />

                <APExpanded key={`${initialValue.investmentType}_chart_key`} flex={3}>
                    <APColumn crossAxisAlignment="stretch" style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }} >

                        <APCharts key={`${initialValue.investmentType}_chart_key`} height='320px' option={initialValue.investmentType.toLowerCase() == "sip" ? optionSip : optionLumpsum} />
                        {
                            initialValue.investmentType == "Lumpsum"
                                ?
                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "8px", justifyContent: "space-between" }}>
                                    < ChartValue style={{ color: "#268EFF" }} label={"Lumpsum Required"} value={lumpsumRequired ?? 0} />
                                    <APSizedBox width='24px' />
                                    < ChartValue label={"Inflation Adjusted Goal"} value={inflationAdjustedGoal ?? 0} />
                                    <APSizedBox width='24px' />
                                    < ChartValue style={{ color: "#5DD27A" }} label={"Gains"} value={gains ?? 0} />
                                </div>
                                :
                                <APRow crossAxisAlignment="start" mainAxisAlignment='spaceBetween'>
                                    <ChartValue label={"Total Invested"} value={totalInvested ?? 0} style={{ color: "#7978DE" }} />
                                    <APSizedBox width="36px" />
                                    <APSizedBox>
                                        <APColumn gap="6px"  >
                                            <APText color={APPalette['grey-600']} variant='paragraph-small'>Inflation Adjusted Goal</APText>
                                            <APColumn crossAxisAlignment="center">
                                                <APText center={true} variant='h2'>{inflationAdjusted?.currency(2, true)}</APText>
                                            </APColumn>
                                            <APSizedBox height="16px" />
                                            <APText color={APPalette['grey-600']} variant='paragraph-small'>Monthly SIP required to achieve the goal</APText>
                                            <APColumn crossAxisAlignment="center">
                                                <APText center={true} variant='h2'>{monthlySavingsAmount?.currency(2, true)}</APText>
                                            </APColumn>
                                        </APColumn>
                                    </APSizedBox>
                                    <APSizedBox width="36px" />
                                    <ChartValue label={"Gain"} value={gain ?? 0} style={{ color: "#5DD27A" }} />
                                </APRow>
                        }
                    </APColumn>
                </APExpanded>
            </div>
        </div>

    )
}
