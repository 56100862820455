import { CircularProgress } from "@mui/material";
import { APDialogCard, APText } from "../elements";
import { launchDialog } from "./DialogHelper";
import { APCenter, APRow, APSizedBox } from "../layout";

export async function showLoadingDialog<T>(promise: () => Promise<T>): Promise<T> {

    return await launchDialog((deferred) => {

        promise().then((result) => {
            deferred.resolve(result);
        }).catch((error) => {
            deferred.reject(error);
        })

        return <APSizedBox height="70px" width="180px" >
            <APCenter>
                <APRow mainAxisSize="min" crossAxisAlignment="center" gap="20px">
                    <APText variant="paragraph-medium">Please wait</APText> <CircularProgress size={'20px'} />
                </APRow>
            </APCenter>
        </APSizedBox>
    }, { disableClose: true })

}