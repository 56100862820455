import { RouteComponentProps } from '@reach/router'
import { APCenter, APColumn, APImage, APSizedBox, APText } from 'ap-components'
import React from 'react'

export default function FailureResponse  (props:RouteComponentProps) {
  return (
    <APCenter>
        <APColumn>
        <APImage src={"/insurance/icon-failed.png"} />
            <APSizedBox height='8px'/>
            <APText variant="normal" style={{fontSize:'16px',textAlign:'center'}}>
                  Payment failed
            </APText> 
        </APColumn>
    </APCenter>
  )
}
