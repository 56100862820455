import { APExpanded, APPalette } from "ap-components";
import React from "react";
import WebStory from "./WebStory";
import { RouteComponentProps, Router } from "@gatsbyjs/reach-router";

export default function WebStoryRoutes(route: RouteComponentProps) {
    return (
        <APExpanded>
            <Router style={{
                height: "100svh", display: "flex", backgroundColor: APPalette["grey-100"],
            }}>
                <WebStory path="/" default />
            </Router>
        </APExpanded>
    );
}