import { APColumn, APRow, APText, APPalette, APButton, APImage, APSizedBox, APExpanded, useDeviceType } from 'ap-components';
import { useRef, useState } from 'react'
import CalculatorInput from '../CalculatorInput';
import CalculatorFormulas from '../CalculatorFormulas';
import { LabelAndValue } from './Retirement';
import html2canvas from 'html2canvas';
import saveAs from 'file-saver';
import { ScreenShot } from './Emi';
import { APCharts } from '../../../Common/Charting';



export default function Education() {

    const targetElementRef = useRef<HTMLDivElement>(null);

    const in1Ref = useRef<HTMLDivElement>(null);
    const in2Ref = useRef<HTMLDivElement>(null);
    const in3Ref = useRef<HTMLDivElement>(null);
    const in4Ref = useRef<HTMLDivElement>(null);

    async function captureScreenshot() {
        if (targetElementRef.current) {
            try {
                in1Ref.current?.style.setProperty("display", "none", "important")
                in2Ref.current?.style.setProperty("display", "block", "important")
                in3Ref.current?.style.setProperty("display", "none", "important")
                in4Ref.current?.style.setProperty("display", "block", "important")

                const canvas = await html2canvas(targetElementRef.current);
                const url = canvas.toDataURL();
                saveAs(url, "Education Calculator")

                in1Ref.current?.style.setProperty("display", "block", "important")
                in2Ref.current?.style.setProperty("display", "none", "important")
                in3Ref.current?.style.setProperty("display", "block", "important")
                in4Ref.current?.style.setProperty("display", "none", "important")
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
    };


    const [initialValue, setInitialValue] = useState({
        requiredAmount: 2000000,
        currentAge: 5,
        targetAge: 17,
        currentSavingsAmount: 500000,
        inflationRate: 6,
        expectedReturns: 12
    })
    var output = CalculatorFormulas.educationPlanner(initialValue);
    var additionalSavings;
    var currentSavingAmount = output.currentSavingsAmount
    var requiredAmountForEdu = output.requiredInflatedAmount

    if (output.additionalSavings < 0) {
        additionalSavings = 0
    } else {
        additionalSavings = output.additionalSavings
    }

    var monthlySip = output.monthlySip
    var totalAmount = (additionalSavings + currentSavingAmount)
    let deviceType = useDeviceType();


    var option = {
        legend: {
            orient: 'horizontal',
            position: "center"
        },
        tooltip: {
            trigger: 'item',
        },
        title: {
            text: `${totalAmount.currency(2, true)}`,
            left: 'center',
            top: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '60%'],
                avoidLabelOverlap: false,
                startAngle: 180,

                tooltip: {
                    valueFormatter: function (value: number) {
                        return value.currency();
                    }
                },
                label: {
                    show: true,
                    formatter(param: any) {
                        return (param.value.currency(2, true))
                    }
                },

                data: [
                    {
                        value: additionalSavings,
                        name: 'Additional Savings Required',
                        itemStyle: {
                            color: '#268EFF'
                        }

                    },
                    {
                        value: currentSavingAmount,
                        name: 'Current Savings Value on Retirement',
                        itemStyle: {
                            color: '#5DD27A'
                        }
                    },
                ]
            }
        ]
    };
    return (
        <div style={{ padding: "8px", }} ref={targetElementRef}>
            <APRow mainAxisAlignment="spaceBetween" >
                <APText variant="h2" style={{ color: APPalette["grey-700"] }}>Education Calculator</APText>
                <div ref={in4Ref} style={{ height: "16px", display: "none" }}>
                    <APImage src='/assetplus/aplight.svg' height="16px" />
                </div>
                <div ref={in3Ref}>
                    <APButton
                        type="secondary"
                        size="medium"
                        style={{ opacity: 1 }}
                        onClick={captureScreenshot}
                    >

                        <APImage src="/icons/icon-camera.svg" />

                        <APText variant="button-small" style={{ color: APPalette["brand-300"], opacity: 1 }}>Take Snapshot</APText>
                    </APButton>
                </div>
            </APRow>
            <APSizedBox height="16px" />

            <div style={{ display: "flex", flexDirection: deviceType == "mobile" ? "column-reverse" : "row" }}  >


                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "flex", flexDirection: "column" }} ref={in1Ref}>
                        <CalculatorInput
                            label="Amount required for education"
                            dataType="amount"
                            value={initialValue.requiredAmount}
                            sliderStep={50000}
                            sliderMax={5000000}
                            inputMax={100000000}
                            inputMin={100}
                            sliderMin={50000}
                            onChange={(value) => {
                                var monthlyExpenses = value;
                                setInitialValue({ ...initialValue, requiredAmount: monthlyExpenses });
                            }}

                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Current Age of child"
                            dataType="age"
                            value={initialValue.currentAge}
                            sliderStep={1}
                            sliderMax={20}
                            inputMax={20}
                            inputMin={0}
                            sliderMin={0}
                            onChange={(value) => {
                                let currentAge = value;
                                let retirementAge = currentAge + 1;

                                retirementAge = retirementAge > 61 ? 60 : retirementAge

                                setInitialValue({ ...initialValue, currentAge: currentAge, targetAge: currentAge >= initialValue.targetAge ? retirementAge : initialValue.targetAge })

                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Child age for higher education"
                            dataType="age"
                            sliderStep={1}
                            value={initialValue.targetAge}
                            sliderMax={25}
                            inputMax={30}
                            inputMin={16}
                            sliderMin={16}
                            onChange={(value) => {
                                var targetAge = value;
                                setInitialValue({
                                    ...initialValue,
                                    targetAge: targetAge,
                                    currentAge: targetAge > 0 ? targetAge <= initialValue.currentAge ? targetAge - 1 : initialValue.currentAge : 0,
                                })
                            }}
                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Current Savings"
                            sliderStep={50000}
                            dataType="amount"
                            value={initialValue.currentSavingsAmount}
                            sliderMax={5000000}
                            inputMax={1000000000}
                            sliderMin={0}
                            inputMin={0}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, currentSavingsAmount: value })
                            }}

                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Inflation Rate"
                            dataType="percentage"
                            value={initialValue.inflationRate}
                            sliderStep={1}
                            sliderMin={0}
                            sliderMax={20}
                            inputMax={20}
                            inputMin={0}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, inflationRate: value })
                            }}

                        />

                        <APSizedBox height="24px" />

                        <CalculatorInput
                            label="Expected Return Rate"
                            dataType="percentage"
                            value={initialValue.expectedReturns}
                            sliderMin={1}
                            sliderStep={1}
                            sliderMax={30}
                            inputMax={30}
                            inputMin={1}
                            onChange={(value) => {
                                setInitialValue({ ...initialValue, expectedReturns: value })
                            }}
                        />
                    </div>
                </APExpanded>
                <APExpanded flex={2}>
                    <div style={{ paddingTop: "16px", display: "none", flexDirection: "column" }} ref={in2Ref}>
                        <ScreenShot label="Amount Required for education" value={initialValue.requiredAmount.currency(2, true)} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Current Age of child" value={initialValue.currentAge} />
                        <APSizedBox height="24px" />
                        <ScreenShot dataType="age" label="Child age for higher education" value={initialValue.targetAge} />
                        <APSizedBox height='24px' />
                        <ScreenShot label="Current Savings" value={initialValue.currentSavingsAmount.currency(2, true)} />
                        <APSizedBox height='24px' />
                        <ScreenShot dataType='percentage' label="Inflation Rate" value={initialValue.inflationRate} />
                        <APSizedBox height='24px' />
                        <ScreenShot dataType='percentage' label="Expected Return Rate" value={initialValue.expectedReturns} />
                    </div>
                </APExpanded>
                <APSizedBox width="32px" />

                <APExpanded flex={3} >
                    <APColumn crossAxisAlignment="stretch" style={{ padding: "16px", background: APPalette["grey-50"], borderRadius: "16px" }} >
                        <APCharts height="320px" option={option} />
                        <APColumn mainAxisAlignment="start"  >
                            < LabelAndValue
                                label={"Retirement amount required as per current expenses"}
                                value={requiredAmountForEdu}
                            />
                            <APSizedBox height="16px" />
                            {
                                requiredAmountForEdu < currentSavingAmount ?
                                    <APText variant="paragraph-small" style={{ color: APPalette["grey-600"] }}>
                                        Congratulations, your current savings cover all your education planning goals. You can keep investing for an unforeseen rise in tuition fees.
                                    </APText> :
                                    <>
                                        < LabelAndValue
                                            label={"Additional Saving Required"}
                                            value={additionalSavings}
                                        />

                                        <APSizedBox height="16px" />

                                        < LabelAndValue
                                            label={"Achievable by a monthly SIP of"}
                                            value={monthlySip}
                                            style={{ color: APPalette["info-300"] }}
                                        />
                                    </>
                            }
                        </APColumn>
                    </APColumn>
                </APExpanded>
            </div>
        </div>

    )
}
