import { navigate, NavigateOptions } from "@gatsbyjs/reach-router"

export function launchPage(to: string, options?: NavigateOptions<{}>) {
    setTimeout(() => { navigate(to, options) })
}
export function convertSum(input: string) {
    if (input === '' || typeof input !== 'string') {
        return;
    }
    // Remove commas and convert to a numeric value
    const number = parseFloat(input.replace(/,/g, ''));

    if (isNaN(number)) {
        return 'Invalid input';
    }

    if (number < 1000) {
        return number.toString();
    }

    if (number >= 10000000) {
        const crore = (number / 10000000).toFixed(0);
        return `₹${crore} crore${parseFloat(crore) > 1 ? 's' : ''}`;
    } else if (number >= 100000) {
        const lakh = (number / 100000).toFixed(0);
        return `₹${lakh} lakh${parseFloat(lakh) > 1 ? 's' : ''}`;
    } else if (number >= 1000) {
        const thousand = (number / 1000).toFixed(0);
        return `₹${thousand} thousand${parseFloat(thousand) > 1 ? 's' : ''}`;
    } else {
        return number.toString();
    }
}
