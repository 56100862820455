import { useCountDown, useAPForm, APForm, APSizedBox, APColumn, APText, APFormFieldText, APRow, APFormSubmit } from "ap-components";
import { useState, useEffect } from "react";

export default function OTPVerification({ onGenerate, onVerify, description }: { onGenerate: () => Promise<void>, onVerify: (otp: string) => Promise<void>, description?: string }) {
    let [timeLeft, { start }] = useCountDown(30000, 1000);
    let [form] = useState({ otp: "" });
    let control = useAPForm();

    async function generateOTPAndStartTimer() {
        await onGenerate();
        start();
    }

    useEffect(() => {
        generateOTPAndStartTimer();
    }, [])

    let secLeft = timeLeft / 1000

    return (
        <APForm control={control}>
            <APColumn crossAxisAlignment="stretch">
                <APSizedBox height="8px" />
                {
                    description &&
                    <>
                        <APText>{description}</APText>
                        <APSizedBox height="16px" />
                    </>
                }
                <APFormFieldText
                    label='OTP'
                    onChanged={(value) => form.otp = value}
                    validator={(value) => {
                        if (value.length === 0) {
                            return "OTP required";
                        }

                        if (value.length !== 4) {
                            return "Invalid OTP"
                        }

                        return null;
                    }}
                />
                <APRow mainAxisAlignment="center">
                    <APFormSubmit onClick={async () => {
                        if (await control.validate()) {
                            await onVerify(form.otp);
                        }
                    }}>
                        Verify
                    </APFormSubmit>
                </APRow>
                <APSizedBox height="24px" />
                <APRow style={{ fontSize: "14px", lineHeight: "20px" }} mainAxisAlignment="center">
                    <APText>Didn't receive? </APText> &nbsp;
                    {
                        (secLeft > 0) ?
                            <APText style={{ color: "gray", textDecoration: "underline" }}>Resend OTP (0:{secLeft < 10 ? `0${secLeft}` : secLeft})</APText>
                            :
                            <APText onClick={generateOTPAndStartTimer} style={{ color: "#4994EC", textDecoration: "underline" }}>Resend OTP</APText>
                    }
                </APRow>
            </APColumn>
        </APForm>
    )
}