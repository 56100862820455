import { useLocation } from "@gatsbyjs/reach-router";
import { APTypography } from "ap-components/src/utils/APTypography";
import Activation2Model from "../../Models/Activation2Model";
import { addParamsToCurrentUrl, launchUrl } from "../DigilockerKYC/common";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { useEffect, useState } from "react";
import { JourneyStepApp, JourneySectionApp, getIt, APColumn, APSizedBox, APImage, APText, APTag, APRow, APExpanded, APCenter } from "ap-components";
import { CircularProgress } from "@mui/material";

export default function DigiLockerStepperFunction({ activationData, updateActivationData }: { activationData: Activation2Model, updateActivationData: (activationData: Activation2Model) => void }): JourneyStepApp | JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [isFetchingUpdate, setisFetchingUpdate] = useState<boolean>(false);

    if (!activationData.activationWorkflowProgress?.includes('DigiLocker')) {
        let url = new URL(window.location.href);
        let isRedirected = searchParams.get("kyc_digilocker_stage") == 'redirected';
        let isDigilockerComeback = searchParams.get("kyc_step") == 'Digilocker';
        if (isRedirected || isDigilockerComeback) {
            ["kyc_digilocker_stage", "kyc_step"].forEach(param => url.searchParams.delete(param));
            launchUrl(url.href);
        }
    }

    async function autoRefetchDigilockerStatus() {
        try {
            const updatedActivationData = await getIt(MutualFundApiServices).fetchDigilockerStatus2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian' });
            updateActivationData(updatedActivationData);
            setisFetchingUpdate(false);
        } catch (error) {
            setisFetchingUpdate(false);
            throw new Error("Digilocker workflow not complete");
        }
    }

    useEffect(() => {
        if (activationData.activationWorkflowProgress == 'DL' && ["INITIATED", "PENDING"].includes(activationData.firstHolderDigiLockerWorkflowComplete ?? "")) {
            setisFetchingUpdate(true);
            autoRefetchDigilockerStatus();
        }
    }, [])


    if (activationData.holdingType == 'SI') {
        return ({
            title: "DigiLocker",
            description: "",
            code: "DL",
            form: (
                isFetchingUpdate ?
                    <APExpanded>
                        <APCenter>
                            <CircularProgress />
                        </APCenter>
                    </APExpanded> :
                    <APColumn crossAxisAlignment="start">
                        <APSizedBox height='16px' />
                        <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='32px'>
                            <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                            <APImage width={114.738} height={28.069} src='img/DigiLocker_Logo.svg' />
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please keep the following ready for KYC:</APText>
                                <APText style={APTypography["paragraph-small"]}>• Image of your signature</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photograph of the applicant</APText>
                                <APText style={APTypography["paragraph-small"]}>• Phone number linked to Aadhaar</APText>
                                <APText style={APTypography["paragraph-small"]}>• Proof of Identity: PAN Image</APText>
                            </APColumn>
                            {(activationData.firstHolderDigiLockerWorkflowComplete ?? '') === 'APPROVED' && (<APTag variant="positive">You have completed the process on DigiLocker. Click on Next to continue</APTag>)}
                            <APRow gap="4px">
                                <APImage width={16} height={16} src='icons/icon_safe.svg' />
                                <APText style={APTypography["paragraph-small"]}>Your data is 100% safe and secure</APText>
                            </APRow>
                        </APColumn>
                        <APSizedBox height='16px' />
                    </APColumn>
            ),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                if ((activationData.taxStatus != '02' && activationData.firstHolderDigiLockerWorkflowComplete == 'APPROVED') || (activationData.taxStatus == '02' && activationData.guardianDigiLockerWorkflowComplete == 'APPROVED')) {
                    updateActivationData({ ...activationData, activationWorkflowProgress: "PD" });
                    return;
                }
                if ((activationData.taxStatus != '02' && ["INITIATED", "PENDING"].includes(activationData.firstHolderDigiLockerWorkflowComplete ?? '')) || (activationData.taxStatus == '02' && ["YET TO INITIATE", "INITIATED", "PENDING"].includes(activationData.guardianDigiLockerWorkflowComplete ?? ''))) {
                    try {
                        const updatedActivationData = await getIt(MutualFundApiServices).fetchDigilockerStatus2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian' });
                        if ((updatedActivationData.taxStatus != '02' && updatedActivationData.firstHolderDigiLockerWorkflowComplete != 'APPROVED') || (updatedActivationData.taxStatus == '02' && updatedActivationData.guardianDigiLockerWorkflowComplete != 'APPROVED')) {
                            throw new Error("Digilocker workflow not complete");
                        }
                        updateActivationData(activationData);
                        return;
                    } catch (error) {

                    }
                }

                let { url } = await getIt(MutualFundApiServices).getDigilockerUrl2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian', redirectUrl: addParamsToCurrentUrl({ kyc_digilocker_stage: "redirected", kyc_step: "Digilocker" }) });
                launchUrl(url);
                throw new Error("Redirecting to Digilocker");
            }
        });
    };

    const steps: JourneyStepApp[] = [];
    if (!activationData.firstHolderHasCkyc) {
        steps.push({
            title: "DigiLocker for Holder 1",
            description: "",
            code: "FH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='8px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        <APSizedBox height="32px" />
                        <APImage width={114.738} height={28.069} src='img/DigiLocker_Logo.svg' />
                        <APSizedBox height="32px" />
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>Please keep the following ready for KYC:</APText>
                            <APText style={APTypography["paragraph-small"]}>• Image of your signature</APText>
                            <APText style={APTypography["paragraph-small"]}>• Photograph of the applicant</APText>
                            <APText style={APTypography["paragraph-small"]}>• Phone number linked to Aadhaar</APText>
                            <APText style={APTypography["paragraph-small"]}>• Proof of Identity: PAN Image</APText>
                        </APColumn>
                        {(activationData.firstHolderDigiLockerWorkflowComplete ?? '') === 'APPROVED' && (<APTag variant="positive">You have completed the process on DigiLocker. Click on Next to continue</APTag>)}
                        <APRow gap="4px">
                            <APImage width={16} height={16} src='icons/icon_safe.svg' />
                            <APText style={APTypography["paragraph-small"]}>Your data is 100% safe and secure</APText>
                        </APRow>
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                if (activationData.taxStatus != '02' && activationData.firstHolderDigiLockerWorkflowComplete == 'APPROVED') {
                    updateActivationData({ ...activationData, activationWorkflowProgress: "PD" });
                    return;
                }
                if ((activationData.taxStatus != '02' && ["YET TO INITIATE", "PENDING"].includes(activationData.firstHolderDigiLockerWorkflowComplete ?? '')) || (activationData.taxStatus == '02' && ["YET TO INITIATE", "INITIATED", "PENDING"].includes(activationData.guardianDigiLockerWorkflowComplete ?? ''))) {
                    const updatedActivationData = await getIt(MutualFundApiServices).fetchDigilockerStatus2({ token: token!, holder: 'primary' });
                    if (updatedActivationData.firstHolderDigiLockerWorkflowComplete != 'APPROVED') {
                        throw new Error("Digilocker workflow not complete");
                    }
                    updateActivationData(activationData);
                    return;
                }

                let { url } = await getIt(MutualFundApiServices).getDigilockerUrl2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian', redirectUrl: addParamsToCurrentUrl({ kyc_digilocker_stage: "redirected", kyc_step: "Digilocker" }) });
                launchUrl(url);
                throw new Error("Redirecting to Digilocker");
            }
        });
    };

    if (!activationData.secondHolderHasCkyc) {
        steps.push({
            title: "DigiLocker for Holder 2",
            description: "",
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='8px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        <APSizedBox height="32px" />
                        <APImage width={114.738} height={28.069} src='img/DigiLocker_Logo.svg' />
                        <APSizedBox height="32px" />
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>Please keep the following ready for KYC:</APText>
                            <APText style={APTypography["paragraph-small"]}>• Image of your signature</APText>
                            <APText style={APTypography["paragraph-small"]}>• Photograph of the applicant</APText>
                            <APText style={APTypography["paragraph-small"]}>• Phone number linked to Aadhaar</APText>
                            <APText style={APTypography["paragraph-small"]}>• Proof of Identity: PAN Image</APText>
                        </APColumn>
                        {(activationData.secondHolderDigiLockerWorkflowComplete ?? '') === 'APPROVED' && (<APTag variant="positive">You have completed the process on DigiLocker. Click on Next to continue</APTag>)}
                        <APRow gap="4px">
                            <APImage width={16} height={16} src='icons/icon_safe.svg' />
                            <APText style={APTypography["paragraph-small"]}>Your data is 100% safe and secure</APText>
                        </APRow>
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: async () => {
                if (activationData.taxStatus != '02' && activationData.secondHolderDigiLockerWorkflowComplete == 'APPROVED') {
                    updateActivationData({ ...activationData, activationWorkflowProgress: "PD" });
                    return;
                }
                if ((activationData.taxStatus != '02' && ["YET TO INITIATE", "INITIATED", "PENDING"].includes(activationData.secondHolderDigiLockerWorkflowComplete ?? ''))) {
                    const updatedActivationData = await getIt(MutualFundApiServices).fetchDigilockerStatus2({ token: token!, holder: 'secondary' });
                    if ((updatedActivationData.secondHolderDigiLockerWorkflowComplete != 'APPROVED') || (updatedActivationData.taxStatus == '02' && updatedActivationData.guardianDigiLockerWorkflowComplete != 'APPROVED')) {
                        throw new Error("Digilocker workflow not complete");
                    }
                    updateActivationData(activationData);
                    return;
                }

                let { url } = await getIt(MutualFundApiServices).getDigilockerUrl2({ token: token!, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian', redirectUrl: addParamsToCurrentUrl({ kyc_digilocker_stage: "redirected", kyc_step: "Digilocker" }) });
                launchUrl(url);
                throw new Error("Redirecting to Digilocker");
            }
        });
    };

    return {
        code: "DL",
        title: "DigiLocker",
        description: activationData.name,
        steps
    };
};


export class PhotoRequestForHolderClass {
    token!: string;
    photoBase64!: string;
    holder!: 'primary' | 'secondary' | 'guardian'
}