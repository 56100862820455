
export class APChartColors {
    static "blue-50" = "rgb(229, 242, 255)"
    static "blue-100" = "rgb(191, 222, 255)"
    static "blue-200" = "rgb(153, 202, 255)"
    static "blue-300" = "rgb(102, 175, 255)"
    static "blue-400" = "rgb(38, 142, 255)"
    static "blue-500" = "rgb(0, 122, 255)"
    static "yellow-50" = "rgb(255, 250, 229)"
    static "yellow-100" = "rgb(255, 242, 191)"
    static "yellow-200" = "rgb(255, 235, 153)"
    static "yellow-300" = "rgb(255, 224, 102)"
    static "yellow-400" = "rgb(255, 214, 51)"
    static "yellow-500" = "rgb(255, 204, 0)"
    static "teal-50" = "rgb(238, 249, 254)"
    static "teal-100" = "rgb(214, 241, 254)"
    static "teal-200" = "rgb(189, 233, 253)"
    static "teal-300" = "rgb(156, 222, 252)"
    static "teal-400" = "rgb(123, 211, 251)"
    static "teal-500" = "rgb(90, 200, 250)"
    static "indigo-50" = "rgb(238, 238, 251)"
    static "indigo-100" = "rgb(213, 213, 245)"
    static "indigo-200" = "rgb(188, 187, 239)"
    static "indigo-300" = "rgb(155, 154, 230)"
    static "indigo-400" = "rgb(121, 120, 222)"
    static "indigo-500" = "rgb(88, 86, 214)"
    static "purple-50" = "rgb(247, 238, 252)"
    static "purple-100" = "rgb(235, 212, 247)"
    static "purple-200" = "rgb(223, 186, 242)"
    static "purple-300" = "rgb(207, 151, 235)"
    static "purple-400" = "rgb(191, 117, 229)"
    static "purple-500" = "rgb(175, 82, 222)"
    static "pink-50" = "rgb(255, 234, 238)"
    static "pink-100" = "rgb(255, 202, 212)"
    static "pink-200" = "rgb(255, 171, 187)"
    static "pink-300" = "rgb(255, 129, 153)"
    static "pink-400" = "rgb(255, 87, 119)"
    static "pink-500" = "rgb(255, 45, 85)"
    static "red-50" = "rgb(255, 235, 234)"
    static "red-100" = "rgb(255, 206, 203)"
    static "red-200" = "rgb(255, 177, 172)"
    static "red-300" = "rgb(255, 137, 131)"
    static "red-400" = "rgb(255, 98, 89)"
    static "red-500" = "rgb(255, 59, 48)"
    static "orange-50" = "rgb(255, 244, 229)"
    static "orange-100" = "rgb(255, 229, 191)"
    static "orange-200" = "rgb(255, 213, 153)"
    static "orange-300" = "rgb(255, 191, 102)"
    static "orange-400" = "rgb(255, 170, 51)"
    static "orange-500" = "rgb(255, 149, 0)"
    static "green-50" = "rgb(235, 249, 238)"
    static "green-100" = "rgb(204, 241, 213)"
    static "green-200" = "rgb(174, 233, 189)"
    static "green-300" = "rgb(133, 221, 155)"
    static "green-400" = "rgb(93, 210, 122)"
    static "green-500" = "rgb(52, 199, 89)"


}

