import { Checkbox, Divider } from '@mui/material';
import { useAPForm, APExpanded, APForm, APColumn, APFormFieldText, REGEX, APText, APSizedBox, APRow, APFormFieldDropdown, APFormSubmit, APDialogCard, handleErrorMessage, Deferred } from 'ap-components';
import { useState } from 'react'
import MasterTable from './Models.tsx/MasterTable';
import { SelfModel, SelfValidationModel } from './Models.tsx/SelfDeclarationModel';

export default function SelfValidationDialog({ deferred, details, name }: { deferred: Deferred<SelfValidationModel>, details: SelfModel, name: string }) {
    const [form] = useState<SelfValidationModel>({
        email: details.email ?? "",
        mobile: details.mobile ?? "",
        emailOwner: details.emailOwner ?? "",
        mobileOwner: details.mobileOwner ?? ""
    });

    const [confirmation, setConfirmation] = useState(false);

    var control = useAPForm();

    return (
        <APDialogCard title="Self Declaration" style={{ minWidth: "240px", maxWidth: "478px" }}>
            <APForm control={control}>
                <APColumn crossAxisAlignment="stretch" >
                    <APText variant='paragraph-small'>
                        These contact details will be used for OTP verification and sending approval links for future transactions of {name}
                    </APText>
                    <APFormFieldText
                        label="E-mail"
                        initialValue={form.email}
                        onChanged={(v) => {
                            form.email = v;
                        }}
                        validator={(v) => {
                            if (v.length == 0) {
                                return "Email Required"
                            } else if (!REGEX.EMAIL.test(v)) {
                                return "Enter a valid email";
                            }
                            return null
                        }}
                    />
                    <APFormFieldText
                        label="Phone"
                        initialValue={form.mobile}
                        keyboardType="tel"
                        onChanged={(v) => {
                            form.mobile = v;
                        }}
                        validator={(v) => {
                            if (v.length == 0) {
                                return "Phone Number Required"
                            } else if (!REGEX.PHONE.test(v)) {
                                return "Enter a valid phone number";
                            }
                            return null
                        }}
                    />
                    <APSizedBox height="8px" />
                    <APRow crossAxisAlignment="start">
                        <Checkbox style={{ padding: 0 }} value={confirmation} onChange={(ev, checked) => setConfirmation(checked)} />
                        <APSizedBox width="8px" />
                        <APExpanded>
                            <APText style={{ fontSize: "16px", lineHeight: "24px" }}>
                                I hereby confirm that the above email and phone number belongs to:
                            </APText>
                        </APExpanded>
                    </APRow>
                    <APFormFieldDropdown
                        label="E-mail"
                        initialValue={form.emailOwner}
                        items={MasterTable.OwnershipOptions}
                        onChanged={(v) => {
                            if (v) {
                                form.emailOwner = v;
                            }
                        }}
                        validator={(v) => {
                            if (!v) {
                                return "Ownership required"
                            }
                            return null;
                        }}
                    />
                    <APFormFieldDropdown
                        label="Phone"
                        initialValue={form.mobileOwner}
                        items={MasterTable.OwnershipOptions}
                        onChanged={(v) => {
                            if (v) {
                                form.mobileOwner = v;
                            }
                        }}
                        validator={(v) => {
                            if (!v) {
                                return "Ownership required"
                            }
                            return null;
                        }}
                    />
                    <Divider />
                    <APFormSubmit
                        onClick={
                            confirmation ?
                                async () => {
                                    if (await control.validate()) {
                                        try {
                                            deferred.resolve(form);
                                        } catch (error) {
                                            handleErrorMessage(error)
                                        }
                                    }
                                }
                                : undefined
                        }
                    >
                        Next
                    </APFormSubmit>
                </APColumn>
            </APForm>
        </APDialogCard >
    )
}
