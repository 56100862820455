
export const REGEX = {
    PAN: /^([A-Z]{3})([ABCFGHLJPTK])([A-Z])(\d{4})([A-Z])$/,
    // EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$/,
    EMAIL: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
    PHONE: /^[0-9]{10}$/,
    PIN: /^\d{6}$/,
    IFSC: /^[A-Z]{4}0[A-Z0-9]{6}$/,

};
